import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Button, Card, Checkbox, Flex, Progress, Skeleton, Switch, Tooltip } from "antd";
import "./profile.scss";
import { RootState } from "../../store";
import { ContentChatButton, PageHeader } from "../../components";
import { setPdfDetails } from "../../store/pdfViewer/pdfViewer.slice";
import { getAllProfiles, shortlistAssert } from "../../services/profile";
import { IAllMergedProfiles } from "../../store/profile/profile.interface";
import { IContent } from "../../store/contentManagement/content.interface";
import { contentByIdInitialValue } from "../../store/contentManagement/contentSlice";
import {
  CustomPagination,
  downloadAllFiles,
  FromWhere,
  getFileType,
  IMAGES,
  onDownloadPdf,
  PATHS,
  uniqueId,
} from "../../shared";
import {
  filterShortlistedResume,
  resetProfileResumes,
  setProfileDetails,
  setSelectedProfiles,
} from "../../store/profile/profileSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isAllProfileLoading,
    profileResume,
    allMergedProfiles,
    isFilterApplied,
    selectedProfiles,
  } = useSelector((state: RootState) => state.profile);

  const [_, setIsDownloadingPdf] = useState(false);
  const [isAllPdfDownloading, setIsAllPdfDownloading] = useState(false);
  const [currentDownloadFile, setCurrentDownloadFile] = useState<string>("");
  const [selectedProfileContent, setSelectedProfileContent] = useState<IContent[]>([]);

  useEffect(() => {
    if (profileResume?.length) {
      const fileNames: string[] = profileResume.map((v) => v?.file_name);

      fileNames && getAllProfiles(fileNames);
    }
  }, [profileResume]);

  useEffect(() => {
    const content: IContent[] =
      selectedProfiles &&
      selectedProfiles.map((v) => {
        return {
          ...contentByIdInitialValue,
          title: v?.file_name,
          asset_id: v?.asset_id,
          file_name: v?.file_name,
          assetType: getFileType(v?.file_name),
        };
      });

    setSelectedProfileContent(content);
  }, [selectedProfiles]);

  const handlePagination = (_page: number) => {};

  const handleShortlistResume = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    fileName: string,
    isShortlisted: boolean
  ) => {
    e.stopPropagation();
    await shortlistAssert(fileName, !isShortlisted);
  };

  const handleShowShortlistedFilter = (checked: boolean) => {
    dispatch(filterShortlistedResume(checked));
  };

  const handleProfileDetailsPage = (profileDetails: IAllMergedProfiles) => {
    dispatch(
      setPdfDetails({
        assetId: -1,
        fileName: profileDetails?.file_name,
        presignedUrl: profileDetails?.pre_signed_url,
        title: "",
        page: 0,
        showOpenIcon: true,
      })
    );
    dispatch(setProfileDetails(profileDetails));
    navigate(`${PATHS.profileDetails}/${profileDetails?.asset_id}`);
  };

  const handleDownloadAll = async () => {
    setIsAllPdfDownloading(true);
    const presignedUrls = selectedProfiles.map((profile) => profile?.pre_signed_url);

    await downloadAllFiles(presignedUrls, "allProfiles");

    setIsAllPdfDownloading(false);
  };

  const handleCheckProfile = (e: CheckboxChangeEvent, profileDetails: IAllMergedProfiles) => {
    if (e.target.checked === true) {
      dispatch(setSelectedProfiles([...selectedProfiles, profileDetails]));
    } else {
      dispatch(
        setSelectedProfiles(
          selectedProfiles.filter((v) => v?.file_name !== profileDetails?.file_name)
        )
      );
    }
  };

  const handleCheckAllProfile = (e: CheckboxChangeEvent) => {
    if (e.target.checked === true) {
      allMergedProfiles && dispatch(setSelectedProfiles(allMergedProfiles));
    } else {
      dispatch(setSelectedProfiles([]));
    }
  };

  const checkCheckedProfile = (profileDetails: IAllMergedProfiles): boolean => {
    const profileMatched =
      selectedProfiles && selectedProfiles.find((v) => v.file_name === profileDetails?.file_name);

    if (profileMatched) {
      return true;
    }
    return false;
  };

  const renderProfilePage = () => {
    if (allMergedProfiles?.length || isFilterApplied) {
      return (
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}>
          <Card className="profile-card">
            <div className="bottom-bar d-flex d-flex-middle d-flex-between">
              <div className="ant-tabs-nav-container">
                <Checkbox
                  className="select-label"
                  checked={selectedProfiles?.length === allMergedProfiles?.length}
                  indeterminate={
                    selectedProfiles?.length === allMergedProfiles?.length
                      ? false
                      : selectedProfiles?.length > 0
                  }
                  onChange={(e) => handleCheckAllProfile(e)}>
                  {selectedProfiles?.length} Selected
                  <span> (Showing {allMergedProfiles?.length} results)</span>
                </Checkbox>
              </div>
              <div className="ant-tabs-extra-content">
                <div className="published-box">
                  <span className="published-label">Show Shortlisted Only</span>
                  <Switch onChange={handleShowShortlistedFilter} />
                </div>
                {selectedProfiles?.length > 0 && (
                  <Tooltip
                    placement="topRight"
                    trigger="hover"
                    title="Chat with this document"
                    rootClassName="start-chat-tooltip">
                    <ContentChatButton
                      {...{
                        selectedContents: selectedProfileContent,
                        isVisible: true,
                        fromWhere: FromWhere.FindProfile,
                      }}
                    />
                  </Tooltip>
                )}
                {selectedProfiles?.length > 0 && (
                  <Button
                    type="text"
                    className="text-btn"
                    icon={<i className="ci-small-download-icon ci icon-link" />}
                    onClick={handleDownloadAll}
                    loading={isAllPdfDownloading}>
                    Download
                  </Button>
                )}
              </div>
            </div>
            <div className="page-scroll">
              {allMergedProfiles?.map((v) => (
                <Card
                  className="profile-card-inner"
                  key={uniqueId()}
                  onClick={() => handleProfileDetailsPage(v)}>
                  <div className="profile-checkbox" onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      className="select-label"
                      checked={checkCheckedProfile(v)}
                      onChange={(e) => handleCheckProfile(e, v)}
                    />
                  </div>
                  <div className="pdf-box">
                    <Card className="file-card cursor-pointer variant">
                      <div className="pdf-preview">
                        <img
                          src={v?.thumbnail_pre_signed_url}
                          onError={(e) => (e.currentTarget.src = IMAGES.pdfIconBig)}
                          alt="resume"
                        />
                      </div>
                      <div className="file-info">
                        <h6 className="file-name">{v?.file_name}</h6>
                        <div className="extra-options">
                          <Button
                            className="no-style"
                            loading={currentDownloadFile === v?.file_name}>
                            {!(currentDownloadFile === v?.file_name) && (
                              <i
                                className="ci-small-download-icon ci icon-link"
                                onClick={(e) => {
                                  e.stopPropagation(),
                                    onDownloadPdf(
                                      v?.pre_signed_url,
                                      setIsDownloadingPdf,
                                      v?.name,
                                      v?.file_name,
                                      setCurrentDownloadFile
                                    );
                                }}
                              />
                            )}
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="profile-text">
                    <h2 className="wrapper-title">{v?.name}</h2>
                    <p>{v?.why_resume}</p>
                  </div>
                  <div className="score-box">
                    <h4>Relevance Score</h4>
                    <Progress
                      className="score-number"
                      type="circle"
                      percent={v?.score}
                      format={(percent) => `${percent}  `}
                      size={36}
                    />
                    <Button
                      type={v?.is_shortlisted ? "default" : "primary"}
                      className="btn"
                      onClick={(e) => handleShortlistResume(e, v?.file_name, v?.is_shortlisted)}>
                      {v?.is_shortlisted ? "Shortlisted" : "Shortlist"}
                    </Button>
                  </div>
                </Card>
              ))}
            </div>

            <CustomPagination
              currentPage={10}
              total={10}
              pageSize={10}
              handlePagination={handlePagination}
            />
          </Card>
        </motion.div>
      );
    } else return renderEmptyProfilePage();
  };

  const renderEmptyProfilePage = () => {
    return (
      <div className="empty-profile-container">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}>
          <img src={IMAGES.jdEmptyState} alt="empty" />
        </motion.div>
      </div>
    );
  };

  const handleClose = () => {
    // dispatch(setFindProfileModel(false));
    // handleClearFindProfileChat();
    // navigate(PATHS.home);

    dispatch(resetProfileResumes());
  };

  return (
    <>
      <Flex className="admin-inner-wrap access-group-page" vertical>
        <PageHeader
          title="View Profiles"
          isRelevantAsset={false}
          isHeadBack={false}
          content={
            <Button
              onClick={handleClose}
              type="text"
              className="page-close-btn"
              icon={<i className="ri-close-fill" />}
            />
          }
        />

        {isAllProfileLoading ? <Skeleton active paragraph={{ rows: 10 }} /> : renderProfilePage()}
      </Flex>
    </>
  );
};

export default Profile;
