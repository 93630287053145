import { FC } from "react";
import ReactApexChart from "react-apexcharts";

const GradientAreaChart: FC<{options: ApexCharts.ApexOptions}> = ({options}) => {
  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2
    },
    xaxis: {
      type: "datetime",
      categories: [
        "2022-01-01",
        "2022-02-01",
        "2022-03-01",
        "2022-04-01",
        "2022-05-01",
        "2022-06-01",
        "2022-07-01",
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      borderColor: "#fff",
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0,
        opacityTo: 0.6,
        stops: [0, 100],
      },
    },
    colors: ["rgba(232, 190, 39, 0.26)", "rgba(255, 255, 255, 0)"],
    series: [
      {
        name: "Series 1",
        data: [10, 60, 70, 60, 100, 70, 80],
      },
    ],
    ...options
  };

  return (
    <div className="area-chart-wrap">
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        type="area"
        height={100}
      />
    </div>
  );
};

export default GradientAreaChart;
