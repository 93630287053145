import { FC } from "react";
import { Card, Skeleton } from "antd";
import "./FileCardSkeleton.scss";

const FileCardSkeleton: FC<{ items?: number }> = ({ items = 8 }) => {
  return [...Array(items)].map((_, index: number) => (
    <Card key={index} className="file-card skeleton">
      <Skeleton.Image active />
      <Skeleton active paragraph={{ rows: 1 }} />
    </Card>
  ));
};

export default FileCardSkeleton;
