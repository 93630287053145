import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IMediaUploadSliceProps } from "./mediaUpload.interface";
import { PURGE } from "redux-persist";
import { IEtags, ToggleAudio } from "../../shared";
import { IContent } from "../contentManagement/content.interface";

const initialState: IMediaUploadSliceProps = {
  partETags: [],
  videoTime: null,
  isCitation: false,
  toggleAudio: ToggleAudio.unset,
  isAudioPlaying: false,
  loadingAudio: true,
  videoModalContent: null,
  isShowVideoPLayerModal: false,
};

export const mediaUploadSlice = createSlice({
  name: "mediaUploadSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setPartETags: (state, action: PayloadAction<IEtags>) => {
      state.partETags = [...state.partETags, action.payload];
    },

    setVideoTime: (state, action: PayloadAction<string>) => {
      const convertTimestampToSeconds = (timestamp: string): number => {
        const [hours, minutes, seconds] = timestamp.split(":").map(Number);

        if (hours === 0 && minutes === 0 && seconds === 0) {
          return 0;
        } else return hours * 3600 + minutes * 60 + seconds;
      };

      state.videoTime = convertTimestampToSeconds(action.payload);
    },

    isShowVideoPLayerModal: (
      state,
      action: PayloadAction<{ isOpen: boolean; content: IContent | null; isCitation: boolean }>
    ) => {
      state.isCitation = action.payload.isCitation;
      state.videoModalContent = action.payload.content;
      state.isShowVideoPLayerModal = action.payload.isOpen;
    },

    setLoadingAudio: (state, action: PayloadAction<boolean>) => {
      state.loadingAudio = action.payload;
    },

    setIsAudioPlaying: (state, action: PayloadAction<boolean>) => {
      state.isAudioPlaying = action.payload;
    },

    setToggleAudio: (state, action: PayloadAction<ToggleAudio>) => {
      state.toggleAudio = action.payload;
    },

    resetMediaUploadState: (state) => {
      state.partETags = [];
      state.videoTime = null;
      state.toggleAudio = ToggleAudio.unset;
    },
  },
});

export const {
  setPartETags,
  setVideoTime,
  setToggleAudio,
  setLoadingAudio,
  setIsAudioPlaying,
  resetMediaUploadState,
  isShowVideoPLayerModal,
} = mediaUploadSlice.actions;

export default mediaUploadSlice.reducer;
