import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getUserRole, PATHS, UserRoles } from "../../shared";

const ProtectedRoute = () => {
  const isLogin = localStorage.getItem("token_foundationKM");
  const { pathname } = useLocation();

  const renderContent = () => {
    if (!isLogin) {
      return <Navigate to={PATHS.login} />;
    }

    if (
      (![UserRoles.Admin, UserRoles.ContentEditor].includes(getUserRole()) &&
        pathname.includes(PATHS.admin)) ||
      (getUserRole() !== UserRoles.Admin &&
        [
          String(PATHS.adminTaxonomyManagement),
          PATHS.adminTaxonomyOverview,
          PATHS.adminContentManagement,
          PATHS.adminAccessGroup,
        ].includes(pathname))
    ) {
      return <Navigate to={PATHS.home} />;
    }
    return <Outlet />;
  };

  return renderContent();
};

export default ProtectedRoute;
