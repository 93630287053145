import { useState, useRef, useEffect } from "react";
import { Button, Spin, Tooltip } from "antd";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { socialLogin } from "../../../shared";

interface IWhisperTextToSpeechProps {
  text: string;
}

const WhisperTextToSpeech = (props: IWhisperTextToSpeechProps) => {
  const { text } = props;

  const [audioSrc, setAudioSrc] = useState<string>("");
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isAudioLoading, setIsAudioLoading] = useState<boolean>(false);

  const apiKey = socialLogin.OPENAI_API_KEY;

  // Automatically play the audio once it is loaded
  useEffect(() => {
    if (audioSrc && audioRef.current && isPlaying) {
      audioRef.current.play();
    }
  }, [audioSrc, isPlaying]);

  // Handle when the audio ends, set isPlaying to false
  useEffect(() => {
    if (audioRef.current) {
      const audioElement = audioRef.current;

      const handleAudioEnd = () => {
        setIsPlaying(false);
      };

      audioElement.addEventListener("ended", handleAudioEnd);

      return () => {
        audioElement.removeEventListener("ended", handleAudioEnd);
        setIsAudioLoading(false);
      };
    }
  }, [audioSrc]);

  const TextToSpeech = async (text: string, setAudioSrc: (url: string) => void) => {
    try {
      setIsAudioLoading(true);

      const response = await fetch("https://api.openai.com/v1/audio/speech", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: "tts-1-hd",
          input: text,
          voice: "alloy",
        }),
      });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setAudioSrc(url);
    } catch (e) {
      toast.error("Failed to generate speech");
      setIsPlaying(false);
    } finally {
      setIsAudioLoading(false);
    }
  };

  const handlePlayPause = async () => {
    if (!isLoaded) {
      try {
        await TextToSpeech(text, setAudioSrc);
        setIsLoaded(true);
        setIsPlaying(true);
      } catch (error) {
        toast.error("Error generating speech");
      }
    }

    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div>
      {isAudioLoading ? (
        <Spin indicator={<LoadingOutlined spin />} size="small" />
      ) : isPlaying ? (
        <Tooltip trigger="hover" title="Stop">
          <Button
            className={"btn"}
            type="text"
            icon={<i className="ri-stop-fill" />}
            onClick={handlePlayPause}
          />
        </Tooltip>
      ) : (
        <Tooltip trigger="hover" title="Read aloud">
          <Button
            className={"btn"}
            type="text"
            icon={<i className="ri-volume-up-fill" />}
            onClick={handlePlayPause}
          />
        </Tooltip>
      )}

      {audioSrc && <audio ref={audioRef} src={audioSrc} />}
    </div>
  );
};

export default WhisperTextToSpeech;
