import { ComingSoon } from "../../../shared";

const WorkflowManager = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default WorkflowManager;
