import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Popover, Select } from "antd";
import { Resizable, ResizeCallback } from "re-resizable";
import { v4 as uuidv4 } from 'uuid';
import "./History.scss";
import { RootState } from "../../store";
import { getSubdomain } from "../../services/apiClients";
import { setAppLayoutview, setChatWidth } from "../../store/appState/appSlice";
import { ChatPanel, HistoryPanel, PublishingQueue, StartChatPanel } from "../../components";
import {
  AppLayout,
  ChatLanguage,
  IMAGES,
  SettingModal,
  fileType,
  getAllIds,
  isEmpty,
  useChats,
} from "../../shared";
import {
  removeFilterData,
  setAllAssetTypesSelected,
  setAllFileTypesSelected,
} from "../../store/filtersFromTaxonomyData/filters.slice";
import {
  setChatLanguage,
  setIsChatStartWithAssetFilter,
  setIsNewChatStartWithContentFilter,
  setIsOpenChatHistory,
  setIsPublishingQueue,
  setSelectedFilesForAssetFilter,
  setStartNewChatFromInsight,
  setStartNewConversation,
} from "../../store/conversationalSearch/conversationalSearchSlice";

const History = () => {
  const dispatch = useDispatch();

  const { appLayoutView, chatWidth } = useSelector((state: RootState) => state.app);
  const {
    chatLanguage,
    uploadFileList,
    isOpenChatHistory,
    isPublishingInQueue,
    selectedChatHistory,
    isNewConversationStart,
    startNewChatFromInsight,
    isChatStartWithAssetFilter,
    selectedFilesForAssetFilter,
    startNewChatWithContentFilter,
  } = useSelector((state: RootState) => state.conversationalSearch);
  const { assetTypeTaxonomy } = useSelector((state: RootState) => state.taxonomy);

  const [isPublishingQueue, setPublishingQueue] = useState(false);
  const [isChatWrap, setIsChatWrap] = useState(false);
  const [isChatView, setIsChatView] = useState(false);
  const [bodyClass, setBodyClass] = useState(appLayoutView || AppLayout.MINIMIZE);
  const [isVisibleSettings, setIsVisibleSettings] = useState<boolean>(false);
  const [width, setWidth] = useState<string | number>(350);

  const { clearAllChatStates, defaultConfigureState } = useChats();

  useEffect(() => {
    document.body.classList.add(bodyClass);
    return () => {
      document.body.classList.remove(bodyClass);
    };
  }, [bodyClass]);

  const getChatLanguages = () => {
    if (getSubdomain() === "aecb") {
      return [
        { value: ChatLanguage.English, name: "English (EN)" },
        { value: ChatLanguage.Arabic, name: "Arabic (AR)" },
      ];
    } else
      return [
        { value: ChatLanguage.Auto, name: "Auto" },
        { value: ChatLanguage.English, name: "English (EN)" },
        { value: ChatLanguage.Arabic, name: "Arabic (AR)" },
        { value: ChatLanguage.Spanish, name: "Spanish (ES)" },
        { value: ChatLanguage.Chinese, name: "Chinese (Mandarin) (ZH)" },
        { value: ChatLanguage.French, name: "French (FR)" },
        { value: ChatLanguage.German, name: "German (DE)" },
        { value: ChatLanguage.Portuguese, name: "Portuguese (PT)" },
        { value: ChatLanguage.Italian, name: "Italian (IT)" },
        { value: ChatLanguage.Dutch, name: "Dutch (NL)" },
        { value: ChatLanguage.Russian, name: "Russian (RU)" },
        { value: ChatLanguage.Japanese, name: "Japanese (JA)" },
        { value: ChatLanguage.Korean, name: "Korean (KO)" },
        { value: ChatLanguage.Hindi, name: "Hindi (HI)" },
        { value: ChatLanguage.Turkish, name: "Turkish (TR)" },
        { value: ChatLanguage.Polish, name: "Polish (PL)" },
      ];
  };

  const handleNewTopicAction = () => {
    clearAllChatStates();
    defaultConfigureState();
    dispatch(removeFilterData([]));
    dispatch(setAllFileTypesSelected(fileType));
    dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
    dispatch(setStartNewConversation(false));
  };

  const handleClassChange = (newClass: AppLayout) => {
    dispatch(setAppLayoutview(newClass));
    setBodyClass(newClass);
    if (newClass === AppLayout.CLOSE) {
      dispatch(setStartNewChatFromInsight(false));
    }
    if (newClass === AppLayout.FULL) {
      dispatch(setChatWidth("100%"));
    } else {
      dispatch(setChatWidth(350));
    }
  };

  useEffect(() => {
    if ((uploadFileList?.length > 1 || isPublishingInQueue) && selectedChatHistory.id !== -1) {
      dispatch(setIsPublishingQueue(!isEmpty(uploadFileList)));
      setPublishingQueue(true);
    } else {
      setPublishingQueue(false);
      setIsChatWrap(
        selectedChatHistory.id !== -1 ||
          isOpenChatHistory ||
          isNewConversationStart ||
          startNewChatFromInsight
          ? true
          : false
      );
      setIsChatView(!isOpenChatHistory);
    }
  }, [
    selectedChatHistory,
    isOpenChatHistory,
    isNewConversationStart,
    uploadFileList,
    isPublishingInQueue,
    startNewChatFromInsight,
  ]);

  useEffect(() => {
    if (startNewChatWithContentFilter) {
      handleNewTopicAction();
      dispatch(setIsNewChatStartWithContentFilter(false));
      if (selectedFilesForAssetFilter?.length && !isChatStartWithAssetFilter) {
        dispatch(setIsOpenChatHistory(false));
        dispatch(setIsChatStartWithAssetFilter(true));
      }
    }
  }, [startNewChatWithContentFilter, isChatStartWithAssetFilter, selectedFilesForAssetFilter]);

  const handleHistoryView = () => {
    dispatch(setStartNewChatFromInsight(false));
    dispatch(setSelectedFilesForAssetFilter([]));
    dispatch(setIsOpenChatHistory(!isOpenChatHistory));
    if (isOpenChatHistory && selectedChatHistory.id === -1) {
      dispatch(setStartNewConversation(false));
    }
  };

  const handleSaveSetting = () => {
    setIsVisibleSettings(false);
  };

  const handleToggleChat = () => {
    if (appLayoutView === AppLayout.FULL) {
      return handleClassChange(AppLayout.MINIMIZE);
    } else {
      return handleClassChange(AppLayout.FULL);
    }
  };

  const reanderChatHeader = () => {
    return (
      <div
        className={`chat-header d-flex d-flex-middle d-flex-between ${
          isPublishingQueue ? "publishing-queue-wrapper" : ""
        } ${!isEmpty(selectedChatHistory?.fileContext) ? "has-context" : ""}`}>
        <div className="chat-header-left">
          {!isOpenChatHistory && !isPublishingQueue ? (
            <div className="chat-header-title d-flex d-flex-middle">
              <span className="octopus-icon">
                <img src={IMAGES.brandIcon} alt="Brand Icon" />
              </span>
              AI Assistant
            </div>
          ) : (
            <div className="chat-header-title">
              {isOpenChatHistory && (
                <div className="chat-back-icon" onClick={handleHistoryView}>
                  <i className="ri-arrow-left-s-line"></i>
                </div>
              )}
              {isPublishingQueue ? "Publishing Queue" : "History"}
            </div>
          )}
          {/* Section show only History view */}
        </div>

        <div className="chat-header-middle d-flex d-flex-middle">
          {isOpenChatHistory ? (
            ""
          ) : (
            <span className="gpt-version cursor-pointer" onClick={handleHistoryView}>
              <i className="ri-history-line"></i> History
            </span>
          )}

          <div hidden={true}>
            <Button
              type="link"
              onClick={handleHistoryView}
              className={`chat-header-icon ${isOpenChatHistory ? "active" : ""}`}>
              <i className="ri-history-line"></i>
            </Button>
            <Popover
              open={isVisibleSettings}
              placement="bottomLeft"
              title={false}
              content={<SettingModal onSubmit={handleSaveSetting} />}
              trigger="click"
              onOpenChange={() => setIsVisibleSettings(false)}
              rootClassName="setting-pop">
              <Button
                type="link"
                className="chat-header-icon"
                onClick={() => setIsVisibleSettings(true)}>
                <i className="ri-settings-4-line"></i>
              </Button>
            </Popover>
          </div>
        </div>

        <div className="chat-header-right d-flex d-flex-middle">
          <Select
            className="language-select"
            style={{ width: "70px", height: "20px" }}
            value={chatLanguage}
            onChange={(e: ChatLanguage) => dispatch(setChatLanguage(e))}
            dropdownStyle={{ width: "120px" }}>
            {getChatLanguages()?.map((v) => (
              <Select.Option value={v?.value} key={uuidv4()}>{v?.name}</Select.Option>
            ))}
          </Select>
          <Button
            type="link"
            onClick={() => handleClassChange(AppLayout.CLOSE)}
            className="mini-icon min">
            <i className="ri-subtract-line"></i>
          </Button>
          <Button type="link" className="mini-icon max" onClick={handleToggleChat}>
            {appLayoutView === AppLayout.FULL ? (
              <i className="ri-collapse-diagonal-line"></i>
            ) : (
              <i className="ri-expand-diagonal-line"></i>
            )}
          </Button>
          {/* <Button
            type="link"
            className="mini-icon close"
            onClick={() => handleClassChange(AppLayout.CLOSE)}>
            <i className="ri-close-line"></i>
          </Button> */}
        </div>
        {/* {!isEmpty(selectedChatHistory?.fileContext) ? (
          <div className="selected-assets-wrapper ">
            <span>You have selected {selectedChatHistory?.fileContext?.length} Assets</span>
            <span className="tooltip-wrap">
              <Tooltip
                trigger={"click"}
                placement="bottomRight"
                overlayClassName="asset-overlay"
                overlay={
                  <div>
                    <ul>
                      <li key={"01"} className="title-asset">
                        Referenced assets
                      </li>
                      {(selectedChatHistory?.fileContext || []).map(
                        (item: IFileContext, index: number) => (
                          <li
                            title={item.fileName}
                            onClick={() => handleNavigation(item.assetId)}
                            key={index}>
                            <span className="file-icon">
                              <img src={renderFileTypeIcon(item.fileType)} alt="Icon" />
                            </span>
                            {item.fileName}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                }>
                <InfoCircleFilled />
              </Tooltip>
            </span>
          </div>
        ) : (
          ""
        )} */}
      </div>
    );
  };

  useEffect(() => {
    setWidth(chatWidth);
    if (typeof chatWidth === "number") {
      document.documentElement.style.setProperty("--chatWidth", `${chatWidth}px`);
    } else {
      document.documentElement.style.setProperty("--chatWidth", `${chatWidth}`);
    }
  }, [chatWidth]);

  const handleResize: ResizeCallback = (_, __, elementRef, ___) => {
    const newWidth = elementRef.getBoundingClientRect().width;
    dispatch(setChatWidth(newWidth));
  };

  const widthStyle = typeof width === "number" ? `${width}px` : width;

  return (
    <>
      <Resizable
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        className="chat-wrap"
        size={{ width: widthStyle, height: "100%" }}
        onResize={handleResize}
        maxWidth={appLayoutView === AppLayout.MINIMIZE ? 500 : undefined}
        maxHeight={"100%"}
        minWidth={350}>
        <div className="chat-wrap-inner">
          {reanderChatHeader()}

          {isPublishingQueue ? (
            <PublishingQueue />
          ) : (
            <>
              {isChatWrap ? (
                <>
                  <div className="chat-body">{isChatView ? <ChatPanel /> : <HistoryPanel />}</div>
                </>
              ) : (
                <StartChatPanel />
              )}
            </>
          )}
        </div>
      </Resizable>
    </>
  );
};

export default History;
