import React, { FC } from "react";
import { Col, Card, Checkbox } from "antd";
import { FileCardType, FromWhere, PATHS, renderFileTypeIcon } from "../../shared";
import "../fileCard/FileCard.scss";
import { ICitations } from "../../store/conversationalSearch/conversationalSearch.interface";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import "./AssetsFileCard.scss";
const AssetFileCard: FC<{
  data: ICitations;
  isChecked: boolean;
  type: FileCardType;
  extraOptions?: React.ReactNode;
  hoverOptions?: React.ReactNode;
  handleSetCheckedAssets: (e: CheckboxChangeEvent, data: ICitations) => void;
  onClick?: () => void;
  onCardClick?: () => void;
}> = ({
  data,
  type,
  extraOptions,
  hoverOptions,
  onClick,
  onCardClick,
  isChecked,
  handleSetCheckedAssets,
}) => {
  const { pdfDetails } = useSelector((state: RootState) => state.pdfViewer);

  const navigate = useNavigate();
  const location = useLocation();
  const s_no = location?.state?.s_no || 0;

  const noClick = (e: any) => {
    e.stopPropagation();
  };

  const renderFooter = () => {
    if (type === FileCardType.UPLOADED) {
      return (
        // <p className="file-url">
        //   <a href="https://greychaindesign.com/" target="_blank">
        //     https://greychaindesign.com/
        //   </a>
        // </p>
        <></>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Col>
      <Card
        className={`file-card asset-file-card cursor-pointer ${
          s_no === data.s_no && (data.file_type !== "pdf" || pdfDetails.fileName) ? "active" : ""
        }`}
        onClick={() => [
          navigate(PATHS.relevantAssets, {
            state: { s_no: data.s_no, fromWhere: FromWhere.ConversationalSearch },
          }),
          onCardClick && onCardClick(),
        ]}>
        <div className="file-preview">
          <div className="file-bg" style={{ backgroundImage: `url(${data.pre_signed_url})` }}></div>
          <div className="file-prev-inner">
            <div className="checkbox-wrap" onClick={noClick}>
              <Checkbox checked={isChecked} onChange={(e) => handleSetCheckedAssets(e, data)} />
            </div>
            {/* <AssetStatus  
            status={data.assetStatus} 
            /> */}
            {extraOptions ? (
              <div className="extra-options">{extraOptions && extraOptions}</div>
            ) : (
              ""
            )}
            <div className="file-icon">
              <img src={renderFileTypeIcon(data.file_type)} alt="Icon" />
            </div>
          </div>
        </div>
        <div className="file-info d-flex d-flex-between" onClick={noClick}>
          <h6 className="file-name cursor-pointer" onClick={() => onClick && onClick()}>
            {data.title !== "" && data.title ? data.title : data.original_file_name}
          </h6>
          <div className="file-data">
            <p className="file-cat">
              {data.page + 1}
              {/* {data?.file_level === FileLocation.USER ? "My Uploads" : "Organisation"} */}
            </p>
            {/* <p className="file-date">{data.page}</p> */}
          </div>
          {renderFooter()}
        </div>
        <div className="hover-options">{hoverOptions && hoverOptions}</div>
      </Card>
    </Col>
  );
};

export default AssetFileCard;
