import { Button, Tooltip } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import { DeleteModal } from "../deleteModal";
import { RootState } from "../../store";
import { IContent } from "../../store/contentManagement/content.interface";
import { IFileINContext } from "../../store/conversationalSearch/conversationalSearch.interface";
import {
  setAllContentDetails,
  setContentFilesInContext,
  setContentSelectedIds,
  setSelectionAppliedForContent,
  setSelectionAppliedForSources,
} from "../../store/contentManagement/contentSlice";
import {
  setIsChatStartWithAssetFilter,
  setSelectedFilesForAssetFilter,
  setSelectedReleventAsset,
} from "../../store/conversationalSearch/conversationalSearchSlice";
import { setAppLayoutview } from "../../store/appState/appSlice";
import { deleteMultipleContentByIds } from "../../services/contentManagement";
import { AppLayout, IMAGES, isEmpty, PATHS } from "../../shared";
import "./contentActionButton.scss";

interface IContentActionButton {
  isShowChatIcon?: boolean;
}

const ContentActionButton = ({ isShowChatIcon = true }: IContentActionButton) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    contents,
    contentFilesInContext,
    isMultipleContentDeleteLoading,
    totalContentsCount,
    sourceFilter,
    contentFilter,
  } = useSelector((state: RootState) => state.content);

  const [isOpenMultipleDelete, setIsOpenMultipleDelete] = useState<boolean>(false);
  const [showMore, setShowMore] = useState(false);

  // const handleRemoveSelectedContent = () => {
  //   if (contents && contents?.length) {
  //     const filteredContents = [...contents].map((item) => ({ ...item, isSelected: false }));
  //     dispatch(setAllContentDetails(filteredContents));
  //     if (location.pathname.includes(PATHS.sources))
  //       dispatch(setSelectionAppliedForSources(contentFilesInContext?.length ? true : false));
  //     else if (location.pathname.includes(PATHS.myContent))
  //       dispatch(setSelectionAppliedForContent(contentFilesInContext?.length ? true : false));
  //   }
  // };

  const getSelectedContents = () => {
    let selectedContents: number[] = [];
    if (contents && contents?.length) {
      selectedContents = contents.filter((item) => item?.isSelected).map((item) => item?.asset_id);
    }
    return selectedContents || [];
  };

  const handleChatWithAssetFilter = () => {
    let selectedFiles = contents.filter((item) => item?.isSelected);
    if (selectedFiles.find((item) => ["STOPPED", "FAILED"].includes(item.assetStatus))) {
      toast.error("You cant proceed with stopped or failed content");
      return;
    }
    if (selectedFiles.find((item) => item.isPublished !== "TRUE")) {
      const publishedFiles = selectedFiles.filter((item) => item.isPublished === "TRUE");
      if (publishedFiles.length)
        toast.error(
          "Some selected files are not published, Therefore, the chat cannot proceed for these files."
        );
      else toast.error("Files are not published, Therefore, the chat cannot proceed.");
      if (!publishedFiles.length) return;
    }
    dispatch(setAppLayoutview(AppLayout.MINIMIZE));
    dispatch(setSelectedReleventAsset([]));
    const fileInContext: IFileINContext[] = selectedFiles.map((e) => ({
      asset_id: e.asset_id,
      asset_type: "",
      file_name: e.file_name,
      file_type: e.file_type || "",
      title: e.title,
      isPublished: e.isPublished,
    }));
    dispatch(setSelectedFilesForAssetFilter(fileInContext));
    dispatch(setContentSelectedIds(fileInContext.map((e) => e.asset_id)));
    dispatch(setIsChatStartWithAssetFilter(false));
  };

  const handleMultipleContentsDelete = async () => {
    const selectedKeys = getSelectedContents();
    const currentPage = location.pathname.includes(PATHS.myContent) ? "content" : "sources";
    if (selectedKeys && selectedKeys?.length) {
      let filter = currentPage === "content" ? contentFilter : sourceFilter;
      const res: any = await deleteMultipleContentByIds(
        selectedKeys,
        filter,
        currentPage,
        totalContentsCount
      );
      if (res) {
        const contentsValues = [...contents];
        let filteredContents: IContent[] = [];

        contentsValues.forEach((item) => {
          if (!selectedKeys.includes(item?.asset_id)) {
            filteredContents.push(item);
          }
        });
        if (location.pathname.includes(PATHS.sources))
          dispatch(setSelectionAppliedForSources(false));
        else if (location.pathname.includes(PATHS.myContent))
          dispatch(setSelectionAppliedForContent(false));
        const filteredContext = contentFilesInContext.filter(
          (c) => !selectedKeys.includes(c.asset_id)
        );
        dispatch(setSelectedFilesForAssetFilter(filteredContext));
        dispatch(setContentFilesInContext(filteredContext));
        dispatch(setAllContentDetails(filteredContents));
      }
    }
    setIsOpenMultipleDelete(false);
  };

  // const handleApply = async () => {
  //   const previousIds = contents
  //     .filter((c) => selectedContentIds.includes(c.asset_id))
  //     .map((e) => e.asset_id);
  //   console.log(previousIds);
  //   const existingSelectedIds = selectedContentIds.filter((e) => !previousIds.includes(e));
  //   const currentContentIds = contents.filter((c) => c.isSelected).map((c) => c.asset_id);
  //   if (location.pathname.includes(PATHS.sources)) {
  //     dispatch(setSelectionAppliedForSources(true));
  //   } else if (location.pathname.includes(PATHS.myContent)) {
  //     dispatch(setSelectionAppliedForContent(true));
  //   }
  //   await selectContent([...currentContentIds, ...existingSelectedIds]);
  //   await getContentSelected();
  // };

  // let appliedSelection = location.pathname.includes(PATHS.myContent)
  //   ? selectionAppliedForContent
  //   : selectionAppliedForSources;
  const unAuthorizedContents = !isEmpty(contents)
    ? contents.filter((e) => e.isSelected && !e.is_editable)
    : [];
  return (
    <>
      {/* {contents?.length > 0 &&
        (getSelectedContents()?.length > 0 || contentFilesInContext?.length > 0) &&
        !appliedSelection && (
          <>
            <Button
              onClick={handleRemoveSelectedContent}
              type="text"
              className="text-btn round-icon cancel"
              icon={<i className="ri-close-fill"></i>}>
              Cancel
            </Button>
            <Button
              type="text"
              className="text-btn round-icon apply"
              onClick={() => handleApply()}
              icon={<i className="ri-check-fill"></i>}>
              Apply
            </Button>
          </>
        )} */}

      {getSelectedContents()?.length > 0 && (
        <>
          {!location.pathname.includes(PATHS.sources) ? (
            <Button
              type="text"
              className="text-btn"
              disabled={isMultipleContentDeleteLoading}
              onClick={() => setIsOpenMultipleDelete(true)}
              icon={<i className="ri-delete-bin-line"></i>}>
              Delete
            </Button>
          ) : (
            ""
          )}
          {isShowChatIcon && (
            <Tooltip placement="topRight" title="Chat with this document">
              <Button
                onClick={handleChatWithAssetFilter}
                type="primary"
                className="btn chat-document"
                icon={<i className="ci-small-chat-icon ci"></i>}>
                Chat
              </Button>
            </Tooltip>
          )}
        </>
      )}

      <DeleteModal
        isLoading={isMultipleContentDeleteLoading}
        isModalOpen={isOpenMultipleDelete}
        headerIcon={IMAGES.cautionSign}
        handleCancel={() => [setShowMore(false), setIsOpenMultipleDelete(false)]}
        handleSubmit={handleMultipleContentsDelete}
        message={`Are you sure you want to delete these ${
          location.pathname.includes(PATHS.myContent) ? "contents" : "sources"
        }?`}
        content={
          <div>
            <div>
              {unAuthorizedContents?.length ? (
                <p>
                  You are not authorized to delete
                  {(showMore ? unAuthorizedContents : unAuthorizedContents.splice(0, 2)).map(
                    (content, index) => (
                      <>
                        &nbsp;
                        <a className="unauthorized-file-link">{content.original_file_name}</a>
                        {index < (!showMore ? 1 : unAuthorizedContents.length - 1) ? "," : ""}
                      </>
                    )
                  )}
                  &nbsp;
                  {unAuthorizedContents.length > 2 && !showMore ? (
                    <Button onClick={() => setShowMore(!showMore)} type="link" className="btn-more">
                      More
                    </Button>
                  ) : (
                    ""
                  )}
                  . Deleting this file will affect its tags and linked assets.
                </p>
              ) : (
                ""
              )}
              <p>Please confirm if you want to proceed with deleting the authorized asset.</p>
            </div>
          </div>
        }
        title="You can only delete the following asset?"
      />
    </>
  );
};

export default ContentActionButton;
