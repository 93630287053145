import { FC, useEffect, useState } from "react";
import { Button, Checkbox, Col, Input, Modal, Row } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { NoContentData, isEmpty, useDebounce } from "../../shared";
import { IContent } from "../../store/contentManagement/content.interface";
import { getAllContent } from "../../services/contentManagement";
import "./AddRecommendedAsset.scss";

interface IAddRecommendedAsset {
  isModalOpen: boolean;
  title: string;
  handleSubmit: () => void;
  handleCancel: () => void;
}

const AddRecommendedAsset: FC<IAddRecommendedAsset> = ({
  isModalOpen,
  title,
  handleSubmit,
  handleCancel,
}) => {
  const { contents, contentFilter } = useSelector((state: RootState) => state.content);

  const [selectedFiles, setSelectedFiles] = useState<IContent[]>([]);

  useEffect(() => {
    getAllContent(contentFilter, false);
  }, []);

  const getSelectedIDs = () => {
    return !isEmpty(selectedFiles) ? selectedFiles.map((item) => item?.asset_id) : [];
  };

  const removeSelected = (item: IContent) => {
    const updatedSelect = selectedFiles.filter((data) => data?.asset_id !== item?.asset_id && item);
    setSelectedFiles(updatedSelect);
  };

  const onchange = (e: any, item: IContent) => {
    if (e?.target?.checked) {
      setSelectedFiles([...selectedFiles, item]);
    } else {
      removeSelected(item);
    }
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let filteredContents: IContent[] = [];
    if (!isEmpty(e?.target?.value)) {
      const trimmedSearchText = e.target.value.trim();
      const find = new RegExp(trimmedSearchText, "gi");

      filteredContents = contents.filter(
        (item) => item?.title?.match(find) || (item?.file_name?.match(find) && item)
      );
    } else {
    }
    console.log("filteredContents", filteredContents);
  };

  const handleSearchDebounce = useDebounce(onSearch, 500);

  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
      centered={true}
      rootClassName="add-recommended-asset-pop"
      footer={
        <>
          <Button onClick={handleCancel} className="btn" type="default" size={"middle"}>
            Cancel
          </Button>
          <Button
            // loading={proceedLoading}
            // onClick={handleProceed}
            htmlType="submit"
            className="btn"
            type="primary"
            disabled={isEmpty(selectedFiles)}>
            Proceed
          </Button>
        </>
      }
      okText="Proceed"
      cancelText="Cancel">
      <Row className="add-recommend-row">
        <Col span={24}>
          <Input
            type="search"
            prefix={<i className="ri-search-line"></i>}
            onChange={handleSearchDebounce}
          />
        </Col>

        {!isEmpty(selectedFiles) ? (
          <Col span={24}>
            <span className="label">You have selected {selectedFiles.length} Asset(s)</span>
            <ul className="selected-files-list">
              {selectedFiles.map((item: IContent, index: number) => (
                <li key={index} className="cursor-pointer">
                  <Checkbox
                    checked={true}
                    value={item?.asset_id}
                    onChange={() => removeSelected(item)}>
                    <span className="selected-name">
                      {item?.title ? item?.title : item?.file_name}
                    </span>
                  </Checkbox>
                </li>
              ))}
            </ul>
          </Col>
        ) : (
          ""
        )}
        <Col span={24}>
          <div className="files-list-scroll">
            <span className="label">Assets starting from A</span>
            <ul className="files-list">
              {!isEmpty(contents) ? (
                contents.map((item: IContent, index: number) => (
                  <Checkbox
                    key={index}
                    checked={getSelectedIDs().includes(item.asset_id)}
                    onChange={(e) => onchange(e, item)}
                    value={item.title ? item.title : item.file_name}>
                    {item.title ? item.title : item.file_name}
                  </Checkbox>
                ))
              ) : (
                <NoContentData />
              )}
            </ul>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddRecommendedAsset;
