import { Dispatch, SetStateAction } from "react";
import { Form, FormInstance } from "antd";
import TextArea from "antd/es/input/TextArea";
import { IUserProfile } from "../../../store/userManagement/user.interface";

interface SummaryForm {
  form: FormInstance<any>;
  isEdit: boolean;
  localUserProfile: IUserProfile;
  setLocalUserProfile: Dispatch<SetStateAction<IUserProfile>>;
  isProfileUpdating: boolean;
}

const SummaryForm = (props: SummaryForm) => {
  const { form, isEdit, localUserProfile, setLocalUserProfile, isProfileUpdating } = props;
  const isDisabled = isProfileUpdating || !isEdit;

  return (
    <>
      <div className="card-head-wrap d-flex d-flex-between">
        <div className="heading-wrap">
          <h4>Profile Summary</h4>
          <p>You can attach your resume and generate a summary using AI</p>
        </div>
        <div className="btns-wrap">
          <div className="btn-wrap-inner"></div>
        </div>
      </div>

      <div className="summary-wrap">
        {isEdit ? (
          <Form name="profileForm" form={form}>
            <Form.Item
              name="summary"
              rules={[
                {
                  type: "string",
                  required: false,
                  message: "Please enter profile summary",
                },
              ]}>
              <TextArea
                rows={6}
                value={localUserProfile?.summary || ""}
                disabled={isDisabled}
                placeholder="Enter about yourself"
                onChange={(e) => {
                  form.setFieldsValue({ summary: e.target.value });
                  setLocalUserProfile((prev) => ({ ...prev, summary: e.target.value }));
                }}
              />
            </Form.Item>
          </Form>
        ) : (
          <p>{localUserProfile?.summary}</p>
        )}
      </div>
    </>
  );
};

export default SummaryForm;
