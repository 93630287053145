import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import { Button, Checkbox, DatePicker, Select, Skeleton, TimeRangePickerProps } from "antd";
import "./ContentSourceFilter.scss";
import { RootState } from "../../../store";
import { mapDataToTreeSelect } from "../../taxonomyHelper";
import { getAssetTypeOptions, getFileTypeOptions, isEmpty } from "../../helpers";
import EditContentTags from "../../../components/viewEditContentManagement/EditContentTags/EditContentTags";
import {
  assetStatusForMyContent,
  contentType,
  contentTypeForMyContent,
  fileType,
  FromWhere,
} from "../../constants";
import {
  IAllClearFilter,
  IContentFilter,
} from "../../../store/contentManagement/content.interface";

interface IContentSourceFilter {
  isShowContentStatus?: boolean;
  isShowContentType?: boolean;
  localFilters: IContentFilter;
  setLocalFilters: React.Dispatch<React.SetStateAction<IContentFilter>>;
  handleApplyFilter: (localFilters: IContentFilter) => void;
  handleResetFilter: () => void;
  fromWhere?: string;
  isFilterSelected: boolean;
}

const ContentSourceFilter = ({
  isShowContentStatus = true,
  isShowContentType = true,
  localFilters,
  setLocalFilters,
  handleApplyFilter,
  handleResetFilter,
  fromWhere = "",
  isFilterSelected,
}: IContentSourceFilter) => {
  const { assetTypeTaxonomy, allTaxonomy, isAllTaxonomyLoading } = useSelector(
    (state: RootState) => state.taxonomy
  );
  const { isContentTableLoading } = useSelector((state: RootState) => state.content);

  const [isShowDocCount, setIsShowDocCount] = useState(false);
  const [isAllClear, setIsAllClear] = useState<IAllClearFilter>({
    contentType: true,
    assetStatus: true,
    assetType: true,
    documentType: true,
    function: true,
    industry: true,
  });
  const [isShowClearIcon, setIsShowClearIcon] = useState<IAllClearFilter>({
    contentType: true,
    assetStatus: true,
    assetType: true,
    documentType: true,
    function: true,
    industry: true,
  });

  const assetTypes = assetTypeTaxonomy?.taxonomyTree || [];

  const assetTypeOptions = getAssetTypeOptions(assetTypes) || [];
  const fileTypeOptions = getFileTypeOptions(fileType) || [];

  const contentTypeOptions =
    fromWhere === FromWhere.MyContent ? contentTypeForMyContent : contentType;

  const assetStatusOptions = assetStatusForMyContent;

  const { RangePicker } = DatePicker;

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    setLocalFilters((prev) => ({ ...prev, isApplied: false, isFilterChanged: true }));

    if (dates) {
      setLocalFilters((prev) => ({ ...prev, startDate: dateStrings[0], endDate: dateStrings[1] }));
      handleApplyFilter({ ...localFilters, startDate: dateStrings[0], endDate: dateStrings[1] });
    } else {
      setLocalFilters((prev) => ({ ...prev, startDate: "", endDate: "" }));
      handleApplyFilter({ ...localFilters, startDate: "", endDate: "" });
    }
  };

  const handleSelectContentType = (value: any[]) => {
    setIsAllClear((prev) => ({ ...prev, contentType: false }));
    setIsShowClearIcon((prev) => ({ ...prev, contentType: false }));

    if (value.includes("selectAll")) {
      if (contentTypeOptions?.length === value.length - 1) {
        setLocalFilters((prev) => ({ ...prev, contentType: [] }));
      } else {
        const contentTypeValues = contentTypeOptions?.map((item) => item.value);
        setLocalFilters((prev) => ({ ...prev, contentType: contentTypeValues }));
      }
    } else {
      setLocalFilters((prev) => ({ ...prev, contentType: value }));
    }
  };

  const handleBlurContentType = () => {
    if (!isAllClear?.contentType) {
      handleApplyFilter(localFilters);
      setIsAllClear((prev) => ({ ...prev, contentType: true }));
    }
    setIsShowClearIcon((prev) => ({ ...prev, contentType: true }));
  };

  const handleAllClearContentType = () => {
    if (!isEmpty(localFilters?.contentType)) {
      setTimeout(() => {
        setIsAllClear((prev) => ({ ...prev, contentType: true }));
      }, 50);
      handleApplyFilter({ ...localFilters, contentType: [] });
    }
  };

  const handleSelectAssetStatus = (value: any[]) => {
    setIsAllClear((prev) => ({ ...prev, assetStatus: false }));

    if (value.includes("selectAll")) {
      if (assetStatusOptions?.length === value.length - 1) {
        setLocalFilters((prev) => ({ ...prev, assetStatus: [] }));
      } else {
        const assetStatusValues = assetStatusOptions?.map((item) => item.value);
        setLocalFilters((prev) => ({ ...prev, assetStatus: assetStatusValues }));
      }
    } else {
      setLocalFilters((prev) => ({ ...prev, assetStatus: value }));
    }
  };

  const handleBlurAssetStatus = () => {
    if (!isAllClear?.assetStatus) {
      handleApplyFilter(localFilters);
      setIsAllClear((prev) => ({ ...prev, assetStatus: true }));
    }
    setIsShowClearIcon((prev) => ({ ...prev, assetStatus: true }));
  };

  const handleAllClearAssetStatus = () => {
    if (!isEmpty(localFilters?.assetStatus)) {
      setTimeout(() => {
        setIsAllClear((prev) => ({ ...prev, assetStatus: true }));
      }, 50);
      handleApplyFilter({ ...localFilters, assetStatus: [] });
    }
  };

  const handleSelectAssetType = (value: any[]) => {
    setIsAllClear((prev) => ({ ...prev, assetType: false }));

    if (value.includes("selectAll")) {
      if (assetTypeOptions?.length === value.length - 1) {
        setLocalFilters((prev) => ({ ...prev, assetType: [] }));
      } else {
        const assetTypeValues = assetTypeOptions?.map((item) => Number(item.value));
        setLocalFilters((prev) => ({ ...prev, assetType: [...new Set(assetTypeValues)] }));
      }
    } else {
      setLocalFilters((prev) => ({ ...prev, assetType: [...new Set(value)] }));
    }
  };

  const handleBlurAssetType = () => {
    if (!isAllClear?.assetType) {
      handleApplyFilter(localFilters);
      setIsAllClear((prev) => ({ ...prev, assetType: true }));
    }
    setIsShowClearIcon((prev) => ({ ...prev, assetType: true }));
  };

  const handleAllClearAssetType = () => {
    if (!isEmpty(localFilters?.assetType)) {
      setTimeout(() => {
        setIsAllClear((prev) => ({ ...prev, assetType: true }));
      }, 50);
      handleApplyFilter({ ...localFilters, assetType: [] });
    }
  };

  const handleSelectDocType = (value: any[]) => {
    setIsAllClear((prev) => ({ ...prev, documentType: false }));

    if (value.includes("selectAll")) {
      if (fileTypeOptions?.length === value.length - 1) {
        setLocalFilters((prev) => ({ ...prev, documentType: [] }));
      } else {
        const fileTypeValues = fileTypeOptions?.map((item) => item.value);
        setLocalFilters((prev) => ({ ...prev, documentType: fileTypeValues }));
      }
    } else {
      setLocalFilters((prev) => ({ ...prev, documentType: value }));
    }
  };

  const handleBlurDocType = () => {
    setIsShowDocCount(false);
    if (!isAllClear?.documentType) {
      handleApplyFilter(localFilters);
      setIsAllClear((prev) => ({ ...prev, documentType: true }));
    }
    setIsShowClearIcon((prev) => ({ ...prev, documentType: true }));
  };

  const handleAllClearDocType = () => {
    if (!isEmpty(localFilters?.documentType)) {
      setTimeout(() => {
        setIsAllClear((prev) => ({ ...prev, documentType: true }));
      }, 50);
      handleApplyFilter({ ...localFilters, documentType: [] });
    }
  };

  const handleSelectNodes = (selectedNodes: string[], title: string) => {
    setIsAllClear((prev) => ({ ...prev, [title]: false }));
    let ids: string[] = [];

    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node: any) => {
        ids.push(node?.value);
      });
    }
    setLocalFilters((prev) => ({ ...prev, allTaxonomy: { ...prev?.allTaxonomy, [title]: ids } }));
  };

  const handleBlurNodes = (title: string) => {
    if (!isAllClear?.[title]) {
      handleApplyFilter(localFilters);
      setIsAllClear((prev) => ({ ...prev, [title]: true }));
    }
    setIsShowClearIcon((prev) => ({ ...prev, [title]: true }));
  };

  const handleAllClearNodes = (title: string) => {
    if (!isEmpty(localFilters?.allTaxonomy?.[title])) {
      setTimeout(() => {
        setIsAllClear((prev) => ({ ...prev, [title]: true }));
      }, 50);
      handleApplyFilter({
        ...localFilters,
        allTaxonomy: { ...localFilters?.allTaxonomy, [title]: [] },
      });
    }
  };

  return (
    <div className="content-filter-card">
      {isAllTaxonomyLoading ? (
        <Skeleton title={false} active paragraph={{ rows: 2 }} />
      ) : (
        <>
          <div className="content-filter-wrap">
            {isShowContentType && (
              <div className="label-select-wrap">
                <div className="label-select">
                  Content Type{" "}
                  {localFilters?.contentType?.length > 0 && (
                    <span className="count">({localFilters?.contentType?.length})</span>
                  )}
                </div>
                <Select
                  className={`${!isEmpty(localFilters?.contentType) && "ant-select-clear"}`}
                  showSearch
                  mode="multiple"
                  popupClassName="ui-dropdown-default ui-dropdown-checkbox-default"
                  value={localFilters?.contentType || []}
                  options={contentTypeOptions}
                  allowClear={isShowClearIcon?.contentType}
                  onChange={handleSelectContentType}
                  filterOption={(inputValue: string, option: any) =>
                    option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                  }
                  menuItemSelectedIcon={({ isSelected }) => (
                    <span>
                      {isSelected ||
                      (localFilters?.contentType &&
                        localFilters?.contentType?.length === contentTypeOptions?.length) ? (
                        <Checkbox checked={true}></Checkbox>
                      ) : (
                        <Checkbox checked={false}></Checkbox>
                      )}
                    </span>
                  )}
                  onBlur={handleBlurContentType}
                  onClear={handleAllClearContentType}
                  onSearch={() => setIsShowClearIcon((prev) => ({ ...prev, contentType: false }))}
                />
              </div>
            )}

            {isShowContentStatus && (
              <div className="label-select-wrap">
                <div className="label-select">
                  Asset Status{" "}
                  {localFilters?.assetStatus !== undefined &&
                    localFilters?.assetStatus?.length > 0 && (
                      <span className="count">({localFilters?.assetStatus?.length})</span>
                    )}
                </div>
                <Select
                  className={`${!isEmpty(localFilters?.assetStatus) && "ant-select-clear"}`}
                  showSearch
                  mode="multiple"
                  popupClassName="ui-dropdown-default ui-dropdown-checkbox-default"
                  value={localFilters?.assetStatus || []}
                  options={assetStatusOptions}
                  allowClear={isShowClearIcon?.assetStatus}
                  onChange={handleSelectAssetStatus}
                  filterOption={(inputValue: string, option: any) =>
                    option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                  }
                  menuItemSelectedIcon={({ isSelected }) => (
                    <span>
                      {isSelected ||
                      (localFilters?.assetStatus &&
                        localFilters?.assetStatus?.length === assetStatusOptions?.length) ? (
                        <Checkbox checked={true}></Checkbox>
                      ) : (
                        <Checkbox checked={false}></Checkbox>
                      )}
                    </span>
                  )}
                  onBlur={handleBlurAssetStatus}
                  onClear={handleAllClearAssetStatus}
                  onSearch={() => setIsShowClearIcon((prev) => ({ ...prev, assetStatus: false }))}
                />
              </div>
            )}

            <div className="label-select-wrap">
              <div className="label-select">
                Type{" "}
                {localFilters?.assetType?.length > 0 && (
                  <span className="count">({localFilters?.assetType?.length})</span>
                )}
              </div>
              <Select
                className={`${!isEmpty(localFilters?.assetType) && "ant-select-clear"}`}
                showSearch
                allowClear={isShowClearIcon?.assetType}
                mode="multiple"
                popupClassName="ui-dropdown-default ui-dropdown-checkbox-default"
                value={localFilters?.assetType || []}
                onChange={handleSelectAssetType}
                options={assetTypeOptions}
                // options={[{ label: "Select All", value: "selectAll" }, ...assetTypeOptions]}
                filterOption={(inputValue: string, option: any) =>
                  option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }
                menuItemSelectedIcon={({ isSelected }) => (
                  <span>
                    {isSelected ||
                    (localFilters?.assetType &&
                      localFilters?.assetType?.length === assetTypeOptions?.length) ? (
                      <Checkbox checked={true}></Checkbox>
                    ) : (
                      <Checkbox checked={false}></Checkbox>
                    )}
                  </span>
                )}
                onBlur={handleBlurAssetType}
                onClear={handleAllClearAssetType}
                onSearch={() => setIsShowClearIcon((prev) => ({ ...prev, assetType: false }))}
              />
            </div>

            <div className="label-select-wrap">
              <div className="label-select">
                {!isShowDocCount && (
                  <>
                    File Type
                    {localFilters?.documentType?.length > 0 && (
                      <span className="count"> ({localFilters?.documentType?.length})</span>
                    )}
                  </>
                )}
              </div>
              <Select
                className={`${!isEmpty(localFilters?.documentType) && "ant-select-clear"}`}
                showSearch
                mode="multiple"
                popupClassName="ui-dropdown-default ui-dropdown-checkbox-default"
                value={localFilters?.documentType || []}
                allowClear={isShowClearIcon?.documentType}
                onChange={handleSelectDocType}
                onFocus={() => setIsShowDocCount(true)}
                onBlur={handleBlurDocType}
                onClear={handleAllClearDocType}
                options={fileTypeOptions}
                filterOption={(inputValue: string, option: any) =>
                  option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }
                menuItemSelectedIcon={({ isSelected }) => (
                  <span>
                    {isSelected ||
                    (localFilters?.documentType &&
                      localFilters?.documentType?.length === fileTypeOptions?.length) ? (
                      <Checkbox checked={true}></Checkbox>
                    ) : (
                      <Checkbox checked={false}></Checkbox>
                    )}
                  </span>
                )}
                onSearch={() => setIsShowClearIcon((prev) => ({ ...prev, documentType: false }))}
              />
            </div>

            {allTaxonomy?.map((v) => (
              <div className="label-select-wrap" key={v?.taxonomyId}>
                <div className="label-select">
                  {v?.title}{" "}
                  {localFilters?.allTaxonomy?.[v?.title]?.length > 0 && (
                    <span className="count">({localFilters?.allTaxonomy?.[v?.title]?.length})</span>
                  )}
                </div>
                <EditContentTags
                  className={`${
                    !isEmpty(localFilters?.allTaxonomy?.[v?.title]) && "ant-select-clear"
                  }`}
                  selectedTags={localFilters?.allTaxonomy?.[v?.title] || []}
                  mappedContentTags={mapDataToTreeSelect(v?.taxonomyTree)}
                  popupClassName="ui-choose-select ui-choose-multiple"
                  placeholder=""
                  handleChange={(e) => handleSelectNodes(e, v?.title)}
                  allowClear={isShowClearIcon?.[v?.title]}
                  isNonLeafNodeDisable={false}
                  treeCheckStrictly={true}
                  onBlur={() => handleBlurNodes(v?.title)}
                  onClear={() => handleAllClearNodes(v?.title)}
                  onSearch={() => setIsShowClearIcon((prev) => ({ ...prev, [v?.title]: false }))}
                />
              </div>
            ))}

            <div className="date-picker">
              <RangePicker
                className={`${
                  !isEmpty(localFilters?.startDate) &&
                  !isEmpty(localFilters?.endDate) &&
                  "ant-select-clear"
                }`}
                rootClassName="range-picker-default"
                presets={rangePresets}
                onChange={onRangeChange}
                value={
                  localFilters.startDate
                    ? [dayjs(localFilters.startDate), dayjs(localFilters.endDate)]
                    : null
                }
                allowClear={true}
              />
            </div>
          </div>

          <Button
            type="default"
            className="btn reset-btn"
            disabled={!isFilterSelected || isContentTableLoading}
            onClick={() => handleResetFilter()}>
            Reset
          </Button>
        </>
      )}
    </div>
  );
};

export default ContentSourceFilter;
