import { ChangeEvent, useRef, Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card } from "antd";
import { DeleteModal } from "../../../deleteModal";
import { RootState } from "../../../../store";
import { IThemeFile } from "../../../../store/appState/app.interface";
import { MaxFileSize } from "../../../../shared";

interface ILogoForm {
  localAppLogo: IThemeFile;
  setLocalAppLogo: Dispatch<SetStateAction<IThemeFile>>;
  localAssistantLogo: IThemeFile;
  setLocalAssistantLogo: Dispatch<SetStateAction<IThemeFile>>;
  setIsLogoUpdated: Dispatch<SetStateAction<boolean>>;
}

const logo = {
  appLogo: "appLogo",
  assistantLogo: "assistantLogo",
};

const LogoForm = (props: ILogoForm) => {
  const {
    localAppLogo,
    setLocalAppLogo,
    localAssistantLogo,
    setLocalAssistantLogo,
    setIsLogoUpdated,
  } = props;
  const { isGetThemeLoading, isUpdateThemeLoading } = useSelector((state: RootState) => state.app);
  const isDisable = isUpdateThemeLoading || isGetThemeLoading;

  const appLogoInputRef = useRef<HTMLInputElement>(null);
  const assistantLogoInputRef = useRef<HTMLInputElement>(null);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [selectedLogo, setSelectedLogo] = useState<string>("");

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>, setFileDetails: Function) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file?.size > MaxFileSize.logo) {
        toast.error("File size should be less than 200KB");
      } else {
        const fileName = file?.name ?? "";

        setIsLogoUpdated(true);
        setFileDetails((prev: IThemeFile) => ({
          ...prev,
          file: file,
          fileName: fileName,
        }));
      }
    }
    e.target.value = "";
  };

  const handleClickUploadFile = (fileRef: any) => {
    if (fileRef) {
      fileRef.current.click();
    }
  };

  const handleClickDeleteIcon = (logo: string) => {
    setIsShowDeleteModal(true);
    setSelectedLogo(logo);
  };

  const handleRemoveFile = () => {
    if (selectedLogo === logo.appLogo) {
      setLocalAppLogo({ file: null, fileName: "" });
    } else if (selectedLogo === logo.assistantLogo) {
      setLocalAssistantLogo({ file: null, fileName: "" });
    }
    setIsLogoUpdated(true);
    setIsShowDeleteModal(false);
  };

  const handleCloseDeleteModal = () => {
    setIsShowDeleteModal(false);
    setSelectedLogo("");
  };

  return (
    <>
      <Card className="theme-inner">
        <h3>Upload Logo*</h3>
        <div className="card-list">
          <Card className="card-item">
            <label className="item-label">Application Logo</label>
            <div className="browse-container">
              <div className="browse-wrap">
                <input
                  hidden
                  ref={appLogoInputRef}
                  className="file-input"
                  type="file"
                  multiple={false}
                  placeholder="Upload Asset"
                  onChange={(e) => handleFileUpload(e, setLocalAppLogo)}
                  accept=".jpg, .jpeg, .png, .svg"
                />
                <div className="upload-file-container">
                  <div className="file-name">
                    {localAppLogo?.fileName ? (
                      <>
                        <div className="file-name-div acn-flex">
                          <i className="ri-attachment-2 browseIcon"></i>
                          <span className="file-name-span">{localAppLogo?.fileName}</span>
                        </div>
                        <Button
                          type="text"
                          className="text-btn remove-file"
                          disabled={isDisable}
                          onClick={() => handleClickDeleteIcon(logo.appLogo)}>
                          <i className="ri-delete-bin-fill"></i>
                        </Button>
                      </>
                    ) : (
                      <span className="acn-flex acn-flex-middle">
                        <i className="ri-attachment-2 browseIcon"></i>
                        <span>Browse</span>
                      </span>
                    )}
                  </div>

                  <Button
                    onClick={() => handleClickUploadFile(appLogoInputRef)}
                    className=" btn btn-primary browse-btn"
                    disabled={isDisable}
                    type="primary"
                    size="middle">
                    Browse
                  </Button>
                </div>
              </div>
              <div className="browse-container-label">
                Dimensions - 203x158 pixels | File Size Limit : 200kb
              </div>
            </div>
          </Card>

          <Card className="card-item" hidden={false}>
            <label className="item-label">Assistant Logo</label>
            <div className="browse-container">
              <div className="browse-wrap">
                <input
                  hidden
                  ref={assistantLogoInputRef}
                  className="file-input"
                  type="file"
                  multiple={false}
                  placeholder="Upload Asset"
                  onChange={(e) => handleFileUpload(e, setLocalAssistantLogo)}
                  accept=".jpg, .jpeg, .png, .svg"
                />
                <div className="upload-file-container">
                  <div className="file-name">
                    {localAssistantLogo?.fileName ? (
                      <>
                        <div className="file-name-div acn-flex">
                          <i className="ri-attachment-2 browseIcon"></i>
                          <span className="file-name-span">{localAssistantLogo?.fileName}</span>
                        </div>
                        <Button
                          type="text"
                          disabled={isDisable}
                          className="text-btn remove-file"
                          onClick={() => handleClickDeleteIcon(logo.assistantLogo)}>
                          <i className="ri-delete-bin-fill"></i>
                        </Button>
                      </>
                    ) : (
                      <span className="acn-flex acn-flex-middle">
                        <i className="ri-attachment-2 browseIcon"></i>
                        <span>Browse</span>
                      </span>
                    )}
                  </div>

                  <Button
                    onClick={() => handleClickUploadFile(assistantLogoInputRef)}
                    className=" btn btn-primary browse-btn"
                    type="primary"
                    disabled={isDisable}
                    size="middle">
                    Browse
                  </Button>
                </div>
              </div>
              <div className="browse-container-label">
                Dimensions - 120x120 pixels | File Size Limit : 200kb
              </div>
            </div>
          </Card>
        </div>
      </Card>

      {isShowDeleteModal && (
        <DeleteModal
          isLoading={false}
          isModalOpen={isShowDeleteModal}
          handleCancel={handleCloseDeleteModal}
          handleSubmit={handleRemoveFile}
          message="Are you sure you want to delete this Logo?"
          title="Delete Logo"
        />
      )}
    </>
  );
};

export default LogoForm;
