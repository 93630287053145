import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import {
  setIsOpenChatHistory,
  setSelectedFilesForAssetFilter,
  setStartNewChatFromInsight,
  setStartNewConversation,
} from "../../../store/conversationalSearch/conversationalSearchSlice";
import { resetProfileResumes } from "../../../store/profile/profileSlice";
import {
  removeFilterData,
  setAllAssetTypesSelected,
  setAllFileTypesSelected,
} from "../../../store/filtersFromTaxonomyData/filters.slice";
import { setContentSelectedIds } from "../../../store/contentManagement/contentSlice";
import { useChats } from "../useChat";
import { getAllIds } from "../../helpers";
import { fileType } from "../../constants";

export const useFindProfile = () => {
  const dispatch = useDispatch();
  const { assetTypeTaxonomy } = useSelector((state: RootState) => state.taxonomy);
  const { defaultConfigureState, clearAllChatStates } = useChats();

  const handleClearFindProfileChat = () => {
    clearAllChatStates();
    defaultConfigureState();
    // startNewChat();
    dispatch(removeFilterData([]));
    dispatch(setAllFileTypesSelected(fileType));
    dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
    dispatch(setIsOpenChatHistory(false));
    dispatch(resetProfileResumes());

    dispatch(setStartNewConversation(true));
    dispatch(setStartNewChatFromInsight(false));
    dispatch(setSelectedFilesForAssetFilter([]));
    dispatch(setContentSelectedIds([]));
  };

  return {
    handleClearFindProfileChat,
  };
};
