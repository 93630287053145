import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { MicPermissions, socialLogin } from "../../../shared";

interface IWhisperAudioProps {
  isDisable: boolean;
  isAudioLoading: boolean;
  setQuestion: (question: string) => void;
  setIsAudioLoading: (isAudioLoading: boolean) => void;
  setDisableInputWhileRecording: (isRecording: boolean) => void;
}

const WhisperAudioFile = (props: IWhisperAudioProps) => {
  const {
    isDisable,
    setQuestion,
    isAudioLoading,
    setIsAudioLoading,
    setDisableInputWhileRecording,
  } = props;

  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
  const [micPermission, setMicPermission] = useState<MicPermissions | string>(
    MicPermissions.prompt
  );
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);

  const analyserNodeRef = useRef<AnalyserNode | null>(null);
  const silenceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null); // Store interval ID separately

  const apiKey = socialLogin.OPENAI_API_KEY;

  useEffect(() => {
    // Check microphone permission status
    navigator.permissions
      .query({ name: "microphone" as PermissionName })
      .then((permissionStatus) => {
        setMicPermission(permissionStatus.state);

        // Listen for changes in permission status
        permissionStatus.onchange = () => {
          setMicPermission(permissionStatus.state);
        };
      });
  }, []);

  useEffect(() => {
    if (isRecording && recorder) {
      recorder.start();
    } else if (!isRecording && recorder) {
      recorder.stop();
    }

    return () => {
      if (recorder) {
        recorder.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isRecording, recorder]);

  const handleStartRecording = async () => {
    setDisableInputWhileRecording(true);

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    const audioChunks: BlobPart[] = [];

    // Setup audio context and analyser node for silence detection
    const audioCtx = new AudioContext();
    const source = audioCtx.createMediaStreamSource(stream);
    const analyser = audioCtx.createAnalyser();
    analyser.fftSize = 2048;
    source.connect(analyser);
    analyserNodeRef.current = analyser;
    setAudioContext(audioCtx);

    mediaRecorder.ondataavailable = (event: BlobEvent) => {
      audioChunks.push(event.data);
    };

    mediaRecorder.onstop = () => {
      setIsAudioLoading(true);

      const audioBlob = new Blob(audioChunks, { type: "audio/mp3" });
      handleTranscription(audioBlob);
      stopSilenceDetection();
    };

    setRecorder(mediaRecorder);
    setIsRecording(true);
    startSilenceDetection();
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    if (recorder) {
      recorder.stop();
    }
    stopSilenceDetection();
  };

  const startSilenceDetection = () => {
    const detectSilence = () => {
      if (!analyserNodeRef.current) return;

      const bufferLength = analyserNodeRef.current.fftSize;
      const dataArray = new Uint8Array(bufferLength);
      analyserNodeRef.current.getByteTimeDomainData(dataArray);

      // Analyze audio data and detect silence
      const isSilent = dataArray.every((value) => value < 128 + 2 && value > 128 - 2);

      if (isSilent) {
        if (!silenceTimeoutRef.current) {
          silenceTimeoutRef.current = setTimeout(() => {
            handleStopRecording();
          }, 1200); // Stop recording after 1 seconds of silence
        }
      } else {
        if (silenceTimeoutRef.current) {
          clearTimeout(silenceTimeoutRef.current);
          silenceTimeoutRef.current = null;
        }
      }
    };

    intervalIdRef.current = setInterval(detectSilence, 100); // Check for silence every 100ms
  };

  const stopSilenceDetection = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
      silenceTimeoutRef.current = null;
    }

    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    if (audioContext) {
      audioContext.close();
    }
  };

  const handleTranscription = async (audioBlob: Blob) => {
    const formData = new FormData();
    formData.append("file", new File([audioBlob], "audio.mp3"));
    formData.append("model", "whisper-1");

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/audio/transcriptions",
        formData,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setQuestion(response.data.text);
    } catch (error: any) {
      console.error("Error transcribing audio:", error.response?.data || error.message);
      toast.error(error?.response?.data?.error?.message);
    } finally {
      setIsAudioLoading(false);
      setDisableInputWhileRecording(false);
    }
  };

  const renderMicIcon = () => {
    if (micPermission === MicPermissions.denied) {
      return (
        <i
          className="ri-mic-off-fill"
          onClick={() =>
            toast.error(
              "Microphone access is denied. Please enable the microphone in your browser settings"
            )
          }
        />
      );
    } else {
      if (isAudioLoading) {
        return <Spin indicator={<LoadingOutlined spin />} size="small" />;
      } else {
        if (isRecording) {
          return (
            <Tooltip trigger="hover" title={"Stop"} overlayClassName="tooltip-text">
              <div className="stop-recording">
                <i className="ri-stop-fill" onClick={handleStopRecording} />
              </div>
            </Tooltip>
          );
        } else {
          return isDisable ? (
            <i className="ri-mic-fill mic-deActive" />
          ) : (
            <Tooltip trigger="hover" title={"Search by voice"} overlayClassName="tooltip-text">
              <i className="ri-mic-fill" onClick={handleStartRecording} />
            </Tooltip>
          );
        }
      }
    }
  };

  return <div className="mic-btn position-absolute">{renderMicIcon()}</div>;
};

export default WhisperAudioFile;
