import { useState } from "react";
import { Col, Flex, Row } from "antd";
import { motion } from "framer-motion";
import { Outlet } from "react-router-dom";
import { HeadLogo } from "../../components";
import "./Auth.scss";

const AuthLayout = () => {
  const [productFeature] = useState<any[]>([
    {
      icon: <i className="ci ci-feature-1-icon" />,
      title: "Discover Insights & Knowledge from your organization",
    },
    {
      icon: <i className="ci ci-feature-2-icon" />,
      title: "Talk to your data via Chat or Voice",
    },
    {
      icon: <i className="ci ci-feature-3-icon" />,
      title: "Perform Actions across applications via Natural Language",
    },
    {
      icon: <i className="ci ci-feature-4-icon" />,
      title: "Generate Documents & Content on the go",
    },
  ]);

  return (
    <div className="auth-layout-container">
      <HeadLogo />
      <Flex id="auth-layout" className="auth-layout" align="center">
        <Row>
          <Col span={14}>
            <div className="auth-dynamic-box">
              <Outlet />
            </div>
          </Col>

          <Col span={10} style={{ alignSelf: "center" }} className="auth-content-col">
            <h2>AI Assistant backbone for your Enterprise</h2>
            <div className="content">
              <p>
                We are on a mission to organize your institutions knowledge (both internal &
                external) and generate Insights using GenAI.
              </p>
            </div>
            <ul className="product-features">
              {productFeature.map((item: any, index: number) => (
                <motion.li
                  key={index}
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: index * 0.15 }}>
                  <span className="icon">{item?.icon}</span>
                  <span className="span-text">{item.title}</span>
                </motion.li>
              ))}
            </ul>
          </Col>
        </Row>
      </Flex>
    </div>
  );
};

export default AuthLayout;
