import { PURGE } from "redux-persist";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IHomeBookmarkedContent, IHomeSliceProps } from "./home.interface";
import { IContent } from "../contentManagement/content.interface";

const initialState: IHomeSliceProps = {
  homeBookmarkedContent: [],
  isHomeBookmarkedContentLoading: false,
  recentlyViewedAssets: [],
  isRecentlyViewedAssetsLoading: false,
  isOthersLookingAssetsLoading: false,
  othersLookingAssets: [],
};

export const homeSlice = createSlice({
  name: "homeSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setHomeBookmarkedContent: (state, action: PayloadAction<IHomeBookmarkedContent[]>) => {
      state.homeBookmarkedContent = action.payload;
    },

    setIsHomeBookmarkedContentLoading: (state, action: PayloadAction<boolean>) => {
      state.isHomeBookmarkedContentLoading = action.payload;
    },

    setRecentlyViewedAssets: (state, action: PayloadAction<IContent[]>) => {
      state.recentlyViewedAssets = action.payload;
    },
    setIsRecentlyViewedAssetsLoading: (state, action: PayloadAction<boolean>) => {
      state.isRecentlyViewedAssetsLoading = action.payload;
    },

    setIsOthersLookingAssetsLoading: (state, action: PayloadAction<boolean>) => {
      state.isOthersLookingAssetsLoading = action.payload;
    },
    setOthersLookingAssets: (state, action: PayloadAction<IContent[]>) => {
      state.othersLookingAssets = action.payload;
    },
  },
});

export const {
  setHomeBookmarkedContent,
  setIsHomeBookmarkedContentLoading,
  setRecentlyViewedAssets,
  setIsRecentlyViewedAssetsLoading,
  setIsOthersLookingAssetsLoading,
  setOthersLookingAssets,
} = homeSlice.actions;

export default homeSlice.reducer;
