import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { useSelector } from "react-redux";
import { Progress } from "antd";
import "./AudioPlayer.scss";
import { RootState } from "../../../store";
import { useDispatch } from "react-redux";
import { setIsAudioPlaying, setLoadingAudio } from "../../../store/mediaUpload/mediaUploadSlice";
import { ToggleAudio } from "../../constants";

interface AudioPlayerProps {
  src: string;
  detailPageView: boolean;
}

const AudioPlayer = React.forwardRef<HTMLAudioElement, AudioPlayerProps>((props, audioRef) => {
  const { src, detailPageView } = props;

  const [percentage, setPercentage] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);

  const { videoTime, loadingAudio, isAudioPlaying, toggleAudio } = useSelector(
    (state: RootState) => state.mediaUploadSlice
  );

  const dispatch = useDispatch();

  const waveformRef = useRef<HTMLDivElement>(null); // Reference for the waveform container
  const wavesurfer = useRef<WaveSurfer | null>(null); // Reference for WaveSurfer instance

  useEffect(() => {
    if (waveformRef.current) {
      if (wavesurfer.current) {
        wavesurfer.current.destroy(); // Destroy any previous instance
      }

      // Initialize WaveSurfer
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current!,
        waveColor: "#00f", // Customize wave color
        progressColor: "#f00", // Customize progress color
        barWidth: 2,
        cursorWidth: 1,
        backend: "MediaElement",
        mediaControls: false,
      });

      // Load the audio file
      wavesurfer.current.load(src);

      wavesurfer.current.on("loading", (progress) => {
        setPercentage(progress);
      });
      // Play from Redux-controlled videoTime position

      // Handle ready event and clear loading text
      wavesurfer.current.on("ready", () => {
        dispatch(setLoadingAudio(false));
      });

      // Update duration when ready *
      wavesurfer.current.on("ready", () => {
        setTotalDuration(wavesurfer.current!.getDuration());
      });

      // Update current time during playback *
      wavesurfer.current.on("audioprocess", () => {
        setCurrentTime(wavesurfer.current!.getCurrentTime());
      });

      // Handle errors and reset loading status
      wavesurfer.current.on("error", (e) => {
        console.error("WaveSurfer error:", e);
        // setLoading(false);
        // setLoadProgress(0);
        if (waveformRef.current) {
          waveformRef.current.innerHTML = `<p className="audio-error">Error loading audio</p>`;
        }
      });

      // Cleanup on component unmount
      return () => wavesurfer.current?.destroy();
    }
  }, [src]);

  useEffect(() => {
    if (wavesurfer.current) {
      const duration = wavesurfer.current!.getDuration();
      if (videoTime && duration > 0) {
        const position = videoTime / duration;
        wavesurfer.current!.seekTo(position);
        wavesurfer.current!.play();
        dispatch(setIsAudioPlaying(true));
      }
    }
  }, [videoTime]);

  useEffect(() => {
    if (toggleAudio === ToggleAudio.play || toggleAudio === ToggleAudio.pause) {
      togglePlayPause();
    }
  }, [toggleAudio]);

  // Toggle play/pause
  const togglePlayPause = () => {
    if (wavesurfer.current) {
      if (isAudioPlaying) {
        wavesurfer.current.pause();
      } else {
        wavesurfer.current.play();
      }
      dispatch(setIsAudioPlaying(!isAudioPlaying));
    }
  };

  // Handle timeline seek
  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (wavesurfer.current) {
      const seekTo = parseFloat(e.target.value);
      wavesurfer.current.seekTo(seekTo / totalDuration);
      setCurrentTime(seekTo);
    }
  };

  // Format time in mm:ss
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const renderAudioPLayer = () => {
    return (
      <div className="audio-player-container">
        {loadingAudio && (
          <div className="progress-bar">
            <Progress type="circle" percent={percentage} />
          </div>
        )}
        {/* Wavy visualization */}
        <div
          ref={waveformRef}
          style={{ visibility: loadingAudio ? "hidden" : "visible" }}
          className="waveform-wrap"
        />

        <audio ref={audioRef} src={src} hidden />

        {/* Custom Controls */}
        <div className="controls">
          <div onClick={togglePlayPause} className="play-pause">
            {isAudioPlaying ? (
              <i className="ri-stop-fill play-icon" />
            ) : (
              <i className="ri-play-fill play-icon" />
            )}
          </div>

          <span className="time">{formatTime(currentTime)}</span>

          <input
            type="range"
            min="0"
            max={totalDuration.toFixed(2)}
            value={currentTime}
            onChange={handleSeek}
            className="timeline"
          />

          <span className="time">{formatTime(totalDuration)}</span>
        </div>
      </div>
    );
  };

  const renderAudioPLayerForDetailPage = () => {
    return (
      <div className="audio-player-container-detail-page">
        {loadingAudio && (
          <div className="progress-bar">
            <Progress type="circle" percent={percentage} />
          </div>
        )}
        {/* Wavy visualization */}
        <div
          ref={waveformRef}
          style={{ visibility: loadingAudio ? "hidden" : "visible" }}
          className="waveform-wrap"
        />
        <audio ref={audioRef} src={src} hidden />
      </div>
    );
  };

  return detailPageView ? renderAudioPLayerForDetailPage() : renderAudioPLayer();
});

export default AudioPlayer;
