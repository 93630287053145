import { Card, Col, Row, Skeleton } from "antd";
import { formattedNum, GradientAreaChart } from "../../../shared";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import "./StatsCard.scss";

const StatsCard = () => {
  const { isAssetCountLoading, assetCount } = useSelector((state: RootState) => state.app);

  const assetViewedOptions: ApexCharts.ApexOptions = {
    colors: ["rgba(232, 190, 39, 0.26)", "rgba(255, 255, 255, 0)"],
    series: [
      {
        name: "Series 1",
        data: [10, 60, 70, 60, 100, 70, 80],
      },
    ],
  };

  const totalDownloadOptions: ApexCharts.ApexOptions = {
    colors: ["rgba(167, 1, 1, 0.26)", "rgba(255, 255, 255, 0)"],
    series: [
      {
        name: "Series 1",
        data: [10, 60, 70, 60, 100, 70, 55],
      },
    ],
  };

  return (
    <>
      <Row className="my-content-bar global-gap">
        {isAssetCountLoading ? (
          [...Array(3)].map((_, index) => (
            <Col key={index}>
              <Card>
                <Skeleton avatar title={false} active paragraph={{ rows: 1 }} />
              </Card>
            </Col>
          ))
        ) : (
          <>
            <Col>
              <Card>
                <div className="card-icon">
                  <i className="ci-asset-contribute ci"></i>
                </div>
                <p className="card-title">Total Contributed</p>
                <div className="card-data">
                  <p className="card-data-info">
                    {formattedNum(assetCount?.assets_contributed ?? 0)}
                  </p>
                  <span className="card-data-extra">Keep it going!</span>
                </div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div className="card-icon">
                  <i className="ci-asset-viewed ci"></i>
                </div>
                <p className="card-title">Total Viewed</p>
                <GradientAreaChart options={assetViewedOptions} />
                <div className="card-data">
                  <p className="card-data-info">{formattedNum(assetCount?.view_count ?? 0)}</p>
                </div>
              </Card>
            </Col>

            <Col>
              <Card>
                <div className="card-icon">
                  <i className="ci-total-download ci"></i>
                </div>
                <p className="card-title">Total Downloads</p>
                <GradientAreaChart options={totalDownloadOptions} />
                <div className="card-data">
                  <p className="card-data-info">{formattedNum(assetCount?.download_count ?? 0)}</p>
                </div>
              </Card>
            </Col>
          </>
        )}

        {/* <Col span={6}>
          <Card>
            <div className="card-icon">
              <i className="ci-pending-asset ci"></i>
            </div>
            <p className="card-title">Pending Approval Assets</p>
            <div className="card-data">
              <p className="card-data-info">{formattedNum(assetCount?.draft_assets ?? 0)}</p>
              <span className="card-data-extra">
                <Link to="/">View all</Link>
              </span>
            </div>
          </Card>
        </Col> */}
      </Row>
    </>
  );
};

export default StatsCard;
