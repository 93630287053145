import { toast } from "react-toastify";
import { deleteRequest, get, post, put } from "./apiClients";
import { store } from "../store";
import {
  deleteDataConnectionById,
  setAllSharepointFoldersName,
  setAllSharepointSites,
  setAllSources,
  setDataConnectionCurrPage,
  setDataConnectionId,
  setIsAllSourcesLoading,
  setIsConnectDataSourceLoading,
  setIsDeleteSourceLoading,
  setIsStatusUpdating,
  setSharepointFoldersLoading,
  setTotalConnectionCount,
} from "../store/dataConnection/dataConnectionSlice";

export const getAllSources = async (page: number) => {
  try {
    store.dispatch(setIsAllSourcesLoading(true));
    const res = await get(`/core/sharepoint/all-source?page=${page - 1}`);

    const dataRes = res.content ?? [];
    const totalElementsRes = res.totalElements ?? 0;
    const currPageRes = res.number ? res.number + 1 : 1;

    store.dispatch(setAllSources(dataRes));
    store.dispatch(setTotalConnectionCount(totalElementsRes));
    store.dispatch(setDataConnectionCurrPage(currPageRes));
  } catch (error) {
    console.log(error, "error in getAllSources");
    store.dispatch(setAllSources([]));
    return error;
  } finally {
    store.dispatch(setIsAllSourcesLoading(false));
  }
};

export const sharepointConnect = async (
  request: {
    username: string;
    password: string;
    domain: string;
    client_id: string;
    tenant_id: string;
    client_secret: string;
  },
  callBack: () => void
) => {
  try {
    store.dispatch(setIsConnectDataSourceLoading(true));

    const { data } = await post("/core/sharepoint/token", request);

    const { dataConnectionId } = data;

    store.dispatch(setDataConnectionId(dataConnectionId));

    getSharepointSites(dataConnectionId);

    callBack();
  } catch (error: any) {
    console.log(error?.errorMessage, "error in sharepointConnect");
    toast.error(error?.errorMessage || "Failed to connect");
  } finally {
    store.dispatch(setIsConnectDataSourceLoading(false));
  }
};

export const getSharepointSites = async (dataConnectionId: number) => {
  try {
    store.dispatch(setSharepointFoldersLoading(true));

    const res = await get(`core/sharepoint/all-site`, {
      params: {
        connection_id: dataConnectionId,
      },
    });

    store.dispatch(setAllSharepointSites(res));
  } catch (error: any) {
    console.log(error?.error, "error in getSharepointSites");
    toast.error(error?.error || "Failed to fetch sites");
  } finally {
    store.dispatch(setSharepointFoldersLoading(false));
  }
};

export const getSharepointAllFolders = async (dataConnectionId: number) => {
  try {
    store.dispatch(setSharepointFoldersLoading(true));

    const res = await get(`core/sharepoint/${dataConnectionId}/all-folder-name`);
    store.dispatch(setAllSharepointFoldersName(res));
  } catch (error: any) {
    console.log(error?.error, "error in sharepointConnect");
    toast.error(error?.error || "Failed to fetch folders");
  } finally {
    store.dispatch(setSharepointFoldersLoading(false));
  }
};

export const selectSharepointSite = async (
  dataConnectionId: number,
  site_name: string,
  site_id: string,
  callBack: () => void
) => {
  try {
    store.dispatch(setIsConnectDataSourceLoading(true));

    await put(`/core/sharepoint/add/sites`, {
      dataConnectionId,
      site_name,
      site_id,
    });

    getSharepointAllFolders(dataConnectionId);

    callBack();
  } catch (error: any) {
    console.log(error?.error, "error in selectSharepointFolders");
    toast.error(error?.error || "Failed to connect");
  } finally {
    store.dispatch(setIsConnectDataSourceLoading(false));
  }
};

export const selectSharepointFolders = async (
  dataConnectionId: number,
  folders: string[],
  callBack: () => void
) => {
  try {
    store.dispatch(setIsConnectDataSourceLoading(true));

    await put(`/core/sharepoint/add/doc-libraries`, {
      dataConnectionId: dataConnectionId,
      folders: folders,
    });

    callBack();
  } catch (error: any) {
    console.log(error?.error, "error in selectSharepointFolders");
    toast.error(error?.error || "Failed to connect");
  } finally {
    store.dispatch(setIsConnectDataSourceLoading(false));
  }
};

export const setFrequency = async (
  dataConnectionId: number,
  frequency: number,
  dataConnectionCurrPage: number
) => {
  try {
    store.dispatch(setIsConnectDataSourceLoading(true));

    await put(`core/sharepoint/set/frequency`, {
      dataConnectionId: dataConnectionId,
      frequency: frequency,
    });

    getAllSources(dataConnectionCurrPage);
  } catch (error: any) {
    console.log(error?.error, "error in selectFrequency");
    toast.error(error?.error || "Failed to connect");
  } finally {
    store.dispatch(setIsConnectDataSourceLoading(false));
  }
};

// export const getSourcesById = async (sourceId: number) => {
//   try {
//     await get(`/core/sharepoint/source/${sourceId}`);
//   } catch (error) {
//     console.log(error);
//     return error;
//   } finally {
//   }
// };

export const deleteDataConnection = async (dataConnectionId: number, messageRequired: boolean) => {
  try {
    store.dispatch(setIsDeleteSourceLoading(true));

    await deleteRequest(`/core/sharepoint/connection/${dataConnectionId}`);
    store.dispatch(deleteDataConnectionById(dataConnectionId));
    messageRequired && toast.success("Data connection deleted successfully");
  } catch (error: any) {
    console.log(error, "deleteAccessGroups error");
    toast.error(error?.message ?? "Failed to delete data connection");
  } finally {
    store.dispatch(setIsDeleteSourceLoading(false));
  }
};

export const updateSharepointConnection = async (
  dataConnectionId: number,
  folders: string[],
  frequency: number
) => {
  try {
    store.dispatch(setIsConnectDataSourceLoading(true));

    await put(`/core/sharepoint/connection`, {
      dataConnectionId,
      folders,
      frequency,
    });

    toast.success("Connection details successfully updated");
  } catch (error: any) {
    toast.error(error?.error || "Failed to update");
  } finally {
    store.dispatch(setIsConnectDataSourceLoading(false));
  }
};

export const updateStatus = async (dataConnectionId: number, isActive: boolean) => {
  try {
    const status = isActive ? "A" : "D";
    store.dispatch(setIsStatusUpdating(true));

    const res = await put(
      `/core/sharepoint/connection/${dataConnectionId}/status?status=${status}`
    );
    toast.success("Status successfully updated");
    return res;
  } catch (error: any) {
    toast.error(error?.error || "Failed to update status");
    return error;
  } finally {
    store.dispatch(setIsStatusUpdating(false));
  }
};
