import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { defaultLogo } from "../../shared";
import "./HeadLogo.scss";

interface IHeadLogoProps {
  type?: "lg" | "sm";
}

const HeadLogo: FC<IHeadLogoProps> = ({ type }) => {
  const { appearance } = useSelector((state: RootState) => state.app);

  const defaultLgUrl = defaultLogo.appLogoLg;
  const defaultSmUrl = defaultLogo.appLogo;
  let logoURL: string = appearance?.appLogo?.lg || defaultLgUrl;

  if (type === "lg") {
  } else if (type === "sm") {
    logoURL = appearance?.appLogo?.url;
  }

  logoURL = logoURL || defaultSmUrl;

  return (
    <div className="logo d-flex d-flex-middle">
      <img
        className="logo-img"
        src={logoURL}
        alt="logo"
        onError={(e) => (e.currentTarget.src = defaultSmUrl)}
      />
      {/* {appearance.logo.text === "" || type ? (
        <img className="logo-img" src={logoURL} alt="logo" />
      ) : (
        <span className="logo-text">{appearance.logo.text}</span>
      )} */}
    </div>
  );
};

export default HeadLogo;
