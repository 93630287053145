import { PURGE } from "redux-persist";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  IAllMergedProfiles,
  IAllProfiles,
  IProfileProps,
  IProfileResume,
} from "./profile.interface";

const profileDetailsInitialValues: IAllMergedProfiles = {
  asset_id: -1,
  bucket_name: "",
  file_name: "",
  is_resume: false,
  is_shortlisted: false,
  name: "",
  name_in_resume: "",
  pre_signed_url: "",
  relevant_score: 0,
  resume_description: "",
  score: 0,
  why_resume: "",
  thumbnail_pre_signed_url: "",
};

const initialState: IProfileProps = {
  isAllProfileLoading: false,
  allProfiles: [],
  isShortlistAssetLoading: "",
  profileResume: [],
  allMergedProfiles: [] as IAllMergedProfiles[],
  allMergedProfilesFiltered: [],
  isFilterApplied: false,
  profileDetails: profileDetailsInitialValues,
  selectedProfiles: [],
};

export const profileSlice = createSlice({
  name: "profileSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setIsAllProfileLoading: (state, action: PayloadAction<boolean>) => {
      state.isAllProfileLoading = action.payload;
    },

    setProfileResume: (state, action: PayloadAction<IProfileResume[]>) => {
      state.profileResume = action.payload;
    },

    setAllProfiles: (state, action: PayloadAction<IAllProfiles[]>) => {
      state.allProfiles = action.payload;

      state.allMergedProfiles = state.profileResume
        .map((profileResume) => {
          const matchingProfile = action.payload.find(
            (profile) => profile.file_name === profileResume.file_name
          );

          if (matchingProfile) {
            return {
              ...profileResume,
              ...matchingProfile,
            };
          }
          return null; // Return null if no matching file_name found
        })
        .filter((mergedProfile): mergedProfile is IAllMergedProfiles => mergedProfile !== null); // Filter out null values and ensure type safety

      state.allMergedProfilesFiltered = state.allMergedProfiles;
    },

    setIsShortlistAssetLoading: (state, action: PayloadAction<string>) => {
      state.isShortlistAssetLoading = action.payload;
    },

    updateShortlistedResume: (
      state,
      action: PayloadAction<{ fileName: string; isShortlisted: boolean }>
    ) => {
      state.allMergedProfiles =
        state.allMergedProfiles &&
        state.allMergedProfiles.map((v) =>
          v?.file_name === action?.payload?.fileName
            ? { ...v, is_shortlisted: action?.payload?.isShortlisted }
            : v
        );

      state.allMergedProfilesFiltered = state.allMergedProfilesFiltered.map((v) =>
        v?.file_name === action?.payload?.fileName
          ? { ...v, is_shortlisted: action?.payload?.isShortlisted }
          : v
      );
    },

    filterShortlistedResume: (state, action: PayloadAction<boolean>) => {
      if (action.payload === true) {
        state.isFilterApplied = true;

        state.allMergedProfiles = state.allMergedProfiles.filter(
          (v) => v.is_shortlisted === action?.payload
        );
      } else {
        state.isFilterApplied = false;
        state.allMergedProfiles = state.allMergedProfilesFiltered;
      }
    },

    setProfileDetails: (state, action: PayloadAction<IAllMergedProfiles>) => {
      state.profileDetails = action.payload;
    },

    setSelectedProfiles: (state, action: PayloadAction<IAllMergedProfiles[]>) => {
      state.selectedProfiles = action.payload;
    },

    resetProfileResumes: (state) => {
      state.isFilterApplied = false;
      state.allMergedProfiles = [];
      state.selectedProfiles = [];
      state.allMergedProfilesFiltered = [];
      state.profileResume = [];
    },
  },
});

export const {
  setAllProfiles,
  setProfileResume,
  setProfileDetails,
  setSelectedProfiles,
  resetProfileResumes,
  setIsAllProfileLoading,
  updateShortlistedResume,
  filterShortlistedResume,
  setIsShortlistAssetLoading,
} = profileSlice.actions;

export default profileSlice.reducer;
