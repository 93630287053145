import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Flex,
  Input,
  Row,
  Switch,
  Table,
  TableProps,
} from "antd";
import "./AccessGroup.scss";
import { RootState } from "../../../store";
import { getTaxonomyByTitle } from "../../../services/taxonomy";
import { mapDataToTreeSelect } from "../../../shared/taxonomyHelper";
import { IaccessGroups } from "../../../store/accessGroup/accessGroup.interface";
import {
  convertNumberArrayToStringArray,
  CustomPagination,
  isEmpty,
  PageLimit,
  TaxonomyTitle,
  useDebounce,
} from "../../../shared";
import {
  AssetTagsGroup,
  DeleteModal,
  EditContentTags,
  PageHeader,
  TableSkeleton,
} from "../../../components";
import {
  CreateAccessGroup,
  deleteAccessGroups,
  getAccessGroups,
  UpdateAccessGroup,
} from "../../../services/accessGroup";
import {
  accessGroupsInitialState,
  setAccessGroupPage,
  setAccessGroups,
  setAccessGroupSearch,
} from "../../../store/accessGroup/accessGroupSlice";

const AccessGroup = () => {
  const dispatch = useDispatch();
  const { industryTaxonomy, functionTaxonomy } = useSelector((state: RootState) => state.taxonomy);
  const {
    accessGroups,
    accessGroupPage,
    accessGroupSearch,
    isAccessGroupsLoading,
    totalAccessGroupsCount,
    isAccessGroupDeleting,
  } = useSelector((state: RootState) => state.accessGroup);

  const [addNew, setAddNew] = useState(false);
  const [groupList, setGroupList] = useState<IaccessGroups[]>([]);
  const [selectedAccessId, setSelectedAccessId] = useState<number>(-1);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowDiscardChanges, setIsShowDiscardChanges] = useState<boolean>(false);

  const mappedContentIndustry = mapDataToTreeSelect(industryTaxonomy.taxonomyTree ?? []);
  const mappedContentFunction = mapDataToTreeSelect(functionTaxonomy.taxonomyTree ?? []);

  useEffect(() => {
    getAccessGroups(true, accessGroupSearch, accessGroupPage);
  }, [accessGroupPage]);

  useEffect(() => {
    getTaxonomyByTitle(TaxonomyTitle.Industry);
    getTaxonomyByTitle(TaxonomyTitle.Function);
  }, []);

  useEffect(() => {
    setAddNew(false);
    setGroupList(accessGroups);
  }, [accessGroups]);

  useEffect(() => {
    setAddNew(false);
  }, [accessGroups, accessGroupPage]);

  const handleChangeIndustry = (selectedNodes: any[], id: number) => {
    let ids: number[] = [];
    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node) => {
        ids.push(node?.value);
      });
    }

    setGroupList((prevState) =>
      prevState.map((item) =>
        item.id === id
          ? {
              ...item,
              taxonomyMapping: {
                ...item.taxonomyMapping,
                linkedTaxonomyNodes: {
                  ...item.taxonomyMapping.linkedTaxonomyNodes,
                  Industry: ids,
                },
              },
            }
          : item
      )
    );
  };

  const handleChangeFunction = (selectedNodes: any[], id: number) => {
    let ids: number[] = [];
    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node) => {
        ids.push(node?.value);
      });
    }

    setGroupList((prevState) =>
      prevState.map((item) =>
        item.id === id
          ? {
              ...item,
              taxonomyMapping: {
                ...item.taxonomyMapping,
                linkedTaxonomyNodes: {
                  ...item.taxonomyMapping.linkedTaxonomyNodes,
                  Function: ids,
                },
              },
            }
          : item
      )
    );
  };

  const handleChange = ({
    target,
  }: {
    target: { checked?: boolean; name: string; value: string | string[] };
  }) => {
    let tempArray: IaccessGroups[] = [...groupList];
    let currentItemIndex: number = tempArray.findIndex((e) => e?.isNew || e?.isEdit);
    let currentItem = tempArray[currentItemIndex];
    const objKey = target?.name;

    if (!isEmpty(currentItem) && objKey in currentItem) {
      tempArray[currentItemIndex] = {
        ...tempArray[currentItemIndex],
        [objKey]: target?.value || target?.checked,
      };
    }
    setGroupList(tempArray);
  };

  const resetChanges = () => {
    setAddNew(false);
    let tempArray = [...accessGroups];
    tempArray = tempArray.map((item) => ({
      ...item,
      isEdit: false,
      isNew: false,
    }));
    dispatch(setAccessGroups(tempArray));
  };

  const resetChangeWithModalClose = () => {
    setIsShowDiscardChanges(false);
    resetChanges();
  };

  const handleSave = async (record: IaccessGroups, index: number, confirm: boolean) => {
    const { role, title, download, edit, status, upload, id, isEdit } = record;

    if (isEmpty(groupList)) return;
    let tempArray = [...groupList];
    const currentItem = tempArray[index];
    const currentItemPrev = accessGroups[index];

    if (confirm) {
      if (!isEmpty(tempArray) && !currentItem?.title && !currentItem?.title?.trim()) {
        toast.error("Please enter name");
        return;
      }

      const isTitleExists = accessGroups
        .filter((item) => item?.id !== currentItem?.id)
        .find((item) => item?.title === currentItem?.title);

      if (isEmpty(isTitleExists)) {
        tempArray = tempArray.map((item) => ({
          ...item,
          isEdit: false,
          isNew: false,
        }));

        dispatch(setAccessGroups(tempArray));

        if (isEdit) {
          await UpdateAccessGroup(
            {
              access_group_id: id,
              access_group_name: title,
              role: role,
              access_group_status: status.toString().toUpperCase(),
              download: download.toString().toUpperCase(),
              edit: edit.toString().toUpperCase(),
              upload: upload.toString().toUpperCase(),
              search_and_view: "TRUE",
            },
            {
              taxonomyMapping: [
                {
                  taxonomyId: 27,
                  taxonomyNodeIds: record?.taxonomyMapping?.linkedTaxonomyNodes?.Industry?.length
                    ? record?.taxonomyMapping?.linkedTaxonomyNodes?.Industry
                    : [],
                },
                {
                  taxonomyId: 30,
                  taxonomyNodeIds: record?.taxonomyMapping?.linkedTaxonomyNodes?.Function?.length
                    ? record?.taxonomyMapping?.linkedTaxonomyNodes?.Function
                    : [],
                },
              ],
            },
            accessGroupSearch,
            accessGroupPage
          );
        } else {
          await CreateAccessGroup(
            {
              access_group_name: title,
              role: role,
              access_group_status: status.toString().toUpperCase(),
              download: download.toString().toUpperCase(),
              edit: edit.toString().toUpperCase(),
              upload: upload.toString().toUpperCase(),
              search_and_view: "TRUE",
            },
            {
              taxonomyMapping: [
                {
                  taxonomyId: 27,
                  taxonomyNodeIds: record?.taxonomyMapping?.linkedTaxonomyNodes?.Industry?.length
                    ? record?.taxonomyMapping?.linkedTaxonomyNodes?.Industry
                    : [],
                },
                {
                  taxonomyId: 30,
                  taxonomyNodeIds: record?.taxonomyMapping?.linkedTaxonomyNodes?.Function?.length
                    ? record?.taxonomyMapping?.linkedTaxonomyNodes?.Function
                    : [],
                },
              ],
            },
            accessGroupSearch,
            accessGroupPage
          );
        }
      } else {
        toast.error("The access group already exists. Please try a different name.");
      }
    } else {
      if (record?.isNew) {
        tempArray.pop(); // use this only in case new Group
      } else if (JSON.stringify(currentItem) !== JSON.stringify(currentItemPrev)) {
        setIsShowDiscardChanges(true);
        return;
      }

      resetChanges();
      getAccessGroups(false, accessGroupSearch, accessGroupPage);
    }
    setAddNew(false);
  };

  const handleAddNewGroup = () => {
    setAddNew(true),
      setGroupList([
        ...groupList,
        { ...accessGroupsInitialState, id: groupList.length, isEdit: false },
      ]);
  };

  const handleEdit = (record: IaccessGroups) => {
    const destructureList = [...groupList].map((item) => ({
      ...item,
      isEdit: item?.id === record?.id ? true : false,
    }));
    setGroupList(destructureList);
    dispatch(setAccessGroups(destructureList));
  };

  const handleDeleteBtn = (record: IaccessGroups) => {
    setIsShowDeleteModal(true);
    setSelectedAccessId(record?.id);
  };

  const handleStatusChange = (record: IaccessGroups) => {
    const updatedStatus = groupList.map((v: IaccessGroups) =>
      v?.id === record?.id ? { ...v, status: !record?.status } : v
    );

    dispatch(setAccessGroups(updatedStatus));
  };

  const columns: TableProps["columns"] = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      width: "200px",
      render: (text, record) => (
        <>
          <span className="td-label">Title : </span>
          {record?.isNew || record?.isEdit ? (
            <Input
              onChange={handleChange}
              name="title"
              value={record?.title ? record?.title : ""}
            />
          ) : (
            <strong>{text}</strong>
          )}
        </>
      ),
    },
    // {
    //   title: "Role",
    //   dataIndex: "role",
    //   key: "role",
    //   width: "120px",
    //   render: (role: string) => (
    //     <>
    //       <span className="td-label">Role : </span>
    //       <div className="selectRole">
    //         <Select
    //           popupClassName="ui-dropdown-default ui-dropdown-select-role"
    //           value={role}
    //           style={{ width: 80 }}
    //           // disabled={!record?.isEdit}
    //           onChange={(e) => handleChange({ target: { name: "role", value: e } })}
    //           options={[
    //             { value: "USER", label: "User" },
    //             { value: "CONTENT_EDITOR", label: "Editor" },
    //             { value: "ADMIN", label: "Admin" },
    //           ]}
    //         />
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "Industry",
      dataIndex: "industry",
      width: "230px",
      key: "industry",
      render: (_, record: IaccessGroups) => {
        return (
          <>
            <span className="td-label">Industry : </span>
            <div className="industry-column-group">
              {record.isEdit || record.isNew ? (
                <>
                  <label
                    className="count-label"
                    aria-disabled={
                      record?.taxonomyMapping?.linkedTaxonomyNodes?.Industry?.length ? false : true
                    }>
                    {record?.taxonomyMapping?.linkedTaxonomyNodes?.Industry?.length ? (
                      <>
                        Industry
                        <span className="count">
                          ({record?.taxonomyMapping?.linkedTaxonomyNodes?.Industry?.length})
                        </span>
                      </>
                    ) : (
                      "Choose"
                    )}
                  </label>
                  <EditContentTags
                    selectedTags={convertNumberArrayToStringArray(
                      record?.taxonomyMapping?.linkedTaxonomyNodes?.Industry
                    )}
                    mappedContentTags={mappedContentIndustry}
                    popupClassName="ui-dropdown-default ui-dropdown-select-role ui-dropdown-checkbox-default"
                    placeholder=""
                    handleChange={(e) => handleChangeIndustry(e, record?.id)}
                    isNonLeafNodeDisable={false}
                    treeCheckStrictly={true}
                    allowClear={true}
                    popupMatchSelectWidth={false}
                  />
                </>
              ) : (
                <>
                  <AssetTagsGroup
                    linkedTags={record?.taxonomyMapping}
                    id={record?.id}
                    selectedIds={record?.taxonomyMapping?.linkedTaxonomyNodes?.Industry ?? []}
                    title="Industry"
                  />
                </>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Function",
      dataIndex: "function",
      width: "230px",
      key: "function",
      render: (_, record: IaccessGroups) => (
        <>
          <span className="td-label">Function : </span>
          <div className="industry-column-group">
            {record.isEdit || record.isNew ? (
              <>
                <label
                  className="count-label"
                  aria-disabled={
                    record?.taxonomyMapping?.linkedTaxonomyNodes?.Function?.length ? false : true
                  }>
                  {record?.taxonomyMapping?.linkedTaxonomyNodes?.Function?.length ? (
                    <>
                      Function
                      <span className="count">
                        ({record?.taxonomyMapping?.linkedTaxonomyNodes?.Function?.length})
                      </span>
                    </>
                  ) : (
                    "Choose"
                  )}
                </label>
                <EditContentTags
                  selectedTags={convertNumberArrayToStringArray(
                    record?.taxonomyMapping?.linkedTaxonomyNodes?.Function
                  )}
                  mappedContentTags={mappedContentFunction}
                  popupClassName="ui-dropdown-default ui-dropdown-select-role ui-dropdown-checkbox-default"
                  placeholder=""
                  handleChange={(e) => handleChangeFunction(e, record?.id)}
                  isNonLeafNodeDisable={false}
                  treeCheckStrictly={true}
                  allowClear={true}
                  popupMatchSelectWidth={false}
                />
              </>
            ) : (
              <AssetTagsGroup
                linkedTags={record?.taxonomyMapping}
                id={record?.id}
                selectedIds={record?.taxonomyMapping?.linkedTaxonomyNodes?.Function ?? []}
                title="Function"
              />
            )}
          </div>
        </>
      ),
    },
    {
      title: "View",
      dataIndex: "searchView",
      key: "searchView",
      width: "100px",
      align: "center",
      render: () => (
        <>
          <span className="td-label">Search & View : </span>
          <Checkbox
            name="searchView"
            disabled
            onChange={() =>
              handleChange({ target: { name: "searchView", checked: true, value: "" } })
            }
            checked={true}
          />
        </>
      ),
    },
    {
      title: "Download",
      dataIndex: "download",
      key: "download",
      width: "100px",
      align: "center",
      render: (download, record: IaccessGroups) => (
        <>
          <span className="td-label">Download : </span>
          <Checkbox
            name="download"
            disabled={!(record?.isNew || record?.isEdit)}
            onChange={(e) =>
              handleChange({ target: { name: "download", checked: e.target.checked, value: "" } })
            }
            checked={download}
          />
        </>
      ),
    },
    {
      title: "Upload",
      key: "upload",
      dataIndex: "upload",
      width: "100px",
      align: "center",
      render: (upload, record: IaccessGroups) => (
        <>
          <span className="td-label">Upload : </span>
          <Checkbox
            name="upload"
            disabled={!(record?.isNew || record?.isEdit)}
            onChange={(e) =>
              handleChange({ target: { name: "upload", checked: e.target.checked, value: "" } })
            }
            checked={upload}
          />
        </>
      ),
    },
    {
      title: "Edit",
      key: "edit",
      width: "100px",
      align: "center",
      dataIndex: "edit",
      render: (edit, record: IaccessGroups) => (
        <>
          <span className="td-label">Edit : </span>
          <Checkbox
            name="edit"
            disabled={!(record?.isNew || record?.isEdit)}
            onChange={(e) =>
              handleChange({ target: { name: "edit", checked: e.target.checked, value: "" } })
            }
            checked={edit}
          />
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      width: "120px",
      dataIndex: "status",
      render: (_, record: IaccessGroups, index: number) => (
        <>
          <span className="td-label">Status : </span>
          <div className="switch-edit-action">
            <Switch
              checked={record?.status}
              disabled={!(record?.isNew || record?.isEdit)}
              onChange={() => handleStatusChange(record)}
            />
            {record.isNew || record.isEdit ? (
              <>
                <CheckOutlined
                  className="saveIcon"
                  onClick={() => handleSave(record, index, true)}
                />
                <CloseOutlined
                  onClick={() => handleSave(record, index, false)}
                  className="closeIcon"
                />
              </>
            ) : (
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: "1",
                      label: "Edit",
                      onClick: () => handleEdit(record),
                    },
                    {
                      key: "2",
                      label: "Delete",
                      onClick: () => handleDeleteBtn(record),
                    },
                  ],
                }}
                overlayStyle={{ width: 80 }}
                overlayClassName="table-action-dropdown table-action-dropdown-user"
                placement="bottomRight">
                <Button className="more-action">
                  <i className="ri-more-2-fill"></i>
                </Button>
              </Dropdown>
            )}
          </div>
        </>
      ),
    },
  ];

  const handlePagination = (page: number) => {
    dispatch(setAccessGroupPage(page));
  };

  const handleDeleteAccessGroup = async () => {
    if (selectedAccessId !== -1) {
      await deleteAccessGroups(
        selectedAccessId,
        accessGroupSearch,
        accessGroupPage,
        totalAccessGroupsCount
      );
      // dispatch(deleteAccessGroupById(selectedAccessId));
    }
    handleCloseDeleteModal();
  };

  const handleCloseDeleteModal = () => {
    setIsShowDeleteModal(false);
    setSelectedAccessId(-1);
  };

  const getAccessGroupsBySearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedSearchText = e.target.value.trim();

    if (trimmedSearchText.length >= 3)
      await getAccessGroups(true, trimmedSearchText, accessGroupPage);
    if (e.target.value.length === 0) {
      await getAccessGroups(true, "", 1);
    }
    dispatch(setAccessGroupPage(1));
  };

  const handleSearchDebounce = useDebounce(getAccessGroupsBySearch, 500);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchDebounce(e);
    dispatch(setAccessGroupSearch(e.target.value));
  };

  return (
    <>
      <Flex className="admin-inner-wrap access-group-page" vertical>
        <PageHeader
          title="Access Group"
          isRelevantAsset={false}
          content={
            <>
              <Input
                type="search"
                className="header-search"
                placeholder="Search by name..."
                prefix={<i className="ri-search-line" />}
                allowClear
                onChange={(e) => {
                  handleChangeSearch(e);
                }}
                defaultValue={accessGroupSearch}
                value={accessGroupSearch}
              />
              <Button
                className="btn"
                type="default"
                disabled={addNew}
                icon={<i className="ri-add-circle-fill" />}
                onClick={handleAddNewGroup}>
                Add new group
              </Button>
            </>
          }
        />

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}>
          <Row>
            <Col span={24}>
              <Card
                className={`global-table-card ${isEmpty(groupList) ? "global-table-empty" : ""}`}>
                {isAccessGroupsLoading ? (
                  <TableSkeleton />
                ) : (
                  <Table
                    className="global-table responsive-table access-group-table"
                    columns={columns}
                    dataSource={groupList}
                    tableLayout="fixed"
                    scroll={{ y: "calc(100vh - 188px)" }}
                    pagination={false}
                  />
                )}
                {!isEmpty(groupList) ? (
                  <CustomPagination
                    currentPage={accessGroupPage}
                    total={totalAccessGroupsCount}
                    pageSize={PageLimit.UserManagement}
                    handlePagination={handlePagination}
                  />
                ) : (
                  <></>
                )}
              </Card>
            </Col>
          </Row>
        </motion.div>
      </Flex>

      <DeleteModal
        isLoading={isAccessGroupDeleting}
        isModalOpen={isShowDeleteModal}
        handleCancel={handleCloseDeleteModal}
        handleSubmit={handleDeleteAccessGroup}
        message="This access group may be assigned to a user. Are you sure you want to delete this access group?"
        title="Delete Access Group"
      />

      {/* discard changes */}
      <DeleteModal
        isLoading={false}
        isModalOpen={isShowDiscardChanges}
        handleCancel={() => setIsShowDiscardChanges(false)}
        handleSubmit={resetChangeWithModalClose}
        message="This will remove your unsaved changes."
        title="Are you sure you want to leave?"
        submitBtnName="Yes"
        cancelBtnName="No"
      />
    </>
  );
};

export default AccessGroup;
