import {
  Button,
  Card,
  Col,
  Empty,
  Flex,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import { ContentChatButton, DeleteModal, PageHeader } from "../../components";
import { LoadingOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useState, useEffect, ChangeEvent, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams, useBlocker } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { persistStore } from "redux-persist";
import EditContentTags from "../../components/viewEditContentManagement/EditContentTags/EditContentTags";
import {
  getAssetById,
  getAllTagsByAssestId,
  updateTagsByAssestId,
  saveTagsByAssestId,
  editAssetById,
  enablePublish,
  disablePublish,
  deleteAssetById,
  generateSummary,
  getAssetDetailsByAssetId,
  updateDownloadLogs,
  updateDownloadCount,
  autoRefreshedAssetById,
} from "../../services/contentManagement";
import { fetchAllUsers } from "../../services/userManagement";
import {
  ShowTaxonomyLimit,
  PATHS,
  SummaryStaus,
  uniqueId,
  AssetUploadStatus,
  getUserRole,
  UserRoles,
  removeLocalstorage,
  Images,
  getFormattedDate,
  handleOpenPdfDoc,
  IMAGES,
  PDFViewer,
  onDownloadPdf,
  isEmpty,
  getIsAssetProcessing,
  getUserId,
  FromWhere,
  getIsSummaryProcessing,
  AssetStatus,
  TaxonomyTitle,
  AppLayout,
  ImageViewer,
  isFileTYpeVideo,
  isFileTypeAudio,
  AudioPlayer,
  renderTimeStamp,
  ToggleAudio,
} from "../../shared";
import { RootState, store } from "../../store";
import {
  IContent,
  IMappedIndustryChildren,
  IMappedIndustry,
  IFormattedIndustry,
  IContributor,
  IContentUser,
  ITagsByAssestId,
} from "../../store/contentManagement/content.interface";
import {
  contentByIdInitialValue,
  setUserListPageCount,
  setContentById,
  setIsUserListLoading,
  setIsSummaryGenerationLoading,
  updateAssetByAssetId,
  setAllContentDetails,
  tagsByAssestIdInitValue,
} from "../../store/contentManagement/contentSlice";
import { setUpdateUploadFileList } from "../../store/conversationalSearch/conversationalSearchSlice";
import { IUser } from "../../store/userManagement/user.interface";
import "./ViewEditContentManagement.scss";
import { motion } from "framer-motion";
import { getPDFPreSignedUrl } from "../../services/conversationalSearch";
import {
  findNodeNameByValue,
  findParentNodesByValue,
  mapDataToTreeSelect,
} from "../../shared/taxonomyHelper";
import { setUpdateContributedCount } from "../../store/appState/appSlice";
import Markdown from "react-markdown";
import { getTaxonomyByTitle } from "../../services/taxonomy";
import {
  setImageURL,
  setIsImageVisible,
  setIsPDFModalOpen,
  setPDFContent,
} from "../../store/pdfViewer/pdfViewer.slice";
import MediaPlayer from "../../shared/VideoPLayer";
import {
  isShowVideoPLayerModal,
  resetMediaUploadState,
  setToggleAudio,
  setVideoTime,
} from "../../store/mediaUpload/mediaUploadSlice";

interface IViewEditContentManagement {
  isRelevantAsset: boolean;
}

const viewEditContentManagement = (props: IViewEditContentManagement) => {
  const { isRelevantAsset } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const isNewAssetUpload = location?.state?.isNewAssetUpload ?? false;
  const isReadOnly = location?.state?.isReadOnly ?? false;
  const fromWhere = location?.state?.fromWhere || "";
  const isTagsExpanded = location?.state?.isTagsExpanded || false;
  const profileUserId = location?.state?.profileUserId || "";

  const { assetId } = useParams();

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const { uploadFileList } = useSelector((state: RootState) => state.conversationalSearch);
  const {
    isEditContentLoading,
    contentById,
    selectedAllTagByAssestId,
    isContentByIdLoading,
    isTagsByAssestIdLoading,
    contentDeleteLoading,
    downloadLogError,
    userPageCount,
    isUserListLoading,
    isSummaryGenerationLoading,
    totalContentsCount,
    contents,
    contentFilter,
    sourceFilter,
    adminContentFilter,
  } = useSelector((state: RootState) => state.content);
  const { industryTaxonomy, functionTaxonomy, assetTypeTaxonomy } = useSelector(
    (state: RootState) => state.taxonomy
  );
  const { isPDFModalOpen, pdfDetails } = useSelector((state: RootState) => state.pdfViewer);
  const { allUsers, totalUsersCount } = useSelector((state: RootState) => state.user);
  const { assetCount, appLayoutView } = useSelector((state: RootState) => state.app);
  const { loadingAudio, isAudioPlaying } = useSelector(
    (state: RootState) => state.mediaUploadSlice
  );

  const [userList, setUserList] = useState<IUser[]>(allUsers);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [adminsList, setAdminsList] = useState<IUser[]>(allUsers);
  const [isTagsEdited, setIsTagsEdited] = useState<boolean>(false);
  const [selectedFunction, setSelectedFunction] = useState<string[]>([]);
  const [isPdfDownloading, setIsPdfDownloading] = useState<boolean>(false);
  const [content, setContent] = useState<IContent>(contentByIdInitialValue);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [selectedOwner, setSelectedOwner] = useState({ value: "", key: "-1" });
  const [isUpdateTagsLoading, setIsUpdateTagsLoading] = useState<boolean>(false);
  const [selectedAssetType, setSelectedAssetType] = useState<null | string>(null);
  const [selectedContributorId, setSelectedContributorId] = useState<number>(-1);
  const [addContributors, setAddContributors] = useState({ value: "", key: "-1" });
  const [filteredContributors, setfilteredContributors] = useState<IUser[] | []>([]);
  const [isShowUpdateTagsModal, setIsShowUpdateTagsModal] = useState<boolean>(false);
  const [isShowCancelWarnModal, setIsShowCancelWarnModal] = useState<boolean>(false);
  const [isShowAssetDeleteModal, setIsShowAssetDeleteModal] = useState<boolean>(false);
  const [isOpenOwnerRemoveModal, setIsOpenOwnerRemoveModal] = useState<boolean>(false);
  const [isShowContributorSearch, setIsShowContributorSearch] = useState<boolean>(false);
  const [isOpenPeopleRemoveModal, setIsOpenPeopleRemoveModal] = useState<boolean>(false);
  const [isAddContributorVisible, setIsAddContributorVisible] = useState<boolean>(false);
  const [isShowUnsavedChangesModal, setIsShowUnsavedChangesModal] = useState<boolean>(false);
  const [showIndustryLimit, setShowIndustryLimit] = useState<number>(ShowTaxonomyLimit.Industry);
  const [showFunctionLimit, setShowFunctionLimit] = useState<number>(ShowTaxonomyLimit.Function);

  useEffect(() => {
    if (isTagsExpanded) {
      setShowIndustryLimit(selectedIndustries?.length);
      setShowFunctionLimit(selectedFunction?.length);
    }
  }, [selectedIndustries, selectedFunction]);

  const loading = isContentByIdLoading || isTagsByAssestIdLoading;
  let contentByIdInterval: any;
  let summaryInterval: any;

  const currentAssetId = (): number => {
    if (isRelevantAsset) {
      return pdfDetails?.assetId ?? -1;
    } else {
      return !!assetId ? parseInt(assetId) : -1;
    }
  };
  console.log(audioRef, videoRef, "videoTime");

  useEffect(() => {
    getTaxonomyByTitle(TaxonomyTitle.Industry);
    getTaxonomyByTitle(TaxonomyTitle.Function);
    getTaxonomyByTitle(TaxonomyTitle.AssetType);
  }, []);

  const mappedContentIndustry = mapDataToTreeSelect(industryTaxonomy.taxonomyTree ?? []);
  const mappedContentFunction = mapDataToTreeSelect(functionTaxonomy.taxonomyTree ?? []);
  const mappedAssetType =
    assetTypeTaxonomy.taxonomyTree
      .filter((item: any) => item.taxonomyNodeName)
      .map((item: any) => ({
        label: item.taxonomyNodeName,
        value: String(item.taxonomyNodeId),
      })) || [];

  const isContainAssetType = mappedAssetType.filter(
    (asset) => Number(asset.value) === Number(selectedAssetType)
  ).length
    ? true
    : false;

  const handleContentByIdPulling = () => {
    if (currentAssetId() && getIsAssetProcessing(contentById?.assetStatus)) {
      contentByIdInterval = setInterval(async () => {
        let res = await autoRefreshedAssetById(currentAssetId());

        if (
          (res?.asset_id && res?.assetStatus === AssetUploadStatus.COMPLETED) ||
          getIsAssetProcessing(res?.assetStatus) ||
          getIsSummaryProcessing(res?.summaryStatus)
        ) {
          dispatch(updateAssetByAssetId({ data: res, id: res.asset_id }));
        }
        if (!res) {
          clearInterval(contentByIdInterval);
        }
      }, 5000);
    } else {
      clearInterval(contentByIdInterval);
    }
  };

  useEffect(() => {
    handleContentByIdPulling();
    return () => {
      clearInterval(contentByIdInterval);
    };
  }, [contentById]);

  useEffect(() => {
    dispatch(setUserListPageCount(2));
    const data = getFilteredContributors(allUsers);
    setfilteredContributors(data);
  }, [allUsers]);

  const getFilteredContributors = (list: IUser[]) => {
    const filteredContributors = list.filter(
      (user) => !content.contributors.map((contributor) => contributor.id).includes(user.id)
    );
    return filteredContributors;
  };

  const getFormattedSelectedTags = (selectedTags: string[], mappedTags: IMappedIndustry[]) => {
    const formattedTags: IFormattedIndustry[] = [];

    if (selectedTags && selectedTags.length) {
      selectedTags.forEach((value: string) => {
        formattedTags.push({
          parents: findParentNodesByValue(value, mappedTags),
          value,
          id: value,
          title: findNodeNameByValue(mappedTags, value),
        });
      });
    }
    return formattedTags;
  };

  const formattedSelectedIndustryTag = getFormattedSelectedTags(
    selectedIndustries,
    mappedContentIndustry
  );
  const formattedSelectedFunctionTag = getFormattedSelectedTags(
    selectedFunction,
    mappedContentFunction
  );

  useEffect(() => {
    if (currentAssetId) getAssetById(currentAssetId(), setContent, navigate);
    location?.state?.isEditMode ? setIsEditMode(true) : null;
  }, [assetId]);

  useEffect(() => {
    if (currentAssetId) getAllTagsByAssestId(currentAssetId());
  }, []);

  const handleSetTagsInDropdown = () => {
    const selectedIndustry = selectedAllTagByAssestId?.linkedTaxonomyNodes?.Industry || [];
    const selectedFunction = selectedAllTagByAssestId?.linkedTaxonomyNodes?.Function || [];

    if (selectedIndustry && selectedIndustry.length) {
      const values = selectedIndustry.map((id: number) => id.toString());
      setSelectedIndustries(values);
    }
    if (selectedFunction && selectedFunction.length) {
      const values = selectedFunction.map((id: number) => id.toString());
      setSelectedFunction(values);
    }

    const assetTypeSelected = selectedAllTagByAssestId?.linkedTaxonomyNodes?.["Asset Type"] ?? [];
    if (assetTypeSelected && assetTypeSelected.length) {
      setSelectedAssetType(String(assetTypeSelected[0]));
    }
  };

  useEffect(() => {
    handleSetTagsInDropdown();
  }, [selectedAllTagByAssestId]);

  const checkSaveChangesInputs = () => {
    if (!content?.title || content?.title.trim() === "") {
      toast.error("Title input cannot be empty");
      return true;
    }
    if (content?.user?.id === -1) {
      toast.error("Owner input cannot be empty");
      return true;
    }
  };

  const handleBack = () => {
    const fromWhere = location?.state?.fromWhere || "";

    if (fromWhere === FromWhere.MyContent) {
      navigate(PATHS.myContent, { state: { isDataPersist: true } });
    } else if (fromWhere === FromWhere.Source) {
      navigate(PATHS.sources, { state: { isDataPersist: true } });
    } else if (fromWhere === FromWhere.Home) {
      navigate(PATHS.home);
    } else if (fromWhere === FromWhere.AdminContentManagement) {
      navigate(PATHS.adminContentManagement, { state: { isDataPersist: true } });
    } else if (fromWhere === FromWhere.UserProfile) {
      navigate(PATHS.userProfile, { state: { isDataPersist: true, profileUserId } });
    } else {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    if (getIsAnyAssestEdited()) {
      setIsShowCancelWarnModal(true);
    } else {
      handleResetChanges();
    }
  };

  const handleResetChanges = () => {
    if (content?.summaryStatus === SummaryStaus.PROCESSING) {
      handleUpdateSummaryStatus();
    }

    setIsEditMode(false);
    setIsAddContributorVisible(false);
    setContent(contentById);

    const selectedIndustry = (selectedAllTagByAssestId?.linkedTaxonomyNodes?.Industry || []).map(
      (id: number) => id.toString()
    );
    const selectedFunction = (selectedAllTagByAssestId?.linkedTaxonomyNodes?.Function || []).map(
      (id: number) => id.toString()
    );
    setSelectedIndustries(selectedIndustry);
    setSelectedFunction(selectedFunction);
    setIsShowContributorSearch(false);
    setIsShowCancelWarnModal(false);
    setSelectedOwner({ value: "", key: "-1" });
    setIsUpdateTagsLoading(false);
    setIsShowUpdateTagsModal(false);
  };

  const getFormmatedAssetIds = () => {
    const prevSelectedIndustry = selectedAllTagByAssestId?.linkedTaxonomyNodes?.Industry || [];
    const prevSelectedFunction = selectedAllTagByAssestId?.linkedTaxonomyNodes?.Function || [];
    const prevSelectedAssetType =
      selectedAllTagByAssestId?.linkedTaxonomyNodes?.["Asset Type"] || [];
    const prevTaxonomyNodeIds = [
      ...prevSelectedIndustry,
      ...prevSelectedFunction,
      ...prevSelectedAssetType,
    ];

    const currSelectedIndustriesIds =
      selectedIndustries.map((industry: string) => Number(industry)) || [];
    const currSelectedFunctionIds =
      selectedFunction.map((industry: string) => Number(industry)) || [];
    const currAssetTypeId = selectedAssetType ? [Number(selectedAssetType)] : [];
    const currTaxonomyNodeIds = [
      ...currSelectedIndustriesIds,
      ...currSelectedFunctionIds,
      ...currAssetTypeId,
    ];

    return { prevTaxonomyNodeIds, currTaxonomyNodeIds };
  };

  const handleUpdateContentTags = async (currentAssetId: number) => {
    const { prevTaxonomyNodeIds, currTaxonomyNodeIds } = getFormmatedAssetIds();
    let linkedTags: ITagsByAssestId = tagsByAssestIdInitValue;

    if (isTagsEdited) {
      if (prevTaxonomyNodeIds && prevTaxonomyNodeIds.length) {
        const res: any = await updateTagsByAssestId(currentAssetId, currTaxonomyNodeIds);
        if (res?.data) {
          const linkedTagsRes = await getAllTagsByAssestId(currentAssetId);
          if (!isEmpty(linkedTagsRes)) linkedTags = linkedTagsRes;
        } else {
          handleResetChanges();
        }
      } else {
        const res: any = await saveTagsByAssestId(currentAssetId, currTaxonomyNodeIds);
        if (res?.data) {
          const linkedTagsRes = await getAllTagsByAssestId(currentAssetId);
          if (!isEmpty(linkedTagsRes)) linkedTags = linkedTagsRes;
        } else {
          handleResetChanges();
        }
      }
    }

    setShowIndustryLimit(ShowTaxonomyLimit.Industry);
    setShowFunctionLimit(ShowTaxonomyLimit.Function);

    return linkedTags;
  };

  const handleResetRouteLocation = (currentAssetId: number) => {
    navigate(`${PATHS.viewEditContentManagement}/${currentAssetId}`, {
      state: { ...location?.state, isEditMode: false, isNewAssetUpload: false },
    });
  };

  const handleUpdateSummaryStatus = async () => {
    const contributors = contentById?.contributors.map((v) => ({ id: v?.id }));

    await editAssetById(
      currentAssetId(),
      contentById?.title,
      contentById?.summary,
      contentById?.assetType,
      contentById?.user?.id,
      contributors,
      SummaryStaus.CANCELLED
    );
  };

  const handleUpdateContributerCount = () => {
    const prevOwnerId = contentById?.user?.id;
    const prevContributors = !isEmpty(contentById?.contributors)
      ? contentById?.contributors.filter((item) => item.id === getUserId())
      : [];

    const currOwnerId = content?.user?.id;
    const currContributors = !isEmpty(content?.contributors)
      ? content?.contributors.filter((item) => item.id === getUserId())
      : [];

    if (
      (prevOwnerId === getUserId() && currOwnerId !== getUserId()) ||
      (prevContributors.length && !currContributors.length)
    ) {
      dispatch(setUpdateContributedCount(assetCount?.assets_contributed - 1));
    } else if (
      (prevOwnerId !== getUserId() && currOwnerId === getUserId()) ||
      (!prevContributors.length && currContributors.length)
    ) {
      dispatch(setUpdateContributedCount(assetCount?.assets_contributed + 1));
    }
  };

  const handleContentsDataPersist = (currentAssetId: number, linkedTagsRes: ITagsByAssestId) => {
    if (content?.user?.id !== getUserId() && fromWhere === FromWhere.MyContent) {
      // Case: When we Remove Owner
      let contentsValues = [...contents];
      contentsValues = !isEmpty(contentsValues)
        ? contentsValues?.filter((item) => item.asset_id !== currentAssetId)
        : [];
      dispatch(setAllContentDetails(contentsValues));
    } else {
      if (isEmpty(linkedTagsRes?.assetId)) {
        // Case: If we didn't update the Tags
        dispatch(
          updateAssetByAssetId({
            data: {
              ...content,
              linkedTags: selectedAllTagByAssestId,
            },
            id: currentAssetId,
          })
        );
      } else {
        // Case: If we updated the Tags
        const contentData = {
          ...content,
          linkedTags: linkedTagsRes,
          assetStatus: AssetUploadStatus.SENT_TO_LLM,
        };
        dispatch(
          updateAssetByAssetId({
            data: contentData,
            id: currentAssetId,
          })
        );
        dispatch(setContentById({ ...contentData }));
      }
    }

    handleUpdateContributerCount();
  };

  const handleSaveAssetFieldValue = async (currentAssetId: number) => {
    setIsEditMode(false);
    setIsUpdateTagsLoading(true);
    const contributors = content?.contributors.map((v) => ({ id: v?.id }));
    const prevTitle = contentById?.title ?? "";
    const prevSummary = contentById?.summary ?? "";
    const prevContentUserId = contentById?.user?.id ?? -1;

    const currTitle = content?.title ?? "";
    const currSummary = content?.summary ?? "";
    const currContentUserId = content?.user?.id ?? -1;

    if (
      prevTitle !== currTitle ||
      prevSummary !== currSummary ||
      getIsContributorsEdited() ||
      prevContentUserId !== currContentUserId
    ) {
      const res = await editAssetById(
        currentAssetId,
        content?.title,
        content?.summary,
        content?.assetType,
        content?.user?.id,
        contributors,
        content?.summaryStatus || null
      );
      if (res?.data) {
        setContent(content);
        dispatch(setContentById(content));
      } else {
        handleResetChanges();
      }
    }

    const linkedTags = await handleUpdateContentTags(currentAssetId);
    handleContentsDataPersist(currentAssetId, linkedTags);

    setIsEditMode(false);
    setIsAddContributorVisible(false);
    setIsTagsEdited(false);
    setIsShowContributorSearch(false);
    handleResetRouteLocation(currentAssetId);
    setIsUpdateTagsLoading(false);
    setIsShowUpdateTagsModal(false);
  };

  const updateConversationUploadList = () => {
    const remainingFiles: IContent[] = uploadFileList.filter(
      (file) => file.asset_id !== currentAssetId()
    );
    dispatch(setUpdateUploadFileList(remainingFiles));
    if (!isEmpty(remainingFiles)) {
      navigate(`${PATHS.viewEditContentManagement}/${remainingFiles[0]?.asset_id}`, {
        state: {
          isEditMode: true,
          isNewAssetUpload: true,
          releventAssetId: remainingFiles[0]?.asset_id,
        },
      });
    }
  };

  const handleSaveChanges = async () => {
    if (checkSaveChangesInputs()) return;
    if (getIsAssetTagsEdited()) {
      setIsShowUpdateTagsModal(true);
    } else {
      await handleSaveAssetFieldValue(currentAssetId());
    }
    if (location?.state?.releventAssetId) updateConversationUploadList();
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setContent((prev) => ({ ...prev, title: e.target.value }));
  };

  const handleChangeSummary = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContent((prev) => ({ ...prev, summary: e.target.value }));
  };

  const onContributorsChange = (value: { key: string; value: string }) => {
    setAddContributors({ key: value?.key, value: value?.value });
  };

  const handleAddContributors = () => {
    const addedContributor: IContributor = {
      status: "",
      id: parseInt(addContributors?.key),
      username: addContributors?.value,
      name: addContributors?.value,
      email: "string,",
      userPermissions: [],
    };

    setContent((v) => ({
      ...v,
      contributors: [...v.contributors, addedContributor],
    }));
    setAddContributors({ key: "-1", value: "" });
  };

  const onSearch = (val: any) => {
    console.log("search:", val);
  };

  const handleRemoveContributor = () => {
    setIsOpenPeopleRemoveModal(false);

    const contributors = content?.contributors.filter((v) => v.id !== selectedContributorId);
    setContent({
      ...content,
      contributors,
    });
  };

  const handleRemoveContributorModal = (id: number) => {
    setSelectedContributorId(id);
    setIsOpenPeopleRemoveModal(true);
  };

  const handleOwnerRemove = () => {
    setIsOpenOwnerRemoveModal(false);

    const removeUser: IContentUser = {
      email: "",
      id: -1,
      name: "",
      status: "",
      username: "",
      userPermissions: [],
    };
    setContent({
      ...content,
      user: removeUser,
    });
  };

  const handleSelectAssestType = (value: string) => {
    setSelectedAssetType(value);
    setIsTagsEdited(true);
  };

  const handleSelectOwner = (v: { value: string; key: string }) => {
    setSelectedOwner({ value: v.value, key: v.key });
  };

  const handleChangeOwnerAction = () => {
    const newOwner = adminsList.filter((item) => item.id === Number(selectedOwner.key));

    const removeUser: IContentUser = {
      email: newOwner[0].email,
      id: newOwner[0].id ?? -1,
      name: newOwner[0].name,
      status: newOwner[0].status,
      username: newOwner[0].username,
      userPermissions: newOwner[0].userPermissions,
    };
    setContent({
      ...content,
      user: removeUser,
    });
    setSelectedOwner({ key: "-1", value: "" });
  };

  const AvatarAndTitleSkeleton = () => {
    return (
      <li className="ant-list-item">
        <div className="ant-list-item-meta">
          <div className="ant-list-item-meta-avatar">
            <span className="ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh">
              <Skeleton.Avatar className="mt-2" size={45} active={true} />
            </span>
          </div>

          <div className="ant-list-item-meta-content">
            <Skeleton.Input className="mt-1 content" active={true} />
          </div>
        </div>
      </li>
    );
  };

  const handleScroll = async (e: any) => {
    const { target } = e;
    if (Math.round(target.scrollTop + target.offsetHeight) === Math.round(target.scrollHeight)) {
      try {
        if (userList.length < totalUsersCount) {
          dispatch(setIsUserListLoading(true));
          const res = await fetchAllUsers("", userPageCount);
          dispatch(setUserListPageCount(userPageCount + 1));
          const data = getFilteredContributors(res);
          setfilteredContributors([...filteredContributors, ...data]);
          setUserList([...userList, ...res]);
          setAdminsList([...adminsList, ...res]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsUserListLoading(false));
      }
    }
  };

  const renderAssetAndContributor = () => {
    if (isAddContributorVisible) {
      return (
        <Card className="default-card">
          {/* Add people */}
          <div className="add-people">
            <div className="add-people-inner d-flex d-flex-middle">
              <div className="people-input position-relative">
                <img
                  className="search-icon position-absolute"
                  // src={IMAGES.searchIcon}
                  alt="Search Icon"
                />
                <Select
                  placeholder="Search names or emails"
                  optionLabelProp="value"
                  showSearch
                  onChange={onContributorsChange}
                  onSearch={onSearch}
                  value={addContributors}
                  labelInValue
                  suffixIcon={null}
                  popupClassName="people-input-dropdoun"
                  onPopupScroll={handleScroll}>
                  {filteredContributors &&
                    filteredContributors.map((v) => {
                      return (
                        <Select.Option key={v.id} value={v?.name} label={v?.name}>
                          <div className="people-info">
                            <img src={IMAGES.avatarDefault} />
                            <span className="user-name">{v?.name}</span>
                            <span className="user-email">{v?.email}</span>
                          </div>
                        </Select.Option>
                      );
                    })}
                </Select>
              </div>
              <Button
                type="primary"
                className="add-people-btn"
                onClick={handleAddContributors}
                disabled={addContributors?.value === ""}>
                Add people
              </Button>
            </div>

            {/* People List */}
            <div className="people-list-items">
              <ul className="ant-list-items sidebar-list">
                {content?.contributors &&
                  content?.contributors.map((v) => {
                    return (
                      <li className="ant-list-item" key={uniqueId()}>
                        <div className="ant-list-item-meta">
                          <div className="ant-list-item-meta-avatar">
                            <span className="ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh">
                              {/* <img src={IMAGES.avatar1} alt="avatar" /> */}
                            </span>
                          </div>
                          <div className="ant-list-item-meta-content">
                            <h5 className="ant-list-item-meta-title">{v?.name}</h5>
                            {/* Edit View */}
                            <p
                              className="link-action"
                              onClick={() => handleRemoveContributorModal(v?.id)}>
                              Remove
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="bottom-btn">
              <Button
                type="primary"
                className="save-changes"
                onClick={() => setIsAddContributorVisible(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      );
    } else {
      return (
        <Card className="default-card">
          <Row className="asset-owner-info">
            <Col span={8} className="divider">
              {loading ? (
                <Skeleton.Input className="skeleton-input title" active={true} />
              ) : (
                <h4 className="card-heading">
                  Owner
                  {isEditMode && !content?.user?.name && (
                    <span className="choose-span">(choose asset owner)</span>
                  )}
                </h4>
              )}
              <ul
                className={
                  (content?.user?.name === "" || content?.user === null) && isEditMode
                    ? "ant-list-items sidebar-list admin-list"
                    : "ant-list-items sidebar-list"
                }>
                {(content?.user?.name === "" || content?.user === null) && isEditMode ? (
                  <div className="add-people-inner d-flex d-flex-middle">
                    <div className="people-input position-relative">
                      <i className="ri-search-line search-icon position-absolute"></i>
                      <Select
                        placeholder="Search names or emails"
                        optionLabelProp="value"
                        showSearch
                        onChange={handleSelectOwner}
                        onSearch={onSearch}
                        value={selectedOwner}
                        labelInValue
                        suffixIcon={null}
                        popupClassName="people-input-dropdoun"
                        // onPopupScroll={handleScroll}
                      >
                        {adminsList.map((v) => {
                          return (
                            <Select.Option key={v.id} value={v?.name} label={v?.name}>
                              <div className="people-info">
                                <img src={IMAGES.avatarDefault} />
                                <span className="user-name">{v?.name}</span>
                                <span className="user-email">{v?.email}</span>
                              </div>
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </div>
                    <Button
                      type="primary"
                      className="btn"
                      onClick={handleChangeOwnerAction}
                      disabled={selectedOwner?.value === ""}>
                      Change owner
                    </Button>
                  </div>
                ) : content?.user?.name === "" || content?.user === null ? (
                  loading ? (
                    <AvatarAndTitleSkeleton />
                  ) : (
                    <div className="empty-owner">
                      <Empty />
                    </div>
                  )
                ) : (
                  <li className="ant-list-item">
                    <div className="ant-list-item-meta">
                      <div className="ant-list-item-meta-avatar">
                        <span
                          className={
                            content.user.status === AssetUploadStatus.DELETED
                              ? "ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh alt-avatar-grey-user-image"
                              : "ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh"
                          }>
                          {loading ? (
                            <Skeleton.Avatar className="mt-2" size={45} active={true} />
                          ) : (
                            <img src={IMAGES.avatarDefault} className="avatar" alt="avatar" />
                            // <div className="user-icon">
                            //   <i className="ri-user-fill"></i>
                            // </div>
                          )}
                        </span>
                      </div>

                      {/* Edit View */}
                      <div className="ant-list-item-meta-content">
                        {loading ? (
                          <Skeleton.Input className="mt-1 content" active={true} />
                        ) : (
                          <div>
                            <h5
                              className={
                                content.user.status === AssetUploadStatus.DELETED
                                  ? "ant-list-item-meta-delete-user-title"
                                  : "ant-list-item-meta-title"
                              }>
                              {content?.user?.status === AssetUploadStatus.DELETED
                                ? "Deleted User"
                                : content?.user?.name}
                            </h5>
                            {content?.user?.status !== AssetUploadStatus.DELETED && (
                              <p
                                className="link-action"
                                hidden={isEditMode}
                                onClick={() => window.open(`mailto:${content?.user?.email}`)}>
                                <i className="ri-mail-line"></i>
                              </p>
                            )}
                            <p
                              className="link-action"
                              hidden={!isEditMode}
                              onClick={() => setIsOpenOwnerRemoveModal(true)}>
                              Remove
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </Col>
            <Col span={16}>
              <div className="contributor-list-inner">
                {loading ? (
                  <Skeleton.Input className="skeleton-input title" active={true} />
                ) : (
                  <h4 className="card-heading d-flex d-flex-middle d-flex-between">
                    Contributor(s)
                    {/* Edit View */}
                    <div className="d-flex d-flex-middle">
                      <div hidden={!isEditMode || isShowContributorSearch}>
                        <Button
                          className="btn gen-btn"
                          type="default"
                          onClick={() => setIsShowContributorSearch(true)}
                          icon={<i className="ri-add-circle-fill"></i>}>
                          Add people
                        </Button>
                      </div>

                      <div
                        hidden={!isEditMode || !isShowContributorSearch}
                        className="add-people-inner d-flex d-flex-middle">
                        <div className="people-input position-relative">
                          <i className="ri-search-line search-icon position-absolute"></i>
                          <Select
                            placeholder="Search names or emails"
                            optionLabelProp="value"
                            showSearch
                            onChange={onContributorsChange}
                            onSearch={onSearch}
                            value={addContributors}
                            labelInValue
                            suffixIcon={null}
                            popupClassName="people-input-dropdoun"
                            onPopupScroll={handleScroll}>
                            {filteredContributors &&
                              filteredContributors.map((v) => {
                                return (
                                  <Select.Option key={v.id} value={v?.name} label={v?.name}>
                                    <div className="people-info">
                                      <img src={IMAGES.avatarDefault} />
                                      <span className="user-name">{v?.name}</span>
                                      <span className="user-email">{v?.email}</span>
                                    </div>
                                  </Select.Option>
                                );
                              })}
                            {isUserListLoading && (
                              <div className="loaderItem">
                                {/* <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" /> */}
                              </div>
                            )}
                          </Select>
                        </div>
                        <div className="btn-wrap">
                          <Button
                            type="primary"
                            className="btn"
                            onClick={handleAddContributors}
                            disabled={addContributors?.value === ""}>
                            Add people
                          </Button>
                          <Button
                            type="default"
                            className="btn"
                            onClick={() => setIsShowContributorSearch(false)}>
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </div>
                  </h4>
                )}

                <ul className="ant-list-items sidebar-list">
                  {content?.contributors && content?.contributors?.length ? (
                    content?.contributors.map((v) => {
                      return (
                        <li className="ant-list-item" key={uniqueId()}>
                          <div className="ant-list-item-meta">
                            <div className="ant-list-item-meta-avatar">
                              <span
                                className={
                                  v.status === AssetUploadStatus.DELETED
                                    ? "ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh alt-avatar-grey-user-image"
                                    : "ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh"
                                }>
                                <img src={IMAGES.avatarDefault} className="avatar" alt="avatar" />
                                {/* <div className="user-icon">
                                  <i className="ri-user-fill"></i>
                                </div> */}
                              </span>
                            </div>
                            <div className="ant-list-item-meta-content">
                              <h5
                                className={
                                  v.status === AssetUploadStatus.DELETED
                                    ? "ant-list-item-meta-delete-user-title"
                                    : "ant-list-item-meta-title"
                                }>
                                {v?.status === AssetUploadStatus.DELETED ? "Deleted User" : v?.name}
                              </h5>

                              {/* Edit View */}
                              <p
                                className="link-action"
                                hidden={!isEditMode}
                                onClick={() => handleRemoveContributorModal(v?.id)}>
                                Remove
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : loading ? (
                    [...Array(3)].map((_, index) => {
                      return (
                        <div key={index}>
                          <AvatarAndTitleSkeleton />
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-record">No record found</div>
                  )}
                </ul>
              </div>
            </Col>
          </Row>
        </Card>
      );
    }
  };

  // const isShowEditButton = () =>
  //   ((!getIsAssetProcessing(contentById?.assetStatus) &&
  //     !getIsSummaryProcessing(contentById?.summaryStatus) &&
  //     getUserRole() === UserRoles.Admin) ||
  //     (getUserRole() === UserRoles.ContentEditor &&
  //       content?.is_editable === true &&
  //       !isEditMode)) &&
  //   fromWhere !== FromWhere.Source;

  const isShowEditButton = () =>
    !getIsAssetProcessing(contentById?.assetStatus) &&
    !getIsSummaryProcessing(contentById?.summaryStatus) &&
    // assetEditPermission(contentById) &&
    !isEditMode;

  const getIsContributorsEdited = (): boolean => {
    const prevContributorsId = (contentById?.contributors || []).map((item) => item?.id);
    const currContributorsId = (content?.contributors || []).map((item) => item?.id);
    let isContributorsEdited = true;

    if (prevContributorsId.length === currContributorsId.length) {
      if (prevContributorsId.length === 0 && currContributorsId.length === 0) {
        isContributorsEdited = false;
      } else {
        for (const id of prevContributorsId) {
          if (currContributorsId.includes(id)) {
            isContributorsEdited = false;
          } else {
            isContributorsEdited = true;
            break;
          }
        }
      }
    } else {
      isContributorsEdited = true;
    }

    return isContributorsEdited;
  };

  const getIsAssetTagsEdited = (): boolean => {
    const { prevTaxonomyNodeIds, currTaxonomyNodeIds } = getFormmatedAssetIds();
    let isAssetTagsEdited = false;

    if (prevTaxonomyNodeIds.length === currTaxonomyNodeIds.length) {
      if (prevTaxonomyNodeIds.length === 0 && currTaxonomyNodeIds.length === 0) {
        isAssetTagsEdited = false;
      } else {
        for (const id of prevTaxonomyNodeIds) {
          if (currTaxonomyNodeIds.includes(id)) {
            isAssetTagsEdited = false;
          } else {
            isAssetTagsEdited = true;
            break;
          }
        }
      }
    } else {
      isAssetTagsEdited = true;
    }

    return isAssetTagsEdited;
  };

  const getIsAnyAssestEdited = (): boolean => {
    const isContributorsEdited = getIsContributorsEdited();
    const isAssetTagsEdited = getIsAssetTagsEdited();

    const prevTitle = contentById?.title ?? "";
    const prevSummary = contentById?.summary ?? "";
    const prevContentUserId = contentById?.user?.id ?? -1;

    const currTitle = content?.title ?? "";
    const currSummary = content?.summary ?? "";
    const currContentUserId = content?.user?.id ?? -1;
    const currSummaryStatus = content?.summaryStatus ?? "";

    if (
      prevTitle !== currTitle ||
      prevSummary !== currSummary ||
      prevContentUserId !== currContentUserId ||
      isContributorsEdited ||
      isAssetTagsEdited ||
      currSummaryStatus === SummaryStaus.PROCESSING
    ) {
      return true;
    }
    return false;
  };

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      getIsAnyAssestEdited() && isEditMode && currentLocation.pathname !== nextLocation.pathname
  );

  const handlecancelUnsavedChanges = () => {
    setIsShowUnsavedChangesModal(false);
    blocker.state === "blocked" && blocker.reset();
  };

  const handleProceedUnsavedChanges = () => {
    if (blocker.state === "blocked") {
      if (content?.summaryStatus === SummaryStaus.PROCESSING) {
        handleUpdateSummaryStatus();
      }
      blocker.proceed();
    }
  };

  useEffect(() => {
    if (blocker?.state === "blocked") {
      setIsShowUnsavedChangesModal(true);
    }
  }, [blocker]);

  const handlePublishedValidation = () => {
    let flag = false;
    let messageList = [];

    if (getIsAssetProcessing(content?.assetStatus)) {
      toast.error("Unable to publish as document is still under AI processing");
      return true;
    }

    if (!content?.title || content?.title.trim() === "") {
      messageList.push("Title");
      flag = true;
    }
    if (!content?.summary || content?.summary.trim() === "") {
      messageList.push("Summary");
      flag = true;
    }
    if (!selectedAssetType) {
      messageList.push("Asset type");
      flag = true;
    }

    if (messageList && messageList.length) {
      let message = "";
      messageList.forEach((item, index) => {
        message =
          message +
          `${
            index === 0 ? "" : index > 0 && index === messageList.length - 1 ? " and " : ", "
          }${item}`;
      });

      if (message) {
        message = message + ` ${messageList.length > 1 ? "inputs" : "input"} cannot be empty`;
        toast.error(message);
      }
    }

    return flag;
  };

  const handlePublishChange = async () => {
    if (handlePublishedValidation()) return;

    const isPublishedValue = ["TRUE", true].includes(content?.isPublished) ? true : false;
    if (!isPublishedValue) {
      const response = await enablePublish(content?.asset_id);
      if (response?.data) {
        setContent((prev) => ({
          ...prev,
          isPublished: "TRUE",
          publishedOn: response?.data?.publishedOn,
        }));
        dispatch(
          updateAssetByAssetId({
            data: {
              ...content,
              isPublished: "TRUE",
              publishedOn: response?.data?.publishedOn,
            },
            id: content?.asset_id,
          })
        );
      }
    } else {
      const response = await disablePublish(content?.asset_id);
      if (response?.data) {
        setContent((prev) => ({
          ...prev,
          isPublished: "FALSE",
          publishedOn: response?.data?.publishedOn,
        }));
        dispatch(
          updateAssetByAssetId({
            data: {
              ...content,
              isPublished: "FALSE",
              publishedOn: response?.data?.publishedOn,
            },
            id: content?.asset_id,
          })
        );
      }
    }
  };

  const handleSetAutoRefreshedData = () => {
    const prevAssetStatus = content?.assetStatus || "";
    const currAssetStatus = contentById?.assetStatus || "";
    const prevThumbnailPreSignedUrl = content?.thumbnail_pre_signed_url || "";
    const currThumbnailPreSignedUrl = contentById?.thumbnail_pre_signed_url || "";
    const prevSummaryStatus = content?.summaryStatus || "";
    const currSummaryStatus = contentById?.summaryStatus || "";

    if (
      prevAssetStatus !== currAssetStatus ||
      prevThumbnailPreSignedUrl !== currThumbnailPreSignedUrl ||
      prevSummaryStatus !== currSummaryStatus
    ) {
      setContent((prev) => ({
        ...prev,
        assetStatus: contentById?.assetStatus,
        // thumbnail_pre_signed_url: contentById?.thumbnail_pre_signed_url,
        summaryStatus: contentById?.summaryStatus,
        modifiedOn: contentById?.modifiedOn,
      }));
    }
  };

  useEffect(() => {
    handleSetAutoRefreshedData();
  }, [contentById, isNewAssetUpload]);

  // const handleSetTitleOnNewUpload = () => {
  //   if (isNewAssetUpload && !content.title) {
  //     let title = "";

  //     if (contentById.asset_url) {
  //       title = contentById.asset_url;
  //     } else {
  //       title = contentById.original_file_name;
  //     }
  //     setContent((prev) => ({
  //       ...prev,
  //       title: title,
  //     }));
  //   }
  // };

  // useEffect(() => {
  //   handleSetTitleOnNewUpload();
  // }, [contentById, isNewAssetUpload]);

  useEffect(() => {
    if (!adminsList?.length) setAdminsList(allUsers);
  }, [allUsers]);

  const getFilterBasedOnScreen = () => {
    if (fromWhere === FromWhere.Source) {
      return sourceFilter;
    } else if (fromWhere === FromWhere.AdminContentManagement) {
      return adminContentFilter;
    } else {
      return contentFilter;
    }
  };

  const handleDeleteAsset = async () => {
    setIsEditMode(false);
    if (currentAssetId) {
      const res: any = await deleteAssetById(currentAssetId(), totalContentsCount, false, {
        ...getFilterBasedOnScreen(),
        currentPage: 1,
      });

      if (!isEmpty(res?.data)) {
        let contentsValues = [...contents];
        contentsValues = !isEmpty(contentsValues)
          ? contentsValues?.filter((item) => item.asset_id !== currentAssetId())
          : [];
        dispatch(setAllContentDetails(contentsValues));
        handleBack();
      }
    }
    setIsShowAssetDeleteModal(false);
    setIsEditMode(false);
  };

  const onHandleOkAction = () => {
    persistStore(store).purge();
    removeLocalstorage();
    indexedDB.deleteDatabase("localforage");
    indexedDB.deleteDatabase("knowledge-management");
    navigate(PATHS.login);
  };

  const handleChangeIndustry = (selectedNodes: string[]) => {
    let ids: string[] = [];

    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node: any) => {
        ids.push(node?.value);
      });
    }
    setIsTagsEdited(true);
    setSelectedIndustries(ids);
  };

  const handleChangeFunction = (selectedNodes: string[]) => {
    let ids: string[] = [];

    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node: any) => {
        ids.push(node?.value);
      });
    }
    setIsTagsEdited(true);
    setSelectedFunction(ids);
  };

  const handleDownloadFile = async () => {
    try {
      setIsPdfDownloading(true);
      const fileName = content?.inputFileName ? content.inputFileName : content?.file_name;
      const res = await getPDFPreSignedUrl(fileName);
      if (!res?.errorMessage) {
        await updateDownloadLogs(content);
        await updateDownloadCount(content?.asset_id);
        await onDownloadPdf(res, null, content?.original_file_name);
      }
    } catch (error) {
      console.log("Error while fetching url", error);
    } finally {
      setIsPdfDownloading(false);
    }
  };

  const getIsShowGenerateSummaryBtn = () =>
    [UserRoles.Admin, UserRoles.ContentEditor].includes(getUserRole()) && isEditMode;

  const handleGenerateSummary = async () => {
    dispatch(setIsSummaryGenerationLoading(true));
    const res = await generateSummary(currentAssetId());

    if (res?.data === "Summary generation under process.") {
      setContent((prev) => ({ ...prev, summaryStatus: SummaryStaus.PROCESSING }));
    } else {
      dispatch(setIsSummaryGenerationLoading(false));
    }
  };

  const handleSummaryPulling = () => {
    const summaryStatus = content?.summaryStatus || "";

    if (
      isEditMode &&
      [String(SummaryStaus.PROCESSING), SummaryStaus.TO_BE_PROCESSED].includes(summaryStatus)
    ) {
      summaryInterval = setInterval(async () => {
        let res = await getAssetDetailsByAssetId(currentAssetId());

        if (res?.summaryStatus === SummaryStaus.COMPLETED) {
          setContent((prev) => ({
            ...prev,
            summaryStatus: res?.summaryStatus,
            summary: res?.ai_generated_summary,
            modifiedOn: res?.modifiedOn,
          }));
        }
      }, 5000);
    } else {
      dispatch(setIsSummaryGenerationLoading(false));
      clearInterval(summaryInterval);
    }
  };

  useEffect(() => {
    handleSummaryPulling();
    return () => {
      clearInterval(summaryInterval);
    };
  }, [content]);

  const handlePDFClose = () => {
    dispatch(setIsPDFModalOpen(false));
  };

  const handleTitleClick = () => {
    dispatch(setPDFContent(content));
    if (content?.file_type === null) {
      toast.error("URL can't be viewed.");
    } else if (Images.includes(content?.file_type || "")) {
      dispatch(setIsImageVisible(true));
      dispatch(setImageURL(content?.thumbnail_pre_signed_url ?? ""));
    } else if (content?.file_type === "xls" || content?.file_type === "xlsx") {
      toast.error("The Excel file is not supported. Please download it to view.");
    } else {
      handleOpenPdfDoc(
        content?.asset_id,
        content.file_name,
        content?.pre_signed_url,
        content?.title,
        content.total_pages,
        false
      );
    }
  };

  const RenderStatus = (data: IContent) => {
    if (data?.assetStatus !== AssetUploadStatus.COMPLETED && fromWhere !== FromWhere.Source) {
      return (
        <AssetStatus
          content={data}
          isShowAllStatus={[String(FromWhere.AdminContentManagement), FromWhere.MyContent].includes(
            fromWhere
          )}
        />
      );
    }
  };

  const RenderViewEditContent = () => {
    return (
      <>
        <div className="published-box">{RenderStatus(content)}</div>

        {content.assetStatus === AssetUploadStatus.COMPLETED &&
          !isEditMode &&
          fromWhere !== FromWhere.AdminContentManagement && <ContentChatButton {...{ content }} />}

        {content.assetStatus === AssetUploadStatus.COMPLETED && isShowEditButton() && (
          <div className="published-box" hidden={isEditMode}>
            <span className="published-label">Published:</span>
            <Switch
              checked={["TRUE", true].includes(content?.isPublished) ? true : false}
              onChange={handlePublishChange}
            />
          </div>
        )}

        {isShowEditButton() && !isReadOnly && !isEditMode && (
          <Button
            className="btn"
            type="default"
            onClick={() => setIsEditMode(true)}
            icon={<i className="ri-edit-fill"></i>}>
            Edit
          </Button>
        )}
      </>
    );
  };

  const getHeaderTitle = () => {
    if (loading) {
      return "";
    } else {
      if (content?.title) return content?.title;
      else return "Title";
    }
  };

  const handleVideoAudioModal = () => {
    dispatch(isShowVideoPLayerModal({ content, isOpen: true, isCitation: false }));
    dispatch(resetMediaUploadState());

    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset to start
      videoRef.current = null;
    }

    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset to start
      audioRef.current = null;
    }
  };

  const renderMediaIcons = () => {
    if (!loading) {
      if (isFileTYpeVideo(content?.file_type)) {
        return (
          <span onClick={handleVideoAudioModal}>
            <Tooltip overlayClassName="tooltip-text" title={"Full Screen"}>
              <i className="ri-fullscreen-fill icon-link" />
            </Tooltip>
          </span>
        );
      }
      if (isFileTypeAudio(content?.file_type) && !loadingAudio) {
        if (isAudioPlaying)
          return (
            <i
              className="ri-stop-fill play-icon  icon-link"
              onClick={() => handleTogglePlayPause(ToggleAudio.play)}
            />
          );
        else
          return (
            <i
              className="ri-play-fill play-icon  icon-link"
              onClick={() => handleTogglePlayPause(ToggleAudio.pause)}
            />
          );
      }
    }
  };

  const handleTogglePlayPause = (toggle: ToggleAudio) => {
    dispatch(setToggleAudio(toggle));
  };

  const renderFileThumbnail = () => {
    if (loading) {
      return <Skeleton.Image className="skeleton-image" active={true} />;
    } else if (isNewAssetUpload && !content?.thumbnail_pre_signed_url) {
      return (
        <div
          className="thumbnail cursor-pointer"
          onClick={handleTitleClick}
          style={{
            backgroundImage: `url(${content?.thumbnail_pre_signed_url})`,
          }}>
          <div className="file-uploaded">
            <img src={IMAGES.fileUploadedIcon} alt="upload file" />
            <p>File uploaded</p>
          </div>
        </div>
      );
    } else if (isFileTYpeVideo(content?.file_type)) {
      return (
        <div className="thumbnail cursor-pointer mediaStyle-video">
          <MediaPlayer presignedUrl={content?.pre_signed_url} ref={videoRef} id="component" />
        </div>
      );
    } else if (isFileTypeAudio(content?.file_type)) {
      return (
        <div className="thumbnail cursor-pointer mediaStyle">
          <AudioPlayer src={content?.pre_signed_url ?? ""} ref={audioRef} detailPageView={true} />
        </div>
      );
    } else
      return (
        <div
          className="thumbnail cursor-pointer"
          onClick={handleTitleClick}
          style={{
            backgroundImage: `url(${content?.thumbnail_pre_signed_url})`,
          }}
        />
      );
  };

  return (
    <Flex className="inner-app-wrap inner-app-wrap view-edit-content-management-page" vertical>
      <PageHeader
        title={getHeaderTitle()}
        isRelevantAsset={isRelevantAsset}
        isHeadBack={!isRelevantAsset}
        fromWhere={fromWhere}
        content={RenderViewEditContent()}
      />

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.25 }}>
        <div
          className={`asset-container view-edit-content-management ${
            isReadOnly || !isEditMode ? "read-only" : ""
          }`}>
          <div className="view-edit-scroll">
            <Card className="view-edit-card-container">
              <Row className="global-gap">
                <Col className="asset-block1 radius-0" span={24}>
                  <Card>
                    <div className="asset-block1-card">
                      <Row>
                        <Col className="asset-block1-left" span={8}>
                          <Card>
                            {renderFileThumbnail()}

                            <span className="card-content">
                              {loading ? (
                                <Skeleton.Input
                                  className="skeleton-input content mt-1"
                                  active={true}
                                />
                              ) : (
                                <Tooltip
                                  className="title-span"
                                  overlayClassName="tooltip-text"
                                  title={"Title"}>
                                  <span onClick={handleTitleClick}>{content.title}</span>
                                </Tooltip>
                              )}

                              <div className="asset-thumb-card-option d-flex d-flex-middle">
                                {renderMediaIcons()}

                                {!loading &&
                                  (content?.asset_url === null ? (
                                    !isPdfDownloading ? (
                                      <span onClick={handleDownloadFile}>
                                        <Tooltip overlayClassName="tooltip-text" title={"Download"}>
                                          <i className="ci-small-download-icon ci icon-link" />
                                        </Tooltip>
                                      </span>
                                    ) : (
                                      <Spin
                                        className="spin-loader"
                                        indicator={<LoadingOutlined spin />}
                                      />
                                    )
                                  ) : (
                                    <span
                                      onClick={() => {
                                        if (content?.asset_url) window.open(content?.asset_url);
                                        handleOpenPdfDoc(
                                          content.asset_id,
                                          content.file_name,
                                          content?.pre_signed_url,
                                          content.title,
                                          1,
                                          false
                                        );
                                      }}>
                                      <Tooltip overlayClassName="tooltip-text" title={"Open URL"}>
                                        <i className="ci-small-external-link ci icon-link external"></i>
                                      </Tooltip>
                                    </span>
                                  ))}
                              </div>
                            </span>
                          </Card>
                        </Col>

                        <Col className="asset-block1-right" span={16}>
                          {loading ? (
                            <Skeleton.Input className="skeleton-input" active={true} />
                          ) : (
                            <div className="edit-mode edit-mode-title">
                              <div className="title-extra-option">
                                <h4>
                                  Title
                                  {/* <span className="star">*</span> */}
                                </h4>

                                <div className="date-row">
                                  {/* <span className="publi-shed">
                            {content?.isPublished === "TRUE" && "Published"}
                            {content?.isPublished === "FALSE" && "Draft"}
                          </span> */}
                                  <div className="date-meta">
                                    <span className="date">
                                      {content?.uploadedOn && (
                                        <>
                                          <b>Created date :</b>{" "}
                                          {getFormattedDate(content?.uploadedOn, "type1")}
                                        </>
                                      )}
                                    </span>

                                    <span className="date">
                                      {content?.modifiedOn && (
                                        <>
                                          <b>Last update :</b>{" "}
                                          {getFormattedDate(content?.modifiedOn, "type1")}
                                        </>
                                      )}
                                    </span>

                                    <span className="date">
                                      <b>Type: </b>
                                      <Select
                                        showSearch
                                        popupClassName="ui-dropdown-default"
                                        placeholder="Select Asset Type"
                                        onChange={handleSelectAssestType}
                                        value={
                                          !isEditMode && !isContainAssetType
                                            ? "Not assigned"
                                            : selectedAssetType
                                        }
                                        disabled={!isEditMode}
                                        placement="bottomRight"
                                        popupMatchSelectWidth={false}
                                        filterOption={(inputValue: string, option: any) =>
                                          option?.label
                                            ?.toLowerCase()
                                            .indexOf(inputValue.toLowerCase()) !== -1
                                        }>
                                        {mappedAssetType &&
                                          mappedAssetType?.map((v) => {
                                            return (
                                              <Select.Option
                                                key={v?.value}
                                                value={v?.value}
                                                label={v?.label}>
                                                <div>{v?.label}</div>
                                              </Select.Option>
                                            );
                                          })}
                                      </Select>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <h4 hidden={isEditMode} className="asset-title-display">
                                {content?.title}
                              </h4>
                              <Input
                                className="title-edit input-style"
                                value={content?.title}
                                onChange={handleChangeTitle}
                                hidden={!isEditMode}
                              />
                            </div>
                          )}

                          {/* Edit View */}

                          {loading ? (
                            <>
                              <Skeleton.Input className="skeleton-input" active={true} />
                              <Skeleton.Input
                                className="mt-2 skeleton-input content"
                                active={true}
                              />
                              <Skeleton.Input
                                className="mt-2 skeleton-input content"
                                active={true}
                              />
                              <Skeleton.Input
                                className="mt-2 skeleton-input content"
                                active={true}
                              />
                              <Skeleton.Input
                                className="mt-2 skeleton-input content"
                                active={true}
                              />
                              <Skeleton.Input
                                className="mt-2 skeleton-input content last"
                                active={true}
                              />
                            </>
                          ) : (
                            <div className="d-flex d-flex-between">
                              <h4>Summary</h4>
                              <div>
                                {getIsShowGenerateSummaryBtn() && (
                                  <Button
                                    type="primary"
                                    className="btn gen-btn generate-summary-button"
                                    icon={<i className="ri-bard-fill"></i>}
                                    onClick={handleGenerateSummary}
                                    loading={isSummaryGenerationLoading}
                                    disabled={isSummaryGenerationLoading}>
                                    {isSummaryGenerationLoading ? "Generating" : "Generate"}
                                  </Button>
                                )}
                              </div>
                            </div>
                          )}

                          {!loading && (
                            <p hidden={isEditMode}>
                              <Markdown className="mark-down">{content?.summary}</Markdown>
                            </p>
                          )}
                          {/* Edit View */}
                          <div className="edit-mode" hidden={!isEditMode}>
                            <TextArea
                              value={(content?.summary ?? "")
                                .replaceAll("*", "")
                                .replaceAll("#", "")}
                              className="title-edit input-style"
                              rows={4}
                              onChange={handleChangeSummary}
                              disabled={isSummaryGenerationLoading}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>

                {content?.transcript?.length ? (
                  <Col className="asset-block3 radius-0" span={24}>
                    <Card className="default-card">
                      <h4 className="card-heading d-flex d-flex-middle d-flex-between">
                        <span>Transcript</span>
                      </h4>

                      <div className="timestamp-container">
                        <div className="timestamp-wrap">
                          {!!content?.transcript.length &&
                            content?.transcript?.map((v) => (
                              <div
                                className="timestamp-text-wrap"
                                key={uuidv4()}
                                onClick={() => {
                                  dispatch(setVideoTime(v?.start_time));
                                  if (videoRef.current === null) {
                                    videoRef.current = document.getElementById(
                                      "component"
                                    ) as HTMLVideoElement;
                                  }
                                }}>
                                <div className="timestamp-text">
                                  <i className="ri-play-fill" />
                                  {renderTimeStamp(v?.start_time)}
                                </div>
                                <div>{v?.text}</div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </Card>
                  </Col>
                ) : null}

                <Col className="asset-block3 radius-0" span={24}>
                  <Row className="industry-function-tag">
                    <Col span={12}>
                      <Card className="default-card">
                        <div className="industry-function">
                          <h4 className="card-heading d-flex d-flex-middle d-flex-between">
                            {loading ? (
                              <Skeleton.Input className="skeleton-input" active={true} />
                            ) : (
                              <>
                                <span>Industry</span>
                                {/* {isEditMode && (
                                  <Button
                                    type="primary"
                                    className="btn gen-btn"
                                    icon={<i className="ri-bard-fill"></i>}>
                                    Generate
                                  </Button>
                                )} */}
                              </>
                            )}
                          </h4>

                          <div hidden={!isEditMode} className="select-industry-wrapper">
                            <EditContentTags
                              selectedTags={selectedIndustries}
                              mappedContentTags={mappedContentIndustry}
                              popupClassName="ui-choose-select"
                              placeholder="Choose"
                              handleChange={handleChangeIndustry}
                              isNonLeafNodeDisable={false}
                              treeCheckStrictly={true}
                            />
                            <div className="list-tags-select">
                              {formattedSelectedIndustryTag &&
                              formattedSelectedIndustryTag.length ? (
                                formattedSelectedIndustryTag.map(
                                  (industry: IFormattedIndustry, industryIdx: number) => {
                                    if (industry.parents && industry.parents.length) {
                                      return (
                                        <div className="item-tags" key={industryIdx}>
                                          <Space size={[0, 1]} className="tags">
                                            {industry.parents.map(
                                              (
                                                indParent: IMappedIndustryChildren,
                                                indParentIdx: number
                                              ) => {
                                                return (
                                                  <Tag key={indParentIdx}>{indParent.title}</Tag>
                                                );
                                              }
                                            )}
                                            <Tag>{industry.title}</Tag>
                                          </Space>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className="item-tags" key={industryIdx}>
                                          <Space size={[0, 1]} className="tags mb-24">
                                            <Tag>{industry.title}</Tag>
                                          </Space>
                                        </div>
                                      );
                                    }
                                  }
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div className="list-tags-select" hidden={isEditMode}>
                            {formattedSelectedIndustryTag &&
                            formattedSelectedIndustryTag.length &&
                            !loading ? (
                              formattedSelectedIndustryTag
                                .slice(0, showIndustryLimit)
                                .map((industry: IFormattedIndustry, industryIdx: number) => {
                                  if (industry.parents && industry.parents.length) {
                                    return (
                                      <div className="item-tags" key={industryIdx}>
                                        <Space size={[0, 1]} className="tags">
                                          {industry.parents.map(
                                            (
                                              indParent: IMappedIndustryChildren,
                                              indParentIdx: number
                                            ) => {
                                              return (
                                                <Tag key={indParentIdx}>{indParent.title}</Tag>
                                              );
                                            }
                                          )}
                                          <Tag>{industry.title}</Tag>
                                        </Space>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="item-tags" key={industryIdx}>
                                        <Space size={[0, 1]} className="tags mb-24">
                                          <Tag>{industry.title}</Tag>
                                        </Space>
                                      </div>
                                    );
                                  }
                                })
                            ) : loading ? (
                              <Skeleton.Input className="skeleton-input content" active={true} />
                            ) : (
                              <div className="no-record">No record found</div>
                            )}

                            {formattedSelectedIndustryTag.length > 1 &&
                              !loading &&
                              showIndustryLimit !== formattedSelectedIndustryTag.length && (
                                <div className="item-more-btn">
                                  <Space
                                    className="tags more-less-btn"
                                    onClick={() =>
                                      setShowIndustryLimit(formattedSelectedIndustryTag.length)
                                    }>
                                    <Tag className="cursor-pointer">
                                      +{formattedSelectedIndustryTag.length - showIndustryLimit}{" "}
                                      more
                                    </Tag>
                                  </Space>
                                </div>
                              )}
                            {formattedSelectedIndustryTag.length > 1 &&
                              showIndustryLimit === formattedSelectedIndustryTag.length && (
                                <div className="item-more-btn">
                                  <Space
                                    className="tags more-less-btn"
                                    onClick={() => setShowIndustryLimit(1)}>
                                    <Tag className="cursor-pointer">less</Tag>
                                  </Space>
                                </div>
                              )}
                          </div>
                        </div>
                      </Card>
                    </Col>

                    <Col span={12}>
                      <Card className="default-card">
                        <div className="mt-2 industry-function">
                          <h4 className="card-heading d-flex d-flex-middle d-flex-between">
                            {loading ? (
                              <Skeleton.Input className="skeleton-input" active={true} />
                            ) : (
                              <>
                                <span>Function</span>
                                {/* {isEditMode && (
                                  <Button
                                    type="primary"
                                    className="btn gen-btn"
                                    icon={<i className="ri-bard-fill"></i>}>
                                    Generate
                                  </Button>
                                )} */}
                              </>
                            )}
                          </h4>

                          <div hidden={!isEditMode} className="select-industry-wrapper">
                            <EditContentTags
                              selectedTags={selectedFunction}
                              mappedContentTags={mappedContentFunction}
                              popupClassName="ui-choose-select"
                              placeholder="Choose"
                              handleChange={handleChangeFunction}
                              isNonLeafNodeDisable={false}
                              treeCheckStrictly={true}
                            />
                            <div className="list-tags-select">
                              {formattedSelectedFunctionTag &&
                              formattedSelectedFunctionTag.length ? (
                                formattedSelectedFunctionTag.map(
                                  (fun: IFormattedIndustry, funIdx: number) => {
                                    if (fun.parents && fun.parents.length) {
                                      return (
                                        <div className="item-tags" key={funIdx}>
                                          <Space size={[0, 1]} className="tags">
                                            {fun.parents.map(
                                              (
                                                indParent: IMappedIndustryChildren,
                                                funParentIdx: number
                                              ) => {
                                                return (
                                                  <Tag key={funParentIdx}>{indParent.title}</Tag>
                                                );
                                              }
                                            )}
                                            <Tag>{fun.title}</Tag>
                                          </Space>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className="item-tags" key={funIdx}>
                                          <Space size={[0, 1]} className="tags">
                                            <Tag>{fun.title}</Tag>
                                          </Space>
                                        </div>
                                      );
                                    }
                                  }
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div className="list-tags-select" hidden={isEditMode}>
                            {formattedSelectedFunctionTag &&
                            formattedSelectedFunctionTag.length &&
                            !loading ? (
                              formattedSelectedFunctionTag
                                .slice(0, showFunctionLimit)
                                .map((fun: IFormattedIndustry, funIdx: number) => {
                                  if (fun.parents && fun.parents.length) {
                                    return (
                                      <div className="item-tags" key={funIdx}>
                                        <Space size={[0, 1]} className="tags">
                                          {fun.parents.map(
                                            (
                                              indParent: IMappedIndustryChildren,
                                              funParentIdx: number
                                            ) => {
                                              return (
                                                <Tag key={funParentIdx}>{indParent.title}</Tag>
                                              );
                                            }
                                          )}
                                          <Tag>{fun.title}</Tag>
                                        </Space>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="item-tags" key={funIdx}>
                                        <Space size={[0, 1]} className="tags">
                                          <Tag>{fun.title}</Tag>
                                        </Space>
                                      </div>
                                    );
                                  }
                                })
                            ) : loading ? (
                              <Skeleton.Input className="skeleton-input content" active={true} />
                            ) : (
                              <div className="no-record">No record found</div>
                            )}
                            {formattedSelectedFunctionTag.length > 1 &&
                              !loading &&
                              showFunctionLimit !== formattedSelectedFunctionTag.length && (
                                <div className="item-more-btn">
                                  <Space
                                    className="tags more-less-btn"
                                    onClick={() =>
                                      setShowFunctionLimit(formattedSelectedFunctionTag.length)
                                    }>
                                    <Tag className="cursor-pointer">
                                      +{formattedSelectedFunctionTag.length - showFunctionLimit}{" "}
                                      more
                                    </Tag>
                                  </Space>
                                </div>
                              )}
                            {formattedSelectedFunctionTag.length > 1 &&
                              showFunctionLimit === formattedSelectedFunctionTag.length && (
                                <div className="item-more-btn">
                                  <Space
                                    className="tags more-less-btn"
                                    onClick={() => setShowFunctionLimit(1)}>
                                    <Tag className="cursor-pointer">less</Tag>
                                  </Space>
                                </div>
                              )}
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>

                  {/* <Card className="other-information-card">
                  {loading ? (
                    <Skeleton.Input className="skeleton-input other-info" active={true} />
                  ) : (
                    <h4 className="card-heading">Other Information</h4>
                  )}

                  <div hidden={isEditMode}>
                    <p className="other-info">
                      {loading ? (
                        <Skeleton.Input className="skeleton-input content" active={true} />
                      ) : (
                        <strong>Created date :</strong>
                      )}

                      {getFormattedDate(content.uploadedOn, "type2")}
                    </p>
                    <p className="other-info">
                      {loading ? (
                        <Skeleton.Input className="skeleton-input content" active={true} />
                      ) : (
                        <strong>Published date :</strong>
                      )}
                      {!loading &&
                        (content.publishedOn
                          ? getFormattedDate(content.publishedOn, "type2")
                          : "N/A")}
                    </p>
                    <p className="other-info">
                      {loading ? (
                        <Skeleton.Input className="skeleton-input content" active={true} />
                      ) : (
                        <strong>Asset type :</strong>
                      )}
                      {assetTypeSelectedLabel}
                    </p>
                  </div>

                  <p className="other-info" hidden={!isEditMode}>
                    <span className="asset-type-select">
                      <strong>Asset type :</strong>
                      <Select
                        popupClassName="ui-dropdown-default"
                        placeholder="Select Asset Type"
                        onChange={handleSelectAssestType}
                        value={selectedAssetType}>
                        {mappedAssetType &&
                          mappedAssetType?.map((v) => {
                            return (
                              <Select.Option key={v?.value} value={v?.value} label={v?.label}>
                                <div>{v?.label}</div>
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </span>
                  </p>
                </Card> */}
                </Col>

                <Col className="asset-block2" span={24}>
                  {renderAssetAndContributor()}
                </Col>
              </Row>
            </Card>
          </div>

          <div hidden={isReadOnly || !isEditMode} className="asset-block1 asset-edit-bar">
            <div className="page-title-box page-title-edit-mode ">
              <div className="footer-warpper">
                <div
                  className={`${
                    appLayoutView === AppLayout.CLOSE &&
                    fromWhere !== FromWhere.AdminContentManagement
                      ? "delete-container"
                      : ""
                  }`}>
                  <Button
                    className="btn delete-btn"
                    danger
                    onClick={() => setIsShowAssetDeleteModal(true)}>
                    Delete
                  </Button>
                </div>

                <div>
                  <Button className="btn" type="default" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    className="btn edit-btn"
                    type="primary"
                    onClick={handleSaveChanges}
                    disabled={content?.summaryStatus === SummaryStaus.PROCESSING}
                    loading={isEditContentLoading}>
                    {isNewAssetUpload ? "Submit" : "Save changes"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Image Modal */}
      <ImageViewer fromWhere={fromWhere} />

      {downloadLogError && (
        <Modal
          className="delete-modal"
          title={"Alert! Limit crossed"}
          centered={true}
          onCancel={onHandleOkAction}
          footer={
            <>
              <Button className="btn-type2" onClick={onHandleOkAction}>
                Ok
              </Button>
            </>
          }
          open={downloadLogError != null}>
          <div>{downloadLogError}</div>
        </Modal>
      )}

      {/* PDF Modal */}
      <Modal
        className={`pdf-modal detail-open center-pagination ${
          fromWhere === FromWhere.AdminContentManagement ? "content-admin" : ""
        }`}
        title={false}
        centered={true}
        onCancel={handlePDFClose}
        footer={false}
        open={!isEmpty(pdfDetails) && isPDFModalOpen}
        style={{ top: "20px", right: "20px", position: "absolute", marginRight: "20px" }}>
        <PDFViewer
          content={content}
          isRelevantAsset={false}
          isChatButton={fromWhere !== FromWhere.AdminContentManagement}
        />
      </Modal>

      {/* Remove contributor modal */}
      {isOpenPeopleRemoveModal && (
        <DeleteModal
          isLoading={false}
          isModalOpen={isOpenPeopleRemoveModal}
          handleCancel={() => setIsOpenPeopleRemoveModal(false)}
          handleSubmit={handleRemoveContributor}
          message="This will remove the selected contributor"
          title="Are you sure you want to remove?"
          submitBtnName="Remove"
        />
      )}

      {/* Remove owner modal */}
      {isOpenOwnerRemoveModal && (
        <DeleteModal
          isLoading={false}
          isModalOpen={isOpenOwnerRemoveModal}
          handleCancel={() => {
            setIsOpenOwnerRemoveModal(false);
          }}
          handleSubmit={handleOwnerRemove}
          message="This will remove the current asset owner"
          title="Are you sure you want to remove?"
          submitBtnName="Remove"
        />
      )}

      {/* Unsaved Changes warning Mmodal */}
      <DeleteModal
        isLoading={false}
        isModalOpen={isShowUnsavedChangesModal}
        handleCancel={handlecancelUnsavedChanges}
        handleSubmit={handleProceedUnsavedChanges}
        message="This will remove your unsaved changes."
        title="Are you sure you want to leave?"
        submitBtnName="Proceed"
      />

      {/* Cancel warning Mmodal */}
      {isShowCancelWarnModal && (
        <DeleteModal
          isLoading={false}
          isModalOpen={isShowCancelWarnModal}
          handleCancel={() => setIsShowCancelWarnModal(false)}
          handleSubmit={handleResetChanges}
          message="This will remove your unsaved changes."
          title="Are you sure you want to cancel?"
          submitBtnName="Yes"
          cancelBtnName="No"
        />
      )}

      {/* delete Content modal */}
      {isShowAssetDeleteModal && (
        <DeleteModal
          isLoading={contentDeleteLoading}
          isModalOpen={isShowAssetDeleteModal}
          handleCancel={() => setIsShowAssetDeleteModal(false)}
          handleSubmit={handleDeleteAsset}
          message="Are you sure you want to delete this asset?"
          title="Delete Asset"
        />
      )}

      {isShowUpdateTagsModal && (
        <DeleteModal
          isLoading={isUpdateTagsLoading}
          isModalOpen={isShowUpdateTagsModal}
          handleCancel={() => setIsShowUpdateTagsModal(false)}
          handleSubmit={() => handleSaveAssetFieldValue(currentAssetId())}
          message="Updating the tags will make the asset unavailable for quite a while. Are you sure you want to proceed?"
          title="Reprocess Tags"
          submitBtnName="Proceed"
        />
      )}
    </Flex>
  );
};

export default viewEditContentManagement;
