import { Button } from "antd";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "./PdfViewer.scss";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setPDFData, setPdfDetails } from "../../../store/pdfViewer/pdfViewer.slice";
import {
  AssetUploadStatus,
  FromWhere,
  isEmpty,
  RelevantAssetView,
  ViewType,
} from "../../../shared";
import { searchPlugin } from "@react-pdf-viewer/search";
import jumpToPagePlugin from "./jumpToPagePlugin";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { getPDFPreSignedUrl } from "../../../services/conversationalSearch";
import LoadingPdf from "./LoadingPdf";
import { setRelevantAssetView } from "../../../store/contentManagement/contentSlice";
import { ContentChatButton } from "../../../components";
import { IContent } from "../../../store/contentManagement/content.interface";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";

interface IPDFViewer {
  isRelevantAsset: boolean;
  btnText?: string;
  btnClick?: () => void;
  isChatButton?: boolean;
  content?: IContent;
  isLocal?: boolean;
  isShowCustomCloseIcon?: boolean;
  handleNavigateContentById?: (assetId: number, isEditMode: boolean) => void;
  fromWhere?: string;
}

GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js`;

const PDFViewer = (props: IPDFViewer) => {
  const {
    isRelevantAsset,
    btnText = "View Detail",
    btnClick,
    isChatButton = false,
    content,
    isShowCustomCloseIcon = false,
    handleNavigateContentById,
    fromWhere,
  } = props;
  const dispatch = useDispatch();
  const zoomPluginInstance = zoomPlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const { CurrentPageLabel, GoToFirstPage, GoToLastPage, GoToNextPage, GoToPreviousPage } =
    pageNavigationPluginInstance;

  const { ZoomIn, ZoomOut, CurrentScale } = zoomPluginInstance;
  const jumpToPagePluginInstance = jumpToPagePlugin();
  const searchPluginInstance = searchPlugin();
  const { jumpToPage } = jumpToPagePluginInstance;
  const { setTargetPages } = searchPluginInstance; // highlight need to add here

  const [url, setUrl] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { pdfData, pdfDetails } = useSelector((state: RootState) => state.pdfViewer);
  const { relevantAssetView } = useSelector((state: RootState) => state.content);
  const { relevantAssetViewType } = useSelector((state: RootState) => state.conversationalSearch);

  const { page } = pdfDetails;

  useEffect(() => {
    setTargetPages((targetPage) => targetPage.pageIndex === page);
    handleJumpToPage();
  }, [page]);

  useEffect(() => {
    if (pdfDetails?.fileName && pdfData && Object.keys(pdfData).includes(pdfDetails?.fileName)) {
      if (url !== pdfData[pdfDetails?.fileName]) {
        // Added this if cond. because first time doc and ppt are not loading correctly
        if (["ppt", "pptx", "doc", "docx"].includes(content?.file_type || "")) {
          fetchURL();
        } else {
          setUrl(pdfData[pdfDetails?.fileName]);
          setIsError(false);
        }
      }
    } else {
      fetchURL();
    }
  }, [pdfDetails]);

  const fetchURL = async () => {
    try {
      setIsLoading(true);
      const res = await getPDFPreSignedUrl(pdfDetails.fileName);
      const response = await fetch(res);

      if (response?.status === 200) {
        setIsError(false);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data: any = reader.result;
          dispatch(
            setPDFData({
              fileName: pdfDetails?.fileName,
              base64Data: base64data,
            })
          );
          setUrl(base64data);
        };
        reader.readAsDataURL(blob);
      } else {
        setIsError(true);
        setUrl("");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error while fetching url", error);
    }
  };

  const openLinksInNewTab = () => {
    const containers = document.querySelectorAll(".rpv-core__annotation--link");
    containers?.forEach((container) => {
      const links = container?.querySelectorAll("a");

      links?.forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          const url = link.getAttribute("href");
          if (url) {
            window.open(url, "_blank");
          }
        });
      });
    });
  };

  const handleJumpToPage = () => {
    jumpToPage(page);
    // jumpToPage(page === 0 ? 2 : page);
  };

  const handleClosePDF = () => {
    document.body.classList.remove("relevant-assets-page"); // Toggle the class on body tag
    dispatch(setRelevantAssetView(RelevantAssetView.None));
    dispatch(
      setPdfDetails({
        assetId: -1,
        fileName: "",
        presignedUrl: "",
        title: "",
        page: 0,
        showOpenIcon: false,
      })
    );
  };

  const handleBtnClick = () => {
    if (btnClick) {
      return btnClick();
    } else if (
      relevantAssetView === RelevantAssetView.PDF &&
      relevantAssetViewType === ViewType.LIST &&
      handleNavigateContentById
    ) {
      return handleNavigateContentById(pdfDetails?.assetId || -1, false);
    } else {
      return dispatch(setRelevantAssetView(RelevantAssetView.PDFInfo));
    }
  };

  const isViewDetailsBtnDisable = () => {
    let isDisable = false;
    if ([String(FromWhere.RelevantAsset)].includes(fromWhere || "")) {
      isDisable = false;
    } else if (!isEmpty(content) && content?.assetStatus !== AssetUploadStatus.COMPLETED) {
      isDisable = true;
    }

    return isDisable;
  };

  return (
    <div className="pdfDetailsContainer">
      <div className="pdfHeaderContainer">
        <div className="pdfHeaderSubContainer">
          <ZoomIn>
            {(props) => (
              <Button className="icon-btn" onClick={props.onClick} type="text" shape="circle">
                <i className="ri-zoom-in-line"></i>
              </Button>
            )}
          </ZoomIn>
          <CurrentScale />
          <ZoomOut>
            {(props) => (
              <Button className="icon-btn" onClick={props.onClick} type="text" shape="circle">
                <i className="ri-zoom-out-line"></i>
              </Button>
            )}
          </ZoomOut>
        </div>

        <div className="page-navigation-icon-wrap">
          <GoToFirstPage>
            {(props) => (
              <Button
                className="icon-btn"
                onClick={props.onClick}
                disabled={props.isDisabled || page === 0}
                type="text"
                shape="circle">
                <i className="ri-arrow-left-double-line"></i>
              </Button>
            )}
          </GoToFirstPage>

          <GoToPreviousPage>
            {(props) => {
              return (
                <Button
                  className="icon-btn"
                  onClick={props.onClick}
                  // disabled={props.isDisabled}
                  disabled={page === 0}
                  type="text"
                  shape="circle">
                  <i className="ri-arrow-left-s-line"></i>
                </Button>
              );
            }}
          </GoToPreviousPage>

          <CurrentPageLabel>
            {(props) => (
              <div className="Current-page-label">{`Page ${props.currentPage + 1} of ${
                props.numberOfPages
              }`}</div>
            )}
          </CurrentPageLabel>

          <GoToNextPage>
            {(props) => (
              <Button
                className="icon-btn"
                onClick={props.onClick}
                disabled={props.isDisabled}
                type="text"
                shape="circle">
                <i className="ri-arrow-right-s-line"></i>
              </Button>
            )}
          </GoToNextPage>

          <GoToLastPage>
            {(props) => (
              <Button
                className="icon-btn"
                onClick={props.onClick}
                disabled={props.isDisabled}
                type="text"
                shape="circle">
                <i className="ri-arrow-right-double-line"></i>
              </Button>
            )}
          </GoToLastPage>
        </div>

        <div className="asset-close-pdf-wrap">
          {isChatButton && <ContentChatButton isVisible={isChatButton} content={content} />}
          {isRelevantAsset && (
            <Button
              type="default"
              className="btn view-asset-btn"
              onClick={handleBtnClick}
              disabled={isViewDetailsBtnDisable()}>
              {btnText}
            </Button>
          )}
          {isShowCustomCloseIcon && (
            <Button type="text" className="btn close-pdf-btn" onClick={handleClosePDF}>
              <i className="ri-close-fill"></i>
            </Button>
          )}
        </div>
      </div>

      {isLoading && (
        <div className="loading-pdf">
          <LoadingPdf />
        </div>
      )}
      {isError ? <div className="error-document">Asset not found. Please try again</div> : <></>}
      {url && !isError ? (
        <Worker workerUrl={GlobalWorkerOptions.workerSrc}>
          <Viewer
            fileUrl={url}
            defaultScale={SpecialZoomLevel.PageWidth}
            onDocumentLoad={() => {
              setTimeout(() => {
                openLinksInNewTab();
              }, 1000);
              handleJumpToPage();
            }}
            plugins={[
              toolbarPluginInstance,
              pageNavigationPluginInstance,
              zoomPluginInstance,
              jumpToPagePluginInstance,
              searchPluginInstance,
              defaultLayoutPluginInstance,
            ]}
          />
        </Worker>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PDFViewer;
