import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Flex, Form, Input } from "antd";

import { RootState } from "../../../store";
import { resetPassword } from "../../../services/authApi";
import { PATHS } from "../../../shared";
import "./ResetPassword.scss";
import { DeleteModal } from "../../../components";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { userId } = useSelector((state: RootState) => state.auth);
  const { resetPasswordLoading } = useSelector((state: RootState) => state.auth);

  const [isShowWarnModal, setIsShowWarnModal] = useState<boolean>(false);

  // useLayoutEffect(() => {
  //   if (userId < 0) {
  //     navigate(PATHS.login);
  //     window.location.reload();
  //   }
  // }, [userId]);

  const handleResetPassword = () => {
    const values = form.getFieldsValue();
    resetPassword({ ...values, userId }, navigate);
    setIsShowWarnModal(false);
  };

  const onFinish = () => {
    setIsShowWarnModal(true);
  };

  // const onFinish = (values: {
  //   oldPassword: string;
  //   newPassword: string;
  //   confirmPassword: string;
  // }) => {
  //   resetPassword({ ...values, userId }, navigate);
  // };
  // const handlePasswordchange = (password: string) => {
  //   form.setFieldsValue({ password: password });
  //   setForm({ ...resetPasswordForm, password });
  // };

  const handleBackClick = () => {
    return userId ? navigate(-1) : navigate(PATHS.login);
  };

  return (
    <>
      <Flex className="loginForm" align="center" justify="center">
        <div className="loginFormInner">
          <div id="fp-step-2" className="card-inner">
            <h3 className="box-title">Set a new password</h3>
            <p className="short-text">
              Your new password must be different to previously used passwords.
            </p>
            <Form
              name="login"
              form={form}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              layout="vertical"
              requiredMark={false}
              size="large">
              <Form.Item
                label="Password"
                name="oldPassword"
                className="InputPassword"
                rules={[{ required: true, message: "Please input your password" }]}>
                <Input.Password placeholder="" />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                className="InputPassword"
                rules={[{ required: true, message: "Please input your new password" }]}>
                <Input.Password placeholder="" />
              </Form.Item>
              <Form.Item
                label="Confirm password"
                name="confirmPassword"
                className="InputPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password again",
                  },
                ]}>
                <Input.Password placeholder="" />
              </Form.Item>
              <Form.Item className="loginBtn">
                <Button
                  loading={resetPasswordLoading}
                  className="primary-btn mt-20 mb-20"
                  type="primary"
                  htmlType="submit"
                  block>
                  Update Password
                </Button>
              </Form.Item>
            </Form>
            <div className="back-btn d-flex d-flex-middle d-flex-center">
              <Button
                className="back-to-log d-flex d-flex-middle"
                type="default"
                htmlType="submit"
                onClick={handleBackClick}
                icon={<i className="ri-arrow-left-s-line"></i>}>
                {userId ? "Back" : "Back to log in"}
              </Button>
            </div>
          </div>
        </div>
      </Flex>

      {isShowWarnModal && (
        <DeleteModal
          isLoading={resetPasswordLoading}
          isModalOpen={isShowWarnModal}
          handleCancel={() => setIsShowWarnModal(false)}
          handleSubmit={() => handleResetPassword()}
          message="Updating your password will log you out of the application."
          title="Update Password"
          submitBtnName="Proceed"
        />
      )}
    </>
  );
};

export default ResetPassword;
