import { FC } from "react";
import { Card, Col, Row, Skeleton, Table } from "antd";
import "./TableSkeleton.scss";

interface ITableSkeletonProps {
  rows?: number;
}

const TableSkeleton: FC<ITableSkeletonProps> = ({rows = 8}) => {
  const columns = [
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "name",
      key: "name",
      width: "auto",
      render: () => <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />,
    },
    {
      title: <Skeleton.Input style={{ width: "75%", minWidth: "75%", height: 14 }} active={true} />,
      dataIndex: "email",
      key: "email",
      width: "350px",
      render: () => (
        <Skeleton.Input style={{ width: "75%", minWidth: "75%", height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "role",
      key: "role",
      width: "20%",
      render: () => <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />,
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "role",
      key: "role",
      width: "100",
      render: () => <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />,
    },
    {
      title: <Skeleton.Input style={{ width: 50, minWidth: 50, height: 14 }} active={true} />,
      key: "action",
      width: "100px",
      render: () => (
        <Skeleton.Input style={{ width: 50, minWidth: 50, height: 22 }} active={true} />
      ),
    },
  ];

  const generateDataSource = (): any[] => {
    const dataSource = [...Array(rows).map(()=>{})];
    return dataSource;
  }

  const dataSource = generateDataSource();

  return (
    <div className="page-content top-container">
      <Row>
        <Col className="global-table-card table-ui table-skeleton" span={24}>
          <Card>
            <Table
              className="global-table responsive-table responsive-table-user-management"
              columns={columns}
              dataSource={dataSource}
              tableLayout="fixed"
              scroll={{ y: "calc(100vh - 315px)" }}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TableSkeleton;
