import { get } from "./apiClients";
import { store } from "../store";
import {
  setHomeBookmarkedContent,
  setIsHomeBookmarkedContentLoading,
  setIsOthersLookingAssetsLoading,
  setIsRecentlyViewedAssetsLoading,
  setOthersLookingAssets,
  setRecentlyViewedAssets,
} from "../store/home/homeSlice";

export const getHomeBookmarkedContent = async (isLoading: boolean) => {
  try {
    isLoading && store.dispatch(setIsHomeBookmarkedContentLoading(true));

    const res = await get(`/core/asset`, {
      params: {
        is_bookmarked: true,
      },
    });
    store.dispatch(setHomeBookmarkedContent(res ?? []));
  } catch (error) {
    store.dispatch(setHomeBookmarkedContent([]));
    return error;
  } finally {
    store.dispatch(setIsHomeBookmarkedContentLoading(false));
  }
};

export const getRecentlyViewedAssets = async () => {
  try {
    store.dispatch(setIsRecentlyViewedAssetsLoading(true));
    const res = await get(`/core/asset/my-view-history`);
    store.dispatch(setRecentlyViewedAssets(res));
  } catch (error: any) {
    console.log(error, "getRecentlyViewedAssets api error");
    store.dispatch(setRecentlyViewedAssets([]));
  } finally {
    store.dispatch(setIsRecentlyViewedAssetsLoading(false));
  }
};

export const getOthersLookingAssets = async () => {
  try {
    store.dispatch(setIsOthersLookingAssetsLoading(true));
    const res = await get(`/core/asset/view-history`);
    store.dispatch(setOthersLookingAssets(res));
  } catch (error: any) {
    console.log(error, "getOthersLookingAssets api error");
    store.dispatch(setOthersLookingAssets([]));
  } finally {
    store.dispatch(setIsOthersLookingAssetsLoading(false));
  }
};
