import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { RootState } from "../../../store";
import { setAppLayoutview } from "../../../store/appState/appSlice";
import { AppLayout, themeInitValue } from "../../constants";
import "./ChatFloatingBtn.scss";

const ChatFloatingBtn = () => {
  const dispatch = useDispatch();
  const { appLayoutView } = useSelector((state: RootState) => state.app);
  const { appearance } = useSelector((state: RootState) => state.app);

  const [bodyClass, setBodyClass] = useState(appLayoutView || AppLayout.MINIMIZE);
  const defaultAssistantLogo = themeInitValue?.assistantLogo?.url;
  const assistantLogo = appearance?.assistantLogo?.url || defaultAssistantLogo;

  useEffect(() => {
    document.body.classList.add(bodyClass);
    return () => {
      document.body.classList.remove(bodyClass);
    };
  }, [bodyClass]);

  const toggleChat = () => {
    dispatch(setAppLayoutview(AppLayout.MINIMIZE));
    setBodyClass(AppLayout.MINIMIZE);
  };

  return appLayoutView !== AppLayout.MINIMIZE ? (
    <Button type="text" id="chatToggle" onClick={toggleChat}>
      {/* <img src={IMAGES.waveImage} alt="Wave" className="wave" /> */}
      <span className="wrap">
        <img
          src={assistantLogo}
          alt="Assistant Logo"
          className="octopus"
          onError={(e) => (e.currentTarget.src = defaultAssistantLogo)}
        />
      </span>
    </Button>
  ) : (
    ""
  );
};

export default ChatFloatingBtn;
