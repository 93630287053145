import { FC } from "react";
import { formatBytes, renderFileTypeIcon } from "../../../shared";
import { IContent } from "../../../store/contentManagement/content.interface";

export const ConversationFileCard: FC<{ file: IContent; isPublishInQueue?: boolean }> = ({
  file,
  isPublishInQueue,
}) => {
  return (
    <div className="conversation-list conversation-file-attachment">
      {isPublishInQueue ? (
        ""
      ) : (
        <div className="chat-avatar">
          <div className="user-icon">
            <i className="ri-user-fill"></i>
          </div>
        </div>
      )}
      <div className="file-attachment position-relative">
        <div className="file-attachment-item">
          <div className="file-attachment-info">
            <div className="file-attachment-icon">
              <img src={renderFileTypeIcon(file.file_type)} width="32" />
            </div>
            <div className="file-attachment-content">
              <h4 className="file-attachment-title">{file.original_file_name}</h4>
              <span className="file-size">{formatBytes(file.size || 0)}</span>
            </div>
          </div>
          <div className="file-attachment-action">
            {/* Uploading... */}
            {/* Close Icon */}
            {/* <span className="span-icon close-span">
          <i className="ri-close-fill"></i>
        </span> */}
            {/* Delete Icon */}
            {/* <span hidden className="span-icon delete-span">
          <i className="ri-delete-bin-line"></i>
        </span> */}
          </div>
        </div>
        {/* <Progress percent={70} showInfo={false} /> */}
      </div>
    </div>
  );
};
