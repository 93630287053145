import { ChangeEvent, FC, useRef, useState } from "react";
import { Button, Form, Modal, Progress } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../../store";
import { setFileUploadProgress } from "../../../store/userManagement/usersSlice";
import { uploadProfileResume } from "../../../services/userManagement";
import { isEmpty, UploadResumeState } from "../../../shared";
import "./UploadResume.scss";

interface IUploadResumeForm {
  setIsOpen: Function;
  isOpen: boolean;
}

const UploadResumeForm: FC<IUploadResumeForm> = (props) => {
  const { setIsOpen, isOpen } = props;
  const dispatch = useDispatch();
  const [uploadForm] = Form.useForm();
  const { fileUploadProgress, isResumeUploading } = useSelector((state: RootState) => state.user);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [uploadingState, setUploadingState] = useState<UploadResumeState>(
    UploadResumeState.INITIAL
  );

  const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    fileUploadProgress && dispatch(setFileUploadProgress(0));

    if (e.target.files) {
      const file = e.target.files[0];

      setUploadingState(UploadResumeState.FILE);
      setFile(file);
      setFileName(file.name);
    }
    e.target.value = "";
  };

  const handleFileUpload = async () => {
    if (file) {
      const fileType = file?.name?.substring(file?.name.lastIndexOf(".") + 1);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileContentType", fileType);
      toast.warn("Uploading your resume may take a moment. Please wait");

      const res = await uploadProfileResume(formData, setUploadingState);
      if (!isEmpty(res)) {
        handleCloseResumeModal();
      }
    } else {
      setUploadingState(UploadResumeState.FAILED);
      toast.error("Failed to upload");
    }
  };

  const handleRemoveFile = () => {
    uploadForm.resetFields();
    setUploadingState(UploadResumeState.INITIAL);
    setFile(null);
    setFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleCloseResumeModal = () => {
    setIsOpen(false);
    handleRemoveFile();
  };

  const handleClickUploadFile = () => {
    if (fileInputRef.current != null) {
      fileInputRef.current.click();
    }
  };

  const renderAsserUploadingState = (fileName: string, fileUploadProgress: number) => {
    return (
      <div className="uploading-field">
        <p className="upload-status">
          {uploadingState === UploadResumeState.FILE
            ? "Selected file"
            : uploadingState === UploadResumeState.UPLOADING
            ? "Uploading..."
            : uploadingState === UploadResumeState.FAILED
            ? "Upload Failed"
            : "Uploaded"}
        </p>
        <div className="file-container">
          <span>
            <i className="ri-attachment-line browseIcon"></i>
            <span>{fileName}</span>
          </span>
          {uploadingState === UploadResumeState.FILE ? (
            <div className="btn-wrapper">
              <Button type="text" className="text-btn remove-file" onClick={handleRemoveFile}>
                <i className="ri-delete-bin-fill"></i>
              </Button>
            </div>
          ) : (
            <></>
          )}

          {uploadingState !== UploadResumeState.UPLOADING ? (
            <Progress percent={fileUploadProgress} />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      width={"40%"}
      open={isOpen}
      title={"Upload Resume"}
      className="add-exp-modal upload-resume-modal"
      footer={false}
      onCancel={handleCloseResumeModal}
      centered>
      <Form form={uploadForm} name="uploadResume" className="block-style" layout="vertical">
        <p className="replaced-text">
          Provide your resume below to pre-fill your profile. Existing data in the form will be
          replaced.
        </p>
        <div className="form-item-col">
          <p className="input-label">Upload</p>
          <div>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple={false}
              placeholder="Upload Asset"
              onChange={handleSelectFile}
              accept=".pdf"
            />
            <div className="upload-file-container">
              <div className="file-name">
                <span>
                  <i className="ri-attachment-line browseIcon"></i>
                  <span>Browse</span>
                </span>
              </div>

              <Button
                onClick={handleClickUploadFile}
                className="btn-primary fill-button btn-type2"
                type="primary"
                size="middle"
                disabled={isResumeUploading}>
                Browse
              </Button>
            </div>
            <p>Upload in PDF format only.</p>
          </div>
        </div>

        {uploadingState === UploadResumeState.FILE ||
        uploadingState === UploadResumeState.UPLOADING ||
        uploadingState === UploadResumeState.UPLOADED ||
        uploadingState === UploadResumeState.FAILED ? (
          <>{renderAsserUploadingState(fileName, fileUploadProgress)}</>
        ) : (
          ""
        )}

        <div className="submit-wrap">
          <Button className="btn" type="default" onClick={handleCloseResumeModal}>
            Cancel
          </Button>

          <Button
            className="btn"
            type="primary"
            htmlType="submit"
            onClick={handleFileUpload}
            loading={isResumeUploading}
            disabled={uploadingState === UploadResumeState.INITIAL || isResumeUploading}>
            Upload
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default UploadResumeForm;
