import { useState } from "react";
import { Button, Card, Flex, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import "./profileDetails.scss";
import { RootState } from "../../store";
import { ContentChatButton, PageHeader } from "../../components";
import { getFileType, onDownloadPdf, PDFViewer } from "../../shared";
import { contentByIdInitialValue } from "../../store/contentManagement/contentSlice";

const ProfileDetails = () => {
  const [isPdfDownloading, setIsPdfDownloading] = useState(false);

  const { profileDetails } = useSelector((state: RootState) => state.profile);

  return (
    <>
      <Flex className="admin-inner-wrap access-group-page" vertical>
        <PageHeader
          title="Profile Preview"
          isRelevantAsset={false}
          content={
            <Link to={"/profile"}>
              <Button
                type="text"
                className="page-close-btn"
                icon={<i className="ri-close-fill"></i>}
              />
            </Link>
          }
        />

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}>
          <Card className="profile-details-card">
            <div className="page-scroll">
              <Card className="profile-details-card-inner">
                <PDFViewer isRelevantAsset={false} />
                <div className="profile-details-text">
                  <div className="ant-tabs-extra-content">
                    {/* <Button
                      type="text"
                      className="text-btn"
                      icon={<i className="ri-message-3-line" />}>
                      Chat
                    </Button> */}

                    <Tooltip
                      placement="topRight"
                      trigger="hover"
                      title="Chat with this document"
                      rootClassName="start-chat-tooltip">
                      <ContentChatButton
                        {...{
                          content: {
                            ...contentByIdInitialValue,
                            title: profileDetails?.file_name,
                            asset_id: profileDetails?.asset_id,
                            file_name: profileDetails?.file_name,
                            assetType: getFileType(profileDetails?.file_name),
                          },
                          isVisible: true,
                        }}
                      />
                    </Tooltip>
                    <Button
                      type="text"
                      className="text-btn"
                      loading={isPdfDownloading}
                      icon={
                        !isPdfDownloading && (
                          <i
                            className="ci-small-download-icon ci icon-link"
                            onClick={() =>
                              onDownloadPdf(
                                profileDetails?.pre_signed_url,
                                setIsPdfDownloading,
                                profileDetails?.name
                              )
                            }
                          />
                        )
                      }>
                      Download
                    </Button>
                  </div>
                  <h2 className="wrapper-title">{profileDetails?.name}</h2>
                  <p>{profileDetails?.why_resume}</p>
                </div>
              </Card>
            </div>
          </Card>
        </motion.div>
      </Flex>
    </>
  );
};

export default ProfileDetails;
