import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { Card, ColorPicker } from "antd";
import { Color } from "antd/es/color-picker";
import { IAppearanceProps } from "../../../../store/appState/app.interface";
import { RootState } from "../../../../store";

interface IColorForm {
  localThemeConfig: any;
  setLocalThemeConfig: Dispatch<SetStateAction<IAppearanceProps>>;
}

const ColorForm = (props: IColorForm) => {
  const { localThemeConfig, setLocalThemeConfig } = props;
  const { isGetThemeLoading, isUpdateThemeLoading } = useSelector((state: RootState) => state.app);
  const isDisable = isUpdateThemeLoading || isGetThemeLoading;

  const handleColorChange = (_: Color, css: string, name: string) => {
    setLocalThemeConfig({ ...localThemeConfig, [`${name}`]: css });
  };

  return (
    <>
      <Card className="theme-inner">
        <h3>Select Color*</h3>
        <div className="card-list">
          <Card className="card-item">
            <label className="item-label">Primary Color</label>
            <div className="browse-wrap align-select-color d-flex d-flex-middle ">
              <div className="color-code d-flex d-flex-middle">
                <ColorPicker
                  value={localThemeConfig?.primaryColor}
                  onChange={(color, css) => handleColorChange(color, css, "primaryColor")}
                  format="hex"
                  showText
                  disabled={isDisable}
                />
              </div>
            </div>
          </Card>

          <Card className="card-item">
            <label className="item-label">Secondary Color</label>
            <div className="browse-wrap align-select-color d-flex d-flex-middle">
              <div className="color-code d-flex d-flex-middle">
                <ColorPicker
                  value={localThemeConfig?.secondaryColor}
                  onChange={(color, css) => handleColorChange(color, css, "secondaryColor")}
                  format="hex"
                  showText
                  disabled={isDisable}
                />
              </div>
            </div>
          </Card>

          <Card className="card-item">
            <label className="item-label">Tertiary Color</label>
            <div className="browse-wrap align-select-color d-flex d-flex-middle">
              <div className="color-code d-flex d-flex-middle">
                <ColorPicker
                  value={localThemeConfig?.tertiaryColor}
                  onChange={(color, css) => handleColorChange(color, css, "tertiaryColor")}
                  format="hex"
                  showText
                  disabled={isDisable}
                />
              </div>
            </div>
          </Card>

          <Card className="card-item" hidden={true}>
            <label className="item-label">Hover Color</label>
            <div className="browse-wrap align-select-color d-flex d-flex-middle">
              <div className="color-code d-flex d-flex-middle">
                <ColorPicker
                  value={localThemeConfig?.hoverColor}
                  onChange={(color, css) => handleColorChange(color, css, "hoverColor")}
                  format="hex"
                  showText
                  disabled={isDisable}
                />
              </div>
            </div>
          </Card>

          <Card className="card-item" hidden={true}>
            <label className="item-label">Text Color</label>
            <div className="browse-wrap align-select-color d-flex d-flex-middle">
              <div className="color-code d-flex d-flex-middle">
                <ColorPicker
                  value={localThemeConfig?.textColor}
                  onChange={(color, css) => handleColorChange(color, css, "textColor")}
                  format="hex"
                  showText
                  disabled={isDisable}
                />
              </div>
              {/* <div className="color-code-input" style={{ width: "100%" }}>
                      <Input placeholder="Enter color code" />
                    </div> */}
            </div>
          </Card>
        </div>
      </Card>
    </>
  );
};

export default ColorForm;
