import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ICurrentLevel, ITaxonomyNode, TFilters, TFiltersType } from "./filters.interface";
import { PURGE } from "redux-persist";

export const taxonomyByInitValue: ITaxonomyNode = {
  taxonomyNodeId: -1,
  description: "",
  taxonomyNodeLevel: 0,
  taxonomyNodeName: "",
  linkedAsset: [],
  children: [],
};
const initialState: TFilters = {
  selectedIndustry: [],
  selectedFunctions: [],
  selectedFileType: [],
  selectedAssetType: [],
  selectedDateRange: [],
  isFiltersDataLoading: false,
  browseCategoryFiltersData: null,
  browseCategoryCurrentLevel: [],
  browseCategorySelectedCategory: taxonomyByInitValue,
  browseCategorySelectedFunctionValue: "Select Function",
  browseCategorySelectedIndustryValue: "Select Industry",
  browseCategorySelectedRadioOption: TFiltersType.Functions,
};

export const filtersSlice = createSlice({
  name: "filtersSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setFiltersData: (state, action: PayloadAction<Array<{ type: string; data: string[] }>>) => {
      action.payload.forEach((element) => {
        const keys = element.data;

        if (element.type === TFiltersType.Industry) {
          state.selectedIndustry = keys;
        }
        if (element.type === TFiltersType.Functions) {
          state.selectedFunctions = keys;
        }
        if (element.type === TFiltersType.AssetType) {
          state.selectedAssetType = keys;
        }
        if (element.type === TFiltersType.FileType) {
          state.selectedFileType = keys;
        }
      });
    },
    setFilterDateRange: (state, action: PayloadAction<Array<string>>) => {
      state.selectedDateRange = action.payload;
    },
    removeFilterData: (state, action: PayloadAction<[]>) => {
      state.selectedIndustry = action.payload;
      state.selectedFunctions = action.payload;
      state.selectedFileType = action.payload;
      state.selectedAssetType = action.payload;
      state.selectedDateRange = action.payload;
    },
    setAllAssetTypesSelected: (state, action: PayloadAction<Array<string>>) => {
      state.selectedAssetType = action.payload;
    },
    setAllFileTypesSelected: (
      state,
      action: PayloadAction<Array<{ title: string; key: string }>>
    ) => {
      const data = action.payload.map((item) => item.key);
      state.selectedFileType = data;
    },
    setFiltersDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isFiltersDataLoading = action.payload;
    },
    setBrowseCategoryFiltersData: (state, action: PayloadAction<ITaxonomyNode | null>) => {
      state.browseCategoryFiltersData = action.payload;
    },
    setBrowseCategoryCurrentLevel: (state, action: PayloadAction<ICurrentLevel[]>) => {
      state.browseCategoryCurrentLevel = action.payload;
    },
    setBrowseCategorySelectedCategory: (state, action: PayloadAction<ITaxonomyNode>) => {
      state.browseCategorySelectedCategory = action.payload;
    },
    setBrowseCategorySelectedFunctionValue: (state, action: PayloadAction<string>) => {
      state.browseCategorySelectedFunctionValue = action.payload;
    },
    setBrowseCategorySelectedIndustryValue: (state, action: PayloadAction<string>) => {
      state.browseCategorySelectedIndustryValue = action.payload;
    },
    setBrowseCategorySelectedRadioOption: (state, action: PayloadAction<string>) => {
      state.browseCategorySelectedRadioOption = action.payload;
    },
    setResetBrowseCategorySelection: (state, action: PayloadAction<[]>) => {
      state.browseCategorySelectedCategory= taxonomyByInitValue
      state.browseCategoryCurrentLevel = action.payload

    },
  },
});

export const {
  setFiltersData,
  setFilterDateRange,
  removeFilterData,
  setAllAssetTypesSelected,
  setAllFileTypesSelected,
  setFiltersDataLoading,
  setBrowseCategoryFiltersData,
  setBrowseCategoryCurrentLevel,
  setBrowseCategorySelectedCategory,
  setBrowseCategorySelectedFunctionValue,
  setBrowseCategorySelectedIndustryValue,
  setBrowseCategorySelectedRadioOption,
  setResetBrowseCategorySelection
} = filtersSlice.actions;

export default filtersSlice.reducer;
