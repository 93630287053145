import { IMAGES } from "../../images";
import "./NoContentData.scss";

const NoContentData = () => {
  return (
    <div className="content-empty-box">
      <div className="empty-wrap">
        <img src={IMAGES.exptyBox} alt="empty-data" />
        <span>No Data Found</span>
      </div>
    </div>
  );
};

export default NoContentData;
