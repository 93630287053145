import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getUserEmail, isEmpty } from "../../shared";

const InfoSection = () => {
  const { userProfile } = useSelector((state: RootState) => state.user);

  return (
    <>
      <div className="user-info">
        <h2 className="user-name">{userProfile?.name}</h2>
        <ul className="main-info">
          <li className="tag">
            <span className="more">
              {!isEmpty(userProfile?.currentRole) ? userProfile?.currentRole : "--"}
            </span>
          </li>

          <li className="email">
            <a href={`mailto:${getUserEmail()}`}>
              <span className="more">
                <u>{getUserEmail()}</u>
              </span>
            </a>
          </li>

          <li>
            {!isEmpty(userProfile?.phoneNumber) ? (
              <a href={`tel:91${userProfile?.phoneNumber}`}>
                <span className="more">
                  <i className="ri-phone-line" />
                  +91 - {userProfile?.phoneNumber}
                </span>
              </a>
            ) : (
              <span className="more">
                <i className="ri-phone-line" />
                --
              </span>
            )}
          </li>
        </ul>
        <ul className="other-info">
          {!isEmpty(userProfile?.location) && (
            <li>
              <i className="ci ci-location-icon" />
              <span>{userProfile?.location}</span>
            </li>
          )}

          {!isEmpty(userProfile?.startDate) && (
            <li hidden={true}>
              <i className="ci ci-calendar-check-icon" />
              <span>{userProfile?.startDate} - Present</span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default InfoSection;
