import { store } from "./../store/store";
import { toast } from "react-toastify";
import { get, post, put } from "./apiClients";
import { IEtags } from "../shared";
import { setPartETags } from "../store/mediaUpload/mediaUploadSlice";

export const initiateMediaUpload = async (
  contentType: string,
  fileName: string
): Promise<string> => {
  try {
    const { uploadId } = await get(`/core/cloud/utils/multipart-upload/initiate`, {
      params: {
        contentType,
        fileName,
      },
    });

    return uploadId;
  } catch (error: any) {
    toast.error(error?.message ?? "Failed to upload file");
    return error;
  }
};

export const uploadFileInChunks = async (
  uploadId: string,
  file: File,
  fileName: string,
  chunkSize: number,
  setUploadProgress: (progress: number) => void
) => {
  const totalChunks = Math.ceil(file.size / chunkSize);
  let uploadedChunks = 0;

  for (let start = 0; start < file.size; start += chunkSize) {
    const chunk = file.slice(start, start + chunkSize);
    const partNumber = uploadedChunks + 1;

    const formData = new FormData();
    formData.append("fileChunk", chunk);

    try {
      const { data } = await put(`core/cloud/utils/multipart-upload/file-chunk`, formData, {
        params: {
          uploadId: uploadId,
          partNumber: String(partNumber),
          fileName: fileName,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const chunkProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            const overallProgress = Math.round(
              ((uploadedChunks + chunkProgress / 100) / totalChunks) * 100
            );

            setUploadProgress(overallProgress);
          } else {
            // Handle cases where progressEvent.total is undefined
            setUploadProgress(Math.round(((uploadedChunks + 1) / totalChunks) * 100));
          }
        },
      });

      uploadedChunks++;
      store.dispatch(setPartETags(data?.partETag));
    } catch (error: any) {
      toast.error(error?.message ?? "Failed to upload file");
      console.error(error);
      break;
    }
  }
};

export const mergeFileChunk = async (request: {
  fileName: string;
  uploadId: string;
  partETags: IEtags[];
}) => {
  try {
    // store.dispatch(setIsContentTableLoading(true));
    const res = await post(`/core/cloud/utils/multipart-upload/complete`, request);
    console.log(res);
    return res;
  } catch (error: any) {
    toast.error(error?.message ?? "Failed to upload file");
    return error;
  } finally {
    // store.dispatch(setIsContentTableLoading(false));
  }
};

export const abortMediaUpload = async (request: { fileName: string; uploadId: string }) => {
  try {
    const res = await post(`/core/cloud/utils/multipart-upload/abort`, request);
    console.log(res);
    return res;
  } catch (error: any) {
    toast.error(error?.message ?? "Failed to upload file");
  }
};
