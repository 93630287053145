import { Button, Tooltip } from "antd";
import "./BookmarkIcon.scss";

interface IBookmarkIcon {
  isActive?: boolean;
  disabled?: boolean;
  className?: string;
}

const BookmarkIcon = ({ isActive = false, disabled = false, className = "" }: IBookmarkIcon) => {
  return (
    <Tooltip placement="top" title="Bookmark" trigger="hover" overlayClassName="tooltip-text">
      <Button type="text" className={`${className} custom-icon bookmark-icon`} disabled={disabled}>
        {isActive ? (
          // <i className="ci-bookmark-icon-check ci"></i>
          <i className="ri-bookmark-fill"></i>
        ) : (
          // <i className="ci-bookmark-icon-uncheck ci"></i>
          <i className="ri-bookmark-line"></i>
        )}
      </Button>
    </Tooltip>
  );
};

export default BookmarkIcon;
