import { ReactNode } from "react";
import { Button, Modal } from "antd";
interface IModal {
  title: string;
  message: string;
  content?: ReactNode;
  isLoading: boolean;
  isModalOpen: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  submitBtnName?: string;
  cancelBtnName?: string;
  headerIcon?: string;
  isShowCancelBtn?: boolean;
  closeIcon?: boolean;
  isBtnPrimary?: boolean;
}

const DeleteModal: React.FC<IModal> = ({
  submitBtnName = "Delete",
  cancelBtnName = "Cancel",
  isShowCancelBtn = true,
  closeIcon = true,
  isBtnPrimary = false,
  ...props
}) => {
  const {
    isLoading,
    isModalOpen,
    handleSubmit,
    handleCancel,
    message,
    content,
    title,
    headerIcon,
  } = props;

  return (
    <Modal
      rootClassName="delete-modal-root"
      title={
        <>
          {headerIcon ? (
            <img src={headerIcon} alt="headerIcon" width={28} className="header-icon-delete" />
          ) : (
            ""
          )}
          {title}
        </>
      }
      centered={true}
      onCancel={handleCancel}
      footer={
        <>
          {isShowCancelBtn && (
            <Button className="btn" onClick={handleCancel}>
              {cancelBtnName}
            </Button>
          )}

          <Button
            className="btn"
            type="primary"
            danger={!isBtnPrimary}
            loading={isLoading}
            onClick={handleSubmit}>
            {submitBtnName}
          </Button>
        </>
      }
      closeIcon={closeIcon}
      open={isModalOpen}>
      <div>{content || message}</div>
    </Modal>
  );
};

export default DeleteModal;
