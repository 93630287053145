import { FC, useState } from "react";
import { Button, Checkbox, Col, GetProp, Radio, RadioChangeEvent, Row, Select } from "antd";
import "./SettingModal.scss";
import { Model } from "../../constants";

interface ISettingModalProps {
  onSubmit?: () => void;
}

const SettingModal: FC<ISettingModalProps> = ({ onSubmit }) => {
  const [aiVersionValue, setAiVersionValue] = useState<string>(Model.GPT4);
  const [aiToolValue, setAiToolValue] = useState<any[]>(["knowledge"]);
  const [aiUseCaseValue, setAiUseCaseValue] = useState<string>("insights");

  const aiVersions = [
    { label: "GPT-3.5", value: Model.GPT3, disabled: true },
    { label: "GPT-4", value: Model.GPT4 },
    { label: "LLAMA", value: Model.LLAMA, disabled: true },
  ];

  const aiTools = [
    { label: "Access API", value: "api", disabled: true },
    { label: "Email", value: "email", disabled: true },
    { label: "Knowledge Retriever", value: "knowledge" },
  ];

  const aiUseCases = [
    { label: "Product Insights", value: "insights" },
    { label: "Proposal", value: "proposal" },
  ];

  const aiVersionChange = ({ target: { value } }: RadioChangeEvent) => {
    setAiVersionValue(value);
  };

  const aiToolsChange: GetProp<typeof Checkbox.Group, "onChange"> = (checkedValues) => {
    setAiToolValue(checkedValues);
  };

  const aiUseCasesChange = (value: string) => {
    setAiUseCaseValue(value);
  };

  const handleSubmit = () => {
    if (onSubmit) {
      return onSubmit();
    }
    return () => {};
  };

  return (
    <>
      <Row className="setting-modal-row">
        <Col span={24}>
          <span className="label">Model</span>
          <Radio.Group
            options={aiVersions}
            onChange={aiVersionChange}
            value={aiVersionValue}
            optionType="button"
            buttonStyle="solid"
          />
        </Col>
        <Col span={24}>
          <span className="label">Tools</span>
          <Checkbox.Group options={aiTools} onChange={aiToolsChange} defaultValue={aiToolValue} />
        </Col>
        <Col span={24}>
          <span className="label">Use Case</span>
          <Select
            popupClassName="ui-dropdown-default"
            value={aiUseCaseValue}
            onChange={aiUseCasesChange}
            options={aiUseCases}
          />
        </Col>
      </Row>
      <div className="setting-footer d-flex d-flex-middle d-flex-right">
        <Button type="link" className="btn" onClick={handleSubmit}>
          Done
        </Button>
      </div>
    </>
  );
};

export default SettingModal;
