import { PURGE } from "redux-persist";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  IAllSources,
  IDataConnectionSliceProps,
  ISharepointSiteDetails,
} from "./dataConnection.interface";

const initialState: IDataConnectionSliceProps = {
  allSources: [],
  isAllSourcesLoading: false,
  isDeleteSourceLoading: false,
  dataConnectionId: -1,
  allSharepointFoldersName: [],
  isConnectDataSourceLoading: false,
  allSharepointSites: [],
  selectedSharepointSite: { site_id: "", site_name: "" },
  isSharepointFoldersLoading: false,
  totalConnectionCount: 0,
  dataConnectionCurrPage: 1,
  isStatusUpdating: false,
};

export const dataConnectionSlice = createSlice({
  name: "dataConnectionSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setIsAllSourcesLoading: (state, action: PayloadAction<boolean>) => {
      state.isAllSourcesLoading = action.payload;
    },

    setAllSources: (state, action: PayloadAction<IAllSources[]>) => {
      state.allSources = action.payload;
    },

    deleteDataConnectionById: (state, action: PayloadAction<number>) => {
      state.allSources = state.allSources.filter((v) => v?.dataConnectionId !== action.payload);
    },

    setIsDeleteSourceLoading: (state, action: PayloadAction<boolean>) => {
      state.isDeleteSourceLoading = action.payload;
    },

    setIsConnectDataSourceLoading: (state, action: PayloadAction<boolean>) => {
      state.isConnectDataSourceLoading = action.payload;
    },

    setDataConnectionId: (state, action: PayloadAction<number>) => {
      state.dataConnectionId = action.payload;
    },

    setAllSharepointFoldersName: (state, action: PayloadAction<string[]>) => {
      state.allSharepointFoldersName = action.payload;
    },

    setAllSharepointSites: (state, action: PayloadAction<ISharepointSiteDetails[]>) => {
      state.allSharepointSites = action.payload;
    },

    setSharepointFoldersLoading: (state, action: PayloadAction<boolean>) => {
      state.isSharepointFoldersLoading = action.payload;
    },

    setTotalConnectionCount: (state, action: PayloadAction<number>) => {
      state.totalConnectionCount = action.payload;
    },

    setDataConnectionCurrPage: (state, action: PayloadAction<number>) => {
      state.dataConnectionCurrPage = action.payload;
    },

    resetDataConnectionFilters: (state) => {
      state.dataConnectionCurrPage = 1;
    },

    setIsStatusUpdating: (state, action: PayloadAction<boolean>) => {
      state.isStatusUpdating = action.payload;
    },
  },
});

export const {
  setAllSources,
  setDataConnectionId,
  setAllSharepointSites,
  setIsAllSourcesLoading,
  setTotalConnectionCount,
  deleteDataConnectionById,
  setIsDeleteSourceLoading,
  setDataConnectionCurrPage,
  setAllSharepointFoldersName,
  resetDataConnectionFilters,
  setSharepointFoldersLoading,
  setIsConnectDataSourceLoading,
  setIsStatusUpdating,
} = dataConnectionSlice.actions;

export default dataConnectionSlice.reducer;
