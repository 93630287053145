import { useNavigate } from "react-router-dom";
import { Button, Card } from "antd";

const ComingSoon = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="error-page">
        <Card className="error-inner happy">
          <h2>Coming Soon</h2>
          <Button type="primary" className="btn" icon={<i className="ri-arrow-left-s-line"></i>} onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </Card>
      </div>
    </>
  );
};

export default ComingSoon;
