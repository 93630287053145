import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IPdfDetails, IPdfViewer } from "./pdfViewer.interface";
import { IContent } from "../contentManagement/content.interface";
import { contentByIdInitialValue } from "../contentManagement/contentSlice";

const initialState: IPdfViewer = {
  pdfData: {},
  pdfDetails: {
    assetId: -1,
    fileName: "",
    presignedUrl: "",
    title: "",
    page: 0,
    showOpenIcon: false,
  },
  isPDFModalOpen: false,
  pdfContent: contentByIdInitialValue,
  isImageVisible: false,
  imageURL: "",
};

interface IPayload {
  base64Data: string;
  fileName: string;
}

export const pdfViewerSlice = createSlice({
  name: "pdfViewerSlice",
  initialState,
  reducers: {
    setPDFData: (state, action: PayloadAction<IPayload>) => {
      const { fileName, base64Data } = action.payload;
      state.pdfData = { ...state.pdfData, [fileName]: base64Data };
    },

    setPdfDetails: (state, action: PayloadAction<IPdfDetails>) => {
      state.pdfDetails = action.payload;
    },

    setIsPDFModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isPDFModalOpen = action.payload;
    },

    setPDFContent: (state, action: PayloadAction<IContent>) => {
      state.pdfContent = action.payload;
    },

    setIsImageVisible: (state, action: PayloadAction<boolean>) => {
      state.isImageVisible = action.payload;
    },

    setImageURL: (state, action: PayloadAction<string>) => {
      state.imageURL = action.payload;
    },
  },
});

export const {
  setPDFData,
  setImageURL,
  setPdfDetails,
  setPDFContent,
  setIsPDFModalOpen,
  setIsImageVisible,
} = pdfViewerSlice.actions;

export default pdfViewerSlice.reducer;
