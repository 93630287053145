import { Link, useLocation, useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { Button, Menu, MenuProps, Popover } from "antd";
import { toast } from "react-toastify";
import { persistStore } from "redux-persist";
import { useSelector, useDispatch } from "react-redux";
import "./SideBar.scss";
import { DeleteModal } from "../deleteModal";
import HeadLogo from "../HeadLogo/HeadLogo";
import { RootState, store } from "../../store";
import { setOldAppearance } from "../../services/appAppearanceApi";
import { clearUserManagementFilter } from "../../store/userManagement/usersSlice";
import { resetAccessGroupFilters } from "../../store/accessGroup/accessGroupSlice";
import { resetDataConnectionFilters } from "../../store/dataConnection/dataConnectionSlice";
import { setIsOpenChatHistory } from "../../store/conversationalSearch/conversationalSearchSlice";
import {
  AppLayout,
  FromWhere,
  Layout,
  PATHS,
  UserRoles,
  getLocalStorageItem,
  getUserId,
} from "../../shared";
import {
  setImageURL,
  setIsImageVisible,
  setIsPDFModalOpen,
  setPDFContent,
} from "../../store/pdfViewer/pdfViewer.slice";
import {
  setAppLayoutview,
  setFindProfileModel,
  setIsShowDeletedUserModal,
  setRoutHitsory,
  setUpdateLayout,
} from "../../store/appState/appSlice";
import {
  clearAdminContentFilter,
  clearContentFilter,
  clearSourceFilter,
  contentByIdInitialValue,
} from "../../store/contentManagement/contentSlice";

type MenuItem = Required<MenuProps>["items"][number];
interface IAdminSideBar {
  sideBarItems: MenuItem[];
}

const SideBar: FC<IAdminSideBar> = ({ sideBarItems }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const location = useLocation();

  const { routeHistory, appLayoutView, isShowDeletedUserModal, layout } = useSelector(
    (state: RootState) => state.app
  );
  const { isPDFModalOpen, isImageVisible } = useSelector((state: RootState) => state.pdfViewer);

  const userId = getUserId();

  const localStorageItem = getLocalStorageItem("user_foundationKM");
  const details: string | undefined = localStorageItem !== null ? localStorageItem : undefined;
  const userDetails = details ? JSON.parse(details) : {};

  const [open, setOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // toggleMenu
  const [currentActive, setCurrentActive] = useState<string[]>([pathname]);

  useEffect(() => {
    upadteRouteHitsory();
    dispatch(setIsPDFModalOpen(false));
    dispatch(setIsImageVisible(false));
  }, [pathname]);

  useEffect(() => {
    if (!isPDFModalOpen && !isImageVisible) dispatch(setPDFContent(contentByIdInitialValue));
    if (!isImageVisible) dispatch(setImageURL(""));
  }, [isPDFModalOpen, isImageVisible]);

  const upadteRouteHitsory = () => {
    setCurrentActive([pathname]);

    if (routeHistory?.length) {
      if (
        pathname.includes(PATHS.viewEditContentManagement) ||
        pathname.includes(PATHS.adminTaxonomyOverview)
      ) {
        const active = routeHistory[0] !== location.pathname ? routeHistory[0] : routeHistory[1];
        setCurrentActive([active]);
      }
    }

    if (!routeHistory?.length || routeHistory[0] !== location.pathname) {
      if (
        document.body.classList.contains("relevant-assets-page") &&
        location.pathname !== PATHS.relevantAssets
      )
        document.body.classList.remove("relevant-assets-page");
      const newPaths = [location.pathname, ...(routeHistory ? routeHistory : [])];
      if (newPaths.length > 5) {
        newPaths.pop(); // remove the oldest path to keep the array length to 5
      }
      dispatch(setRoutHitsory(newPaths));
    }
  };

  const handlePath = (path: string) => {
    if (path === PATHS.history) {
      dispatch(setIsOpenChatHistory(true));
    } else navigate(path);
  };

  const handleLogout = () => {
    store.dispatch(setIsShowDeletedUserModal(false));
    toast.success("Logout Successfully");
    persistStore(store).purge();
    localStorage.removeItem("user_foundationKM");
    localStorage.removeItem("token_foundationKM");
    indexedDB.deleteDatabase("localforage");
    indexedDB.deleteDatabase("knowledge-management");
    setOldAppearance();
    navigate(PATHS.login);
    dispatch(setFindProfileModel(false));
  };

  const isAdminFlow = () =>
    pathname.includes(PATHS.admin) ||
    (location?.state && location?.state?.fromWhere === FromWhere.AdminContentManagement);

  const renderAdminBtn = () => {
    const btnIcon: string = isAdminFlow() ? "ci ci-user-management" : "ci ci-team-management";
    const btnText: string = isAdminFlow() ? "User" : "Admin";
    const btnPath: PATHS = isAdminFlow() ? PATHS.home : PATHS.adminAccessGroup;
    const newLayout: string = isAdminFlow() ? Layout.User : Layout.Admin;

    const handleBtnClick = () => {
      handlePath(btnPath);
      dispatch(setUpdateLayout(newLayout));
    };

    return (
      <Button icon={<i className={btnIcon}></i>} className="admin-menu" onClick={handleBtnClick}>
        {btnText}
      </Button>
    );
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle("sidebar-filter-open"); // Toggle the class on body tag
  };

  const clearScreenFilters = () => {
    dispatch(clearContentFilter());
    dispatch(clearSourceFilter());
    dispatch(clearAdminContentFilter());
    dispatch(resetAccessGroupFilters());
    dispatch(clearUserManagementFilter());
    dispatch(resetDataConnectionFilters());
  };

  // AI Assistant Button End
  const handleClickSidebarItem: MenuProps["onClick"] = (e: any) => {
    if (e?.key !== pathname) {
      handlePath(e?.key);
      clearScreenFilters();

      if (appLayoutView === AppLayout.FULL) {
        dispatch(setAppLayoutview(AppLayout.MINIMIZE));
      }
    }
  };

  const isLogoClickable = () => layout === Layout.Admin && isAdminFlow();
  const handleLogoClick = () => {
    if (isLogoClickable()) {
      navigate(PATHS.home);
      dispatch(setUpdateLayout(Layout.User));
    }
  };

  // const handleMyProfileClick = () => {
  //   setOpen(false);
  //   navigate(PATHS.userProfile, { state: { profileUserId: getUserId() } });
  // };

  return (
    <>
      <div id="sidebar" className="sidebar">
        <span className="sidebar-overlay" onClick={toggleMenu}></span>
        <div className="sidebar-wrap">
          <div className="mobileView toggle-click" onClick={toggleMenu}>
            <div className="arrow-list">
              <i className="ri-menu-2-fill"></i>
            </div>
            {/* Admin Menu */}
          </div>

          <div className="top-bar">
            <div className="admin-logo d-flex d-flex-center d-flex-middle">
              <div className={`${isLogoClickable() && "cursor-pointer"}`} onClick={handleLogoClick}>
                <HeadLogo type="sm" />
              </div>
            </div>
            <div className="side-nav-wrap">
              <Menu
                rootClassName="pop-menu-wrap"
                selectedKeys={currentActive}
                items={sideBarItems}
                onClick={handleClickSidebarItem}
              />
            </div>
          </div>

          <div className="bottom-menu d-flex d-flex-center  d-flex-wrap">
            {userDetails.userRole === UserRoles.Admin && renderAdminBtn()}
            <Popover
              overlayClassName="account-setting-menu"
              content={
                <ul className="account-setting-content">
                  {/* <li>
                    <Button type="text" onClick={handleMyProfileClick}>
                      My Profile
                    </Button>
                  </li> */}
                  <li>
                    <Link to={PATHS.resetPassword}>
                      <Button type="text" onClick={() => setOpen(false)}>
                        Reset Password
                      </Button>
                    </Link>
                  </li>
                  <li>
                    <Button type="text" onClick={() => handleLogout()}>
                      Logout
                    </Button>
                  </li>
                </ul>
              }
              trigger="click"
              open={open}
              placement="leftBottom"
              rootClassName="popover-menu-list"
              onOpenChange={() => setOpen(!open)}>
              <div className="user-icon" onClick={() => setOpen(true)}>
                <i className="ri-user-fill"></i>
              </div>
            </Popover>
          </div>
        </div>
      </div>

      {isShowDeletedUserModal && userId && (
        <DeleteModal
          isLoading={false}
          isModalOpen={isShowDeletedUserModal}
          handleSubmit={handleLogout}
          title="Your account has been removed."
          message="Please contact your administrator for further assistance."
          handleCancel={() => dispatch(setIsShowDeletedUserModal(true))}
          isShowCancelBtn={false}
          submitBtnName="Logout"
          closeIcon={false}
          isBtnPrimary={true}
        />
      )}
    </>
  );
};

export default SideBar;
