import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Modal, Skeleton, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./VideoAudioPLayerModal.scss";
import { PATHS } from "../../constants";
import MediaPlayer from "../../VideoPLayer";
import { AudioPlayer } from "../AudioPLayer.tsx";
import { RootState } from "../../../store/store.ts";
import { ContentChatButton } from "../../../components";
import { isFileTypeAudio, renderTimeStamp } from "../../helpers";
import { IContent } from "../../../store/contentManagement/content.interface";
import { getAssetDetailsByAssetId, updateViewCount } from "../../../services/contentManagement";
import {
  isShowVideoPLayerModal,
  resetMediaUploadState,
  setVideoTime,
} from "../../../store/mediaUpload/mediaUploadSlice";

interface IUploadAssets {
  isModalOpen: boolean;
  content: IContent | null;
}

const renderModalContent = (content: IContent | null) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const videoPLayerRef = useRef<HTMLVideoElement | null>(null);

  const { isCitation } = useSelector((state: RootState) => state.mediaUploadSlice);

  const [isContentLoading, setIsContentLoading] = useState(false);

  useEffect(() => {
    if (isCitation) {
      const hetAssetDetails = async () => {
        setIsContentLoading(true);
        let res = await getAssetDetailsByAssetId(content?.asset_id ?? 0);
        console.log(res);
        dispatch(isShowVideoPLayerModal({ content: res, isOpen: true, isCitation: false }));
        setIsContentLoading(false);
      };

      hetAssetDetails();
    }
  }, [isCitation]);

  useEffect(() => {
    return () => {
      closeModal();
    };
  }, []);

  const closeModal = () => {
    dispatch(isShowVideoPLayerModal({ content: null, isOpen: false, isCitation: false }));
    dispatch(resetMediaUploadState());

    if (videoPLayerRef.current) {
      videoPLayerRef.current.pause();
      videoPLayerRef.current.currentTime = 0;
      videoPLayerRef.current.src = ""; // Unload video
    }

    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset to start
      audioRef.current = null;
    }
  };

  const handleDetailPage = () => {
    content?.asset_id && updateViewCount(content?.asset_id);
    navigate(`${PATHS.viewEditContentManagement}/${content?.asset_id}`, {
      state: {
        isEditMode: false,
        isReadOnly: true,
      },
    });
    closeModal();
  };

  const renderMediaPlayer = () => {
    if (isContentLoading) {
      return (
        <div className="media-content-loading">
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </div>
      );
    } else {
      if (!!content?.file_type) {
        if (isFileTypeAudio(content?.file_type)) {
          return (
            <AudioPlayer
              src={content?.pre_signed_url ?? ""}
              ref={audioRef}
              detailPageView={false}
            />
          );
        }
        return (
          <MediaPlayer
            presignedUrl={content?.pre_signed_url ?? ""}
            ref={videoPLayerRef}
            id="modal"
          />
        );
      }
      return <div className="invalid-media">Invalid File Format</div>;
    }
  };

  const renderTranscript = () => {
    if (isContentLoading) {
      return (
        <div className="media-content-loading">
          <Skeleton active paragraph={{ rows: 20 }} />
        </div>
      );
    } else {
      return (
        <div className="timestamp-wrap">
          {!!content?.transcript.length &&
            content?.transcript?.map((v) => (
              <div className="timestamp-text-wrap" key={uuidv4()}>
                <div
                  className="timestamp-text"
                  onClick={() => {
                    dispatch(setVideoTime(v?.start_time));
                    if (videoPLayerRef.current === null) {
                      videoPLayerRef.current = document.getElementById("modal") as HTMLVideoElement;
                    }
                  }}>
                  <i className="ri-play-fill" />
                  {renderTimeStamp(v?.start_time)}
                </div>
                <div>{v?.text}</div>
              </div>
            ))}
        </div>
      );
    }
  };

  return (
    <div className="timestamp-header-wrap">
      <div className="timestamp-header">
        {content?.title}
        <div className="timestamp-header-btn-container">
          {content && <ContentChatButton isVisible={true} content={content} />}
          {!pathname.includes(PATHS.viewEditContentManagement) && (
            <Button
              type="default"
              className="btn view-asset-btn"
              onClick={handleDetailPage}
              disabled={false}>
              View Detail
            </Button>
          )}
          <Button type="text" className="btn close-pdf-btn" onClick={closeModal}>
            <i className="ri-close-fill" />
          </Button>
        </div>
      </div>
      <div className="media-player-modal">
        {renderMediaPlayer()}
        <div className="timestamp-container">
          <div className="transcript-text">Transcript</div>
          {renderTranscript()}
        </div>
      </div>
    </div>
  );
};

const VideoAudioPLayerModal = ({ isModalOpen, content }: IUploadAssets) => {
  return (
    <Modal
      open={isModalOpen}
      centered
      width={1000}
      className="media-viewer-container"
      footer={false}
      closable={false}
      style={{ right: "0", position: "absolute" }}>
      {renderModalContent(content)}
    </Modal>
  );
};

export default VideoAudioPLayerModal;
