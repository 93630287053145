import { useEffect, useRef, useState } from "react";
import { Button, Input, Popover } from "antd";
import { useClickOutsideModal } from "../../../shared";

interface ISummaryManagement {
  isOpen: boolean;
  setModalClose(value: number): void;
  description: string;
}

const SummaryManagement = (props: ISummaryManagement) => {
  const { isOpen, setModalClose, description } = props;
  const [currentDescription, setCurrentDescription] = useState("");

  const moreDescriptionRef = useRef(null);

  useClickOutsideModal(moreDescriptionRef, () => setModalClose(-1), isOpen);

  useEffect(() => {
    setCurrentDescription(description);
  }, [description]);

  const handleCloseDescription = () => {
    setModalClose(-1); // Close the popover
  };

  return (
    <Popover
      overlayClassName="custom-description-body"
      trigger="click"
      open={isOpen}
      content={
        <div className="popover-content-container" ref={moreDescriptionRef}>
          <Input.TextArea
            placeholder="Type here..."
            className="popover-text"
            value={currentDescription}
            readOnly
            onChange={(e) => setCurrentDescription(e.target.value)}
          />
          <div className="des-btn">
            <Button style={{ marginRight: "10px" }} type="text" onClick={handleCloseDescription}>
              Close
            </Button>
          </div>
        </div>
      }
      overlayStyle={{ width: "600px" }}
      arrow={false}
      placement="bottomRight"
    />
  );
};

export default SummaryManagement;
