import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { RootState } from "../store";

interface IMediaPlayerProps {
  presignedUrl: string;
  id: string;
}
const MediaPlayer = forwardRef((props: IMediaPlayerProps, videoRef: any) => {
  const { presignedUrl, id } = props;

  const [url, setUrl] = useState("");
  const [canVideoPLay, setCanVideoPLay] = useState(false);

  const { videoTime, isShowVideoPLayerModal } = useSelector(
    (state: RootState) => state.mediaUploadSlice
  );

  useEffect(() => {
    if (presignedUrl) {
      setUrl(presignedUrl);
    }

    if (videoRef?.current) {
      videoRef.current.src = presignedUrl;
    }
  }, [presignedUrl, isShowVideoPLayerModal]);

  useEffect(() => {
    // Function to seek video to a specific time (in seconds)
    videoTime && seekTo(videoTime);
  }, [videoTime]);

  useEffect(() => {
    if (canVideoPLay && videoTime && videoTime > 0) {
      seekTo(videoTime);
    }
  }, [videoTime, canVideoPLay]);

  const seekTo = (seconds: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = seconds; // Set video time to the specific seconds
      videoRef.current.play(); // Start playing after seeking
    }
  };

  const renderVideo = () => {
    if (!!url) {
      return (
        <>
          {url && (
            <video
              ref={videoRef}
              controls
              key={url}
              onCanPlay={() => setCanVideoPLay(true)}
              id={id}>
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </>
      );
    }
    return <Spin indicator={<LoadingOutlined spin />} size="large" />;
  };

  return renderVideo();
});

export default MediaPlayer;
