export const useDebounce = <T>(func: (value: T) => void, delay: number): ((value: T) => void) => {
  let timeout: ReturnType<typeof setTimeout>;

  return (value: T) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => func(value), delay);
  };
};
