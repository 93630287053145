import { toast } from "react-toastify";
import { get, put } from "./apiClients";
import { store } from "../store";
import {
  setIsUpdateThemeLoading,
  setAppearance,
  setIsGetThemeLoading,
} from "../store/appState/appSlice";
import { IAppearanceProps, IThemeColor } from "../store/appState/app.interface";
import { hexToRgb, themeInitValue } from "../shared";

export const getAppearance = async (): Promise<void> => {
  try {
    store.dispatch(setIsGetThemeLoading(true));
    const res = await get(`/core/theme`);
    store.dispatch(setAppearance(res));
    return res;
  } catch (error) {
    store.dispatch(setAppearance(themeInitValue));
    console.log(error);
  } finally {
    store.dispatch(setIsGetThemeLoading(false));
  }
};

export const setOldAppearance = (): void => {
  try {
    store.dispatch(setIsUpdateThemeLoading(true));
    store.dispatch(setAppearance(themeInitValue));
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch(setIsUpdateThemeLoading(false));
  }
};

export const updateAppAppearance = async (formData: FormData, inputParams: IThemeColor) => {
  try {
    store.dispatch(setIsUpdateThemeLoading(true));
    const { data } = await put(`/core/theme`, formData, {
      params: {
        primaryColor: inputParams?.primaryColor,
        secondaryColor: inputParams?.secondaryColor,
        tertiaryColor: inputParams?.tertiaryColor,
        textColor: inputParams?.textColor,
        hoverColor: inputParams?.hoverColor,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    getAppearance();
    toast.success("Successfully saved appearance");
    return data;
  } catch (error: any) {
    toast.error(error.errorMessage ?? "Failed to save appearance");
    console.log(error);
  } finally {
    store.dispatch(setIsUpdateThemeLoading(false));
  }
};

export const setAppearanceToBody = (data: IAppearanceProps): void => {
  document.documentElement.style.setProperty("--primary", data?.primaryColor);
  document.documentElement.style.setProperty("--primary-rgb", hexToRgb(data?.primaryColor));
  document.documentElement.style.setProperty("--secondary", data?.secondaryColor);
  document.documentElement.style.setProperty("--primary-btn-hover", data?.secondaryColor);
  document.documentElement.style.setProperty("--tertiary", data?.tertiaryColor);
  // document.documentElement.style.setProperty("--hover", data?.hoverColor);
  document.documentElement.style.setProperty("--hover", `rgba(${hexToRgb(data?.primaryColor)},5%)`);
  // document.documentElement.style.setProperty("--deactive", data?.hoverColor);
  document.documentElement.style.setProperty(
    "--primary-bg",
    `rgba(${hexToRgb(data?.primaryColor)},5%)`
  );
  // document.documentElement.style.setProperty("--deactive-rgb", hexToRgb(data?.hoverColor));
  // document.documentElement.style.setProperty("--font-black", data?.textColor);
};
