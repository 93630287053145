import { useLayoutEffect } from "react";
import { Button, Flex, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { isEmpty, PATHS } from "../../../shared";
import { CreateNewPassword } from "../../../services/authApi";
import { resetOtpPassword } from "../../../store/auth/auth.slice";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userEmailAtForgetPassword, otpAtForgetPassword, isCreatePasswordLoading } = useSelector(
    (state: RootState) => state.auth
  );

  useLayoutEffect(() => {
    if (isEmpty(userEmailAtForgetPassword)) {
      navigate(PATHS.login);
      window.location.reload();
    }
  }, [userEmailAtForgetPassword]);

  const onFinish = (values: { password: string; confirmPassword: string }) => {
    const { confirmPassword } = values;

    CreateNewPassword(
      {
        email: userEmailAtForgetPassword,
        otp: otpAtForgetPassword,
        newPassword: confirmPassword,
      },
      navigate
    );
  };
  return (
    <>
      <Flex className="loginForm" align="center" justify="center">
        <div className="loginFormInner">
          <div id="fp-step-2" className="card-inner">
            <h3 className="box-title">Set a new password</h3>
            <p className="short-text">
              Your new password must be different to previously used passwords.
            </p>
            <Form
              name="resetPassword"
              layout="vertical"
              onFinish={onFinish}
              requiredMark={false}
              size="large">
              <Form.Item
                label="New Password"
                name="password"
                className="InputPassword"
                rules={[{ required: true, message: "Please input your new password" }]}>
                <Input.Password placeholder="" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                className="InputPassword"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Your Confirm Password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The Confirm Password That You Entered Does Not Match!")
                      );
                    },
                  }),
                ]}>
                <Input.Password placeholder="" />
              </Form.Item>

              <Form.Item className="loginBtn">
                <Button
                  loading={isCreatePasswordLoading}
                  className="primary-btn mt-20 mb-20"
                  type="primary"
                  htmlType="submit"
                  block>
                  Update Password
                </Button>
              </Form.Item>
            </Form>
            <div className="back-btn d-flex d-flex-middle d-flex-center">
              <Button
                className="back-to-log d-flex d-flex-middle"
                type="default"
                htmlType="submit"
                onClick={() => {
                  navigate(PATHS.login);
                  dispatch(resetOtpPassword([]));
                }}
                icon={<i className="ri-arrow-left-s-line"></i>}>
                <Link to={PATHS.login}> Back to log in</Link>
              </Button>
            </div>
          </div>
        </div>
      </Flex>
    </>
  );
};

export default ChangePassword;
