import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Button, Flex } from "antd";
import { DeleteModal, PageHeader } from "../../../components";
import { LogoForm, ColorForm } from "../../../components/admin/theme";
import { RootState } from "../../../store";
import { IAppearanceProps, IThemeColor, IThemeFile } from "../../../store/appState/app.interface";
import { getAppearance, updateAppAppearance } from "../../../services/appAppearanceApi";
import { isEmpty, themeInitValue } from "../../../shared";
import "./theme.scss";

const Theme = () => {
  const { appearance, isUpdateThemeLoading, isGetThemeLoading } = useSelector(
    (state: RootState) => state.app
  );
  const isDisable = isUpdateThemeLoading || isGetThemeLoading;

  const [isShowCancelWarnModal, setIsShowCancelWarnModal] = useState<boolean>(false);
  const [isLogoUpdated, setIsLogoUpdated] = useState<boolean>(false);
  const [localThemeConfig, setLocalThemeConfig] = useState<IAppearanceProps>({
    ...appearance,
  });
  const [localAppLogo, setLocalAppLogo] = useState<IThemeFile>({
    file: null,
    fileName: appearance?.appLogo?.fileName || "",
  });
  const [localAssistantLogo, setLocalAssistantLogo] = useState<IThemeFile>({
    file: null,
    fileName: appearance?.assistantLogo?.fileName || "",
  });

  console.log("Default theme value of Octogen  ===", themeInitValue);

  const setInitFileNameInLogo = () => {
    setLocalAppLogo((prev) => ({ ...prev, fileName: appearance?.appLogo?.fileName, file: null }));
    setLocalAssistantLogo((prev) => ({
      ...prev,
      fileName: appearance?.assistantLogo?.fileName,
      file: null,
    }));
  };

  useEffect(() => {
    getAppearance();
  }, []);

  const resetField = () => {
    setLocalThemeConfig(appearance);
    setInitFileNameInLogo();
    setIsLogoUpdated(false);
  };

  const handleCancel = () => {
    resetField();
    setIsShowCancelWarnModal(false);
  };

  const handleLogoValidation = () => {
    let isError = false;

    if (isEmpty(localAppLogo?.fileName) && isEmpty(localAssistantLogo?.fileName)) {
      toast.error("Please select Application and Assistant Logo");
      isError = true;
    } else {
      if (isEmpty(localAppLogo?.fileName)) {
        toast.error("Please select Application Logo");
        isError = true;
      }
      if (isEmpty(localAssistantLogo?.fileName)) {
        toast.error("Please select Assistant Logo");
        isError = true;
      }
    }

    if (!isError) {
      handleApply();
    }
  };

  const handleApply = async () => {
    const formData = new FormData();
    formData.append("appLogo", localAppLogo?.file || "");
    formData.append("assistantLogo", localAssistantLogo?.file || "");

    const inputParams: IThemeColor = {
      primaryColor: localThemeConfig?.primaryColor,
      secondaryColor: localThemeConfig?.secondaryColor,
      tertiaryColor: localThemeConfig?.tertiaryColor,
      textColor: localThemeConfig?.textColor,
      hoverColor: localThemeConfig?.hoverColor,
    };

    // if (localAppLogo?.file !== null || localAssistantLogo?.file !== null) {
    //   toast.success("Appearance update in progress. Updating the logo may take some time.");
    // }
    const res = await updateAppAppearance(formData, inputParams);
    if (!isEmpty(res)) {
      setLocalAppLogo((prev) => ({ ...prev, fileName: localAppLogo?.fileName, file: null }));
      setLocalAssistantLogo((prev) => ({
        ...prev,
        fileName: localAssistantLogo?.fileName,
        file: null,
      }));
    }
    setIsLogoUpdated(false);
  };

  const isDataChanged = () => {
    const prevPrimaryColor = appearance?.primaryColor || "";
    const prevSecColor = appearance?.secondaryColor || "";
    const prevTertiaryColor = appearance?.tertiaryColor || "";
    const prevAppLogoName = appearance?.appLogo?.fileName || "";

    const currPrimaryColor = localThemeConfig?.primaryColor || "";
    const currSecColor = localThemeConfig?.secondaryColor || "";
    const currTertiaryColor = localThemeConfig?.tertiaryColor || "";
    const currAppLogoName = localAppLogo?.fileName || "";

    return (
      prevPrimaryColor !== currPrimaryColor ||
      prevSecColor !== currSecColor ||
      prevTertiaryColor !== currTertiaryColor ||
      prevAppLogoName !== currAppLogoName ||
      isLogoUpdated
    );
  };

  return (
    <>
      <Flex className="admin-inner-wrap access-group-page" vertical>
        <PageHeader title="Theme Configuration" isRelevantAsset={false} />
        <div className="theme-wrap">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="card-theme">
            <LogoForm
              localAppLogo={localAppLogo}
              setLocalAppLogo={setLocalAppLogo}
              localAssistantLogo={localAssistantLogo}
              setLocalAssistantLogo={setLocalAssistantLogo}
              setIsLogoUpdated={setIsLogoUpdated}
            />

            <ColorForm
              localThemeConfig={localThemeConfig}
              setLocalThemeConfig={setLocalThemeConfig}
            />
          </motion.div>

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="card-footer-btn">
            {isDataChanged() && !isUpdateThemeLoading && (
              <Button
                type="default"
                className="btn text-btn cancel"
                onClick={() => setIsShowCancelWarnModal(true)}
                disabled={isDisable}>
                Cancel
              </Button>
            )}
            <Button
              type="primary"
              className="btn text-btn apply"
              onClick={handleLogoValidation}
              loading={isUpdateThemeLoading}
              disabled={isDisable || !isDataChanged()}>
              {isUpdateThemeLoading ? "Applying" : "Apply"}
            </Button>
          </motion.div>
        </div>
      </Flex>

      {isShowCancelWarnModal && (
        <DeleteModal
          isLoading={false}
          isModalOpen={isShowCancelWarnModal}
          handleCancel={() => setIsShowCancelWarnModal(false)}
          handleSubmit={handleCancel}
          message="This will remove your unsaved changes."
          title="Are you sure you want to cancel?"
          submitBtnName="Yes"
          cancelBtnName="No"
        />
      )}
    </>
  );
};

export default Theme;
