import { Card, Flex } from "antd";
import { motion } from "framer-motion";
import "./DataConnectionDetails.scss";
import PageHeader from "../pageHeader/PageHeader";
import { IMAGES } from "../../shared";

const DataConnectionDetails = () => {
  const renderFileCard = () => {
    return (
      <Card className={`file-card cursor-default`}>
        <div className="file-preview">
          <div className="file-bg" style={{ backgroundImage: IMAGES.pdfIcon }}>
            <img src={IMAGES.pdfIcon} alt="fileIcon" />
          </div>
          <div className="file-prev-inner"></div>
        </div>

        <div className="file-info">
          <div className="file-name-info d-flex d-flex-middle">
            <h6 className="file-name">file Title</h6>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <>
      <Flex className="admin-inner-wrap data-connection-page" vertical>
        <PageHeader
          isHeadBack={true}
          title="Data Connections"
          isRelevantAsset={false}
          content={null}
        />
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}>
          <div className="dataCardWrap">{[...Array(5)].map(() => renderFileCard())}</div>
        </motion.div>
      </Flex>
    </>
  );
};

export default DataConnectionDetails;
