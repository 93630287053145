import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { Button, Image } from "antd";
import "./AssetsFileCard.scss";
import { RootState } from "../../store";
import { setAppLayoutview } from "../../store/appState/appSlice";
import { getThumbnailsByPage } from "../../services/conversationalSearch";
import { contentByIdInitialValue } from "../../store/contentManagement/contentSlice";
import { isShowVideoPLayerModal, setVideoTime } from "../../store/mediaUpload/mediaUploadSlice";
import {
  AppLayout,
  FromWhere,
  isEmpty,
  isFileTypeAudio,
  isFileTYpeVideo,
  PATHS,
  renderFileTypeIcon,
  renderTimeStamp,
  ViewType,
} from "../../shared";
import {
  setSelectedRelevantAssetMsgId,
  setRelevantAssetViewType,
  setSelectedMessageCitations,
} from "../../store/conversationalSearch/conversationalSearchSlice";
import {
  ICitations,
  IThumbnails,
} from "../../store/conversationalSearch/conversationalSearch.interface";

interface IGroupedItems {
  id: number;
  title: string;
  file_name: string;
  original_file_name: string;
  page: number[];
  file_type: string;
  pre_signed_url?: string;
  asset_id?: number;
  start_time: string;
}

interface IReleventAsset {
  citations: ICitations[];
  messageId: number | null;
}
const RelevanAssets: FC<IReleventAsset> = ({ citations, messageId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { routeHistory } = useSelector((state: RootState) => state.app);
  const { selectedChatHistory, selectedRelevantAssetMsgId } = useSelector(
    (state: RootState) => state.conversationalSearch
  );

  const [visible, setVisible] = useState(false);
  const [selectedImageURL, _] = useState<string | undefined>("");
  const [isThumnailUpdated, setIsThumbnailUpdated] = useState(false);
  const [citationList, setCitationList] = useState<IGroupedItems[]>([]);

  const scaleStep = 0.5;

  useEffect(() => {
    if (citations.length > 0) {
      const filteredCitations = citations.filter((c) => c.asset_level === "primary");
      setIsThumbnailUpdated(false);
      const groupedData = groupItemsByFileName(filteredCitations);
      setCitationList(groupedData);
    }
  }, [citations]);

  useEffect(() => {
    const filteredCitations = citations?.filter((c) => c.asset_level === "primary");
    if (
      !isEmpty(citationList) &&
      !isEmpty(filteredCitations) &&
      citationList.length > 0 &&
      selectedChatHistory?.id !== -1 &&
      filteredCitations[0]?.asset_id === citationList[0]?.id &&
      !isThumnailUpdated
    ) {
      const request = citationList?.map((citation) => {
        return {
          file_name: citation.file_name,
          page_no: citation?.page[0] === 0 ? 0 : citation?.page[0] - 1,
        };
      });

      const getThumbnail = async () => {
        await getThumbnailsByPage(selectedChatHistory.id, request).then(
          (thumbnails: IThumbnails[]) => {
            const updatedCitations = citationList.map((citation) => {
              const findThumbnail = thumbnails?.find(
                (thumbnail) =>
                  thumbnail.file_name === citation.file_name &&
                  thumbnail.page_no === citation.page[0] - 1
              );

              if (findThumbnail?.pre_signed_url) {
                return { ...citation, pre_signed_url: findThumbnail.pre_signed_url };
              }
              return citation;
            });
            setCitationList(updatedCitations);
            setIsThumbnailUpdated(true);
          }
        );
      };
      getThumbnail();
    }
  }, [citationList, selectedChatHistory?.id, isThumnailUpdated]);

  const groupItemsByFileName = (items: ICitations[]): IGroupedItems[] => {
    const grouped: { [key: string]: IGroupedItems } = {};
    items.forEach((item) => {
      const key = `${item?.title}-${item?.file_name}-${item?.original_file_name}`;
      if (!(key in grouped)) {
        grouped[key] = {
          id: item?.asset_id,
          title: item?.title,
          file_name: item?.file_name,
          original_file_name: item?.original_file_name,
          page: [],
          file_type: item?.file_type,
          pre_signed_url: item?.pre_signed_url,
          start_time: item?.start_time,
        };
      }
      if (!grouped[key].page.includes(item?.page + 1)) {
        grouped[key].page.push(item?.page + 1);
      }
    });

    return Object.values(grouped);
  };

  // const isImage = (item: ICitations | IGroupedItems) => Images.includes(item?.file_type || "");

  // const handleOnTitleClick = (
  //   item: ICitations | IGroupedItems,
  //   e: React.MouseEvent<HTMLHeadingElement, MouseEvent>
  // ) => {
  //   handleNavigateReleventAssets(0);
  //   dispatch(setAppLayoutview(AppLayout.MINIMIZE));

  //   if (isImage(item)) {
  //     if (item?.pre_signed_url) {
  //       setVisible(true);
  //       setSelectedImageURL(item?.pre_signed_url);
  //     } else {
  //       getThumbnailsByPage(selectedChatHistory.id, [
  //         { file_name: item?.file_name, page_no: 0 },
  //       ]).then((thumbnails: IThumbnails[]) => {
  //         if (thumbnails.length) {
  //           setVisible(true);
  //           setSelectedImageURL(thumbnails[0]?.pre_signed_url);
  //         }
  //       });
  //     }
  //   } else {
  //     e.stopPropagation();
  //     if (item?.file_type === "xls" || item?.file_type === "xlsx") {
  //       toast.error("Cannot open preview of this file");
  //     } else {
  //       // document.body.classList.add("relevant-assets-page");
  //       // handleOpenPdfDoc(item?.asset_id || 0, item?.file_name, "", item?.title, item?.page, true);
  //     }
  //   }
  // };

  const handleOnTitleClick = () => {
    // if (messageId === selectedRelevantAssetMsgId) {
    //   handleCloseRelevantAssetScreen();
    // } else {
    //   handleNavigateReleventAssets(0);
    //   dispatch(setAppLayoutview(AppLayout.MINIMIZE));
    //   dispatch(setSelectedRelevantAssetMsgId(messageId));
    // }

    handleNavigateReleventAssets(0);
    dispatch(setAppLayoutview(AppLayout.MINIMIZE));
    dispatch(setSelectedRelevantAssetMsgId(messageId));
  };

  const showMore = () => {
    handleNavigateReleventAssets(0);
  };

  const handleNavigateReleventAssets = (s_no: number) => {
    dispatch(setSelectedMessageCitations(citations));
    navigate(PATHS.relevantAssets, {
      state: { s_no: s_no, fromWhere: FromWhere.ConversationalSearch },
    });
  };

  const handleAssetTimeStamp = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: IGroupedItems
  ) => {
    e.stopPropagation();

    const modalContent = {
      ...contentByIdInitialValue,
      file_name: item?.file_name,
      file_type: item?.file_type,
      asset_id: item?.id,
      original_file_name: item?.original_file_name,
      title: item?.title,
      pre_signed_url: item?.pre_signed_url ?? "",
    };
    dispatch(isShowVideoPLayerModal({ content: modalContent, isOpen: true, isCitation: true }));
    dispatch(setVideoTime(item?.start_time));
  };

  const renderPageNumber = (item: IGroupedItems) => {
    if (isFileTYpeVideo(item?.file_type) || isFileTypeAudio(item?.file_type)) {
      return (
        <div className="asset-timestamp" onClick={(e) => handleAssetTimeStamp(e, item)}>
          {renderTimeStamp(item?.start_time)}
        </div>
      );
    } else
      return (
        <span className="page-no" title={item?.page.join(", ")}>
          {item?.page.slice(0, 5).join(", ")}
          {item?.page.length > 5 ? "..." : ""}
        </span>
      );
  };

  const handleCloseReleventAsset = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(setRelevantAssetViewType(ViewType.GRID));
    dispatch(setSelectedMessageCitations([]));
    dispatch(setSelectedRelevantAssetMsgId(null));

    if (routeHistory[1].includes(PATHS.viewEditContentManagement)) {
      navigate(PATHS.home);
    } else {
      navigate(routeHistory[1] || PATHS.home);
    }
  };

  return (
    <>
      <div
        className="cursor-pointer"
        onClick={() => {
          handleOnTitleClick();
          dispatch(setRelevantAssetViewType(ViewType.GRID));
        }}>
        <div className="d-flex d-flex-between">
          <h5>Relevant Assets:</h5>
          {selectedRelevantAssetMsgId === messageId && (
            <Button
              type="text"
              className="page-close-btn"
              icon={<i className="ri-close-fill" />}
              onClick={handleCloseReleventAsset}
            />
          )}
        </div>

        <div className="documents-list">
          {!!citationList.length &&
            citationList.slice(0, 5).map((item) => {
              return (
                <div className="documents-item" key={uuidv4()}>
                  {item?.pre_signed_url ? (
                    <div
                      className="file-bg"
                      style={{ backgroundImage: `url(${item?.pre_signed_url})` }}
                    />
                  ) : (
                    <img
                      src={renderFileTypeIcon(item?.file_type)}
                      alt="File Icon"
                      className={`${item?.pre_signed_url ? "file-icon" : ""}`}
                    />
                  )}
                  <span className="file-name">{item?.title ?? item?.original_file_name}</span>

                  {renderPageNumber(item)}
                </div>
              );
            })}

          {citationList.length > 5 ? (
            <div className="documents-item documents-show-more" onClick={showMore}>
              <span className="file-name">Show more</span>
            </div>
          ) : (
            ""
          )}
          <div>{/* <PDFViewer /> */}</div>
        </div>
      </div>

      {visible && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${selectedImageURL}`,
              onVisibleChange: (value) => {
                handleNavigateReleventAssets(0);
                setVisible(value);
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default RelevanAssets;
