import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Button, Card, Col, Dropdown, Flex, Row, Switch, Table, TableProps, Tooltip } from "antd";

import { DeleteModal, PageHeader, TableSkeleton } from "../../../components";
import { DataConnectionModal } from "../../../components/dataConnectionModal";
import { RootState } from "../../../store";
import { IAllSources } from "../../../store/dataConnection/dataConnection.interface";
import {
  setAllSources,
  setDataConnectionCurrPage,
} from "../../../store/dataConnection/dataConnectionSlice";
import {
  deleteDataConnection,
  getAllSources,
  updateStatus,
} from "../../../services/dataConnection";
import { CustomPagination, IMAGES, isEmpty, PageLimit } from "../../../shared";
import "./DataConnection.scss";

const DataConnection = () => {
  const dispatch = useDispatch();

  const {
    allSources,
    isAllSourcesLoading,
    isDeleteSourceLoading,
    dataConnectionCurrPage,
    totalConnectionCount,
    isStatusUpdating,
  } = useSelector((state: RootState) => state.dataConnection);

  const [isDataModalOpen, setIsDataModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isDeleteDataModalOpen, setIsDeleteDataModalOpen] = useState<boolean>(false);
  const [selectedDataConnectionId, setSelectedDataConnectionId] = useState<number>(-1);
  const [selectedConnectionDetails, setSelectedConnectionDetails] = useState<IAllSources | null>(
    null
  );

  useEffect(() => {
    getAllSources(dataConnectionCurrPage);
    setIsEditMode(false);
  }, [dataConnectionCurrPage]);

  const handleDeleteBtn = (record: IAllSources) => {
    setIsDeleteDataModalOpen(true);
    setSelectedDataConnectionId(record?.dataConnectionId);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteDataModalOpen(false);
    setSelectedDataConnectionId(-1);
  };

  const handleDeleteDataConnection = async () => {
    if (selectedDataConnectionId !== -1) {
      deleteDataConnection(selectedDataConnectionId, true);
    } else {
      toast.error("Failed to delete data connection");
    }
    handleCloseDeleteModal();
  };

  const formatDate = (date: string): string => {
    if (date) {
      return moment(date).format("DD MMM YYYY hh:mm a");
    } else {
      return "";
    }
  };

  const handleUpdateStatus = async (value: boolean, dataConnection: IAllSources, index: number) => {
    const res = await updateStatus(dataConnection?.dataConnectionId, value);

    if (!isEmpty(res?.data)) {
      const values = [...allSources];
      values[index] = {
        ...dataConnection,
        isActive: value,
      };
      dispatch(setAllSources(values));
    }
  };

  const handleOnCancel = () => {
    setIsDataModalOpen(false), setIsEditMode(false), setSelectedConnectionDetails(null);
  };

  const columns: TableProps["columns"] = [
    {
      title: "Connecter Type",
      dataIndex: "connectionType",
      key: "connectionType",
      width: "200px",
      render: () => (
        <>
          <span className="td-label">Connecter Type : </span>
          <div className="connecter-name d-flex d-flex-middle">
            <img src={IMAGES.sharepointIcon} /> Share Point
          </div>
        </>
      ),
    },
    {
      title: "Site Name",
      dataIndex: "site_name",
      key: "site_name",
      width: "220px",
      render: (site_name: string) => {
        return (
          <>
            <span className="td-label">Site Name : </span>
            <div className="attributes-link">{site_name}</div>
          </>
        );
      },
    },
    {
      title: "Domain URL",
      dataIndex: "domainUrl",
      key: "domainUrl",
      width: "220px",
      render: (domainUrl: string) => {
        return (
          <>
            <span className="td-label">Domain URL : </span>
            <div className="attributes-link">{domainUrl}</div>
          </>
        );
      },
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      width: "100px",
      render: (frequency: string) => {
        return (
          <>
            <span className="td-label">Frequency : </span>
            <div className="attributes-link">{frequency ? `${frequency} Hz` : "-"}</div>
          </>
        );
      },
    },
    {
      title: "Last refreshed time",
      dataIndex: "connectionDate",
      key: "connectionDate",
      width: "180px",
      render: (connectionDate: string) => {
        return (
          <>
            <span className="td-label">Last refreshed time : </span>
            <span>{formatDate(connectionDate)}</span>
          </>
        );
      },
    },
    {
      title: "Connected By",
      dataIndex: "connectedBy",
      key: "connectedBy",
      width: "150px",
      render: (connectedBy: string) => {
        return (
          <>
            <span className="td-label">Connected By : </span>
            <span>{connectedBy ?? ""}</span>
          </>
        );
      },
    },
    {
      title: "Health Status",
      dataIndex: "connectionStatus",
      key: "connectionStatus",
      width: "130px",
      render: (connectionStatus: boolean, res: IAllSources) => {
        return (
          <>
            <span className="td-label">Health Status : </span>
            <div className="stat-wrap">
              {connectionStatus ? (
                <div className="asset-stat">
                  <div className="published inner">Healthy</div>
                </div>
              ) : (
                <>
                  <div className="asset-stat">
                    <div className="failedStatusText inner">Failed</div>
                  </div>
                  <Tooltip
                    trigger="hover"
                    title={res?.connectionMessage ?? "Reason for failed"}
                    overlayClassName="tooltip-text">
                    <i className="ri-information-fill info" />
                  </Tooltip>
                </>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Active / Deactive",
      dataIndex: "isActive",
      key: "isActive",
      width: "100px",
      render: (isActive: boolean, res: IAllSources, index: number) => {
        return (
          <>
            <span className="td-label">Active / Deactive : </span>
            <Switch
              checked={isActive}
              disabled={isStatusUpdating}
              onChange={(e) => handleUpdateStatus(e, res, index)}
            />
          </>
        );
      },
    },
    {
      title: "Action",
      key: "status",
      width: "90px",
      dataIndex: "status",
      render: (_, res: IAllSources) => (
        <>
          <span className="td-label">Action : </span>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Edit",
                  onClick: () => {
                    setIsDataModalOpen(true),
                      setIsEditMode(true),
                      setSelectedConnectionDetails(res);
                  },
                },
                {
                  key: "2",
                  label: "Delete",
                  onClick: () => handleDeleteBtn(res),
                },
              ],
            }}
            overlayStyle={{ width: 80 }}
            overlayClassName="table-action-dropdown table-action-dropdown-user"
            placement="bottomRight">
            <Button className="more-action">
              <i className="ri-more-2-fill" />
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];

  const handlePagination = (page: number) => {
    dispatch(setDataConnectionCurrPage(page));
  };

  return (
    <>
      <Flex className="admin-inner-wrap data-connection-page" vertical>
        <PageHeader
          title="Data Connections"
          isRelevantAsset={false}
          content={
            <Button
              className="btn"
              type="default"
              onClick={() => {
                setIsDataModalOpen(true), setSelectedConnectionDetails(null);
              }}
              icon={<i className="ri-upload-2-fill" />}>
              Connect new source
            </Button>
          }
        />

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}>
          <Row>
            <Col span={24}>
              <Card
                className={`global-table-card ${isEmpty(allSources) ? "global-table-empty" : ""}`}>
                {isAllSourcesLoading ? (
                  <TableSkeleton />
                ) : (
                  <Table
                    className="global-table responsive-table data-connection-table"
                    columns={columns}
                    dataSource={allSources ?? []}
                    tableLayout="fixed"
                    scroll={{ y: "calc(100vh - 188px)" }}
                    pagination={false}
                  />
                )}

                {!isEmpty(allSources) ? (
                  <CustomPagination
                    currentPage={dataConnectionCurrPage}
                    total={totalConnectionCount}
                    pageSize={PageLimit.dataConnection}
                    handlePagination={handlePagination}
                  />
                ) : (
                  <></>
                )}
              </Card>
            </Col>
          </Row>
        </motion.div>
      </Flex>

      <DataConnectionModal
        isModalOpen={isDataModalOpen}
        handleCancel={handleOnCancel}
        setIsDataModalOpen={setIsDataModalOpen}
        selectedConnectionDetails={selectedConnectionDetails}
        isEditMode={isEditMode}
      />

      <DeleteModal
        isLoading={isDeleteSourceLoading}
        isModalOpen={isDeleteDataModalOpen}
        handleCancel={handleCloseDeleteModal}
        handleSubmit={handleDeleteDataConnection}
        message="Are you sure you want to delete this data connection?"
        title="Delete Data Connection"
      />
    </>
  );
};

export default DataConnection;
