import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Col, Flex, Form, Input, Row } from "antd";
import "./Login.scss";
import configs from "../../../shared/config";
import { RootState, store } from "../../../store";
import SSOIcon from "/assets/icons/sso-icon.svg";
import microsoftIcon from "/assets/icons/microsoft-icon.svg";
import { IMAGES, PATHS, setLoginData } from "../../../shared";
import { authenticatesUser } from "../../../services/authApi";
import { setLoginLoading } from "../../../store/auth/auth.slice";
import { graphConfig, loginRequest } from "../microsoftLogin/authConfig";
import { getSubdomain } from "../../../services/apiClients";

interface ILoginRequest {
  username: string;
  password: string;
}

const Login = () => {
  const navigate = useNavigate();

  const { loginLoading } = useSelector((state: RootState) => state.auth);

  const onFinish = async (values: ILoginRequest) => {
    const { username, password } = values;
    const userName = username ? username?.toLowerCase() : "";

    await authenticatesUser({ username: userName, password }, navigate);
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        store.dispatch(setLoginLoading(true));

        const { data } = await axios.post(
          `${configs.BASE_URL}core/token/check/code/google?authCode=${tokenResponse?.code}`,
          {},
          {
            headers: {
              Subdomain: getSubdomain(),
            },
          }
        );

        setLoginData(data, navigate);
      } catch (error: any) {
        toast.error(error?.response?.data?.errorMessage ?? "Failed to login");
      } finally {
        store.dispatch(setLoginLoading(false));
      }
    },
    flow: "auth-code",
    onError: (error: any) => toast.error(error ?? "Failed to login"),
  });

  const { instance, accounts } = useMsal();

  const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        const access_token: string = response?.accessToken;

        axios
          .get(graphConfig.graphMeEndpoint, {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Accept: "application/json",
            },
          })
          .then((res) => {
            axios
              .post(
                `${configs.BASE_URL}core/token/getExternalUserDetail`,
                { username: res.data?.userPrincipalName },
                {
                  headers: {
                    AUTHTYPE: "MICROSOFT",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response?.idToken}`,
                  },
                }
              )
              .then((res) => {
                const { data } = res;

                setLoginData(data, navigate);
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err: any) => console.log("err", err));
  };

  return (
    <Flex className="loginForm" align="center" justify="center">
      <div className="loginFormInner">
        <div id="loginView" className="card-inner">
          <span className="labelLogin">
            Welcome! <img className="handWave" src={IMAGES.handWave} alt="logo" />
          </span>
          <Form
            name="login"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: false }}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
            size="large">
            <Form.Item
              label="Your email"
              name="username"
              rules={[{ required: true, message: "Please input your email!", type: "email" }]}>
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              className="InputPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}>
              <Input.Password placeholder="" />
            </Form.Item>
            <Form.Item className="loginBtn">
              <Button
                loading={loginLoading}
                className="primary-btn"
                type="primary"
                htmlType="submit"
                block>
                Login
              </Button>
            </Form.Item>
          </Form>

          <Row className="otherLoginOption" align="middle">
            <Col span={24}>
              <p className="or">or</p>
            </Col>
            <Col span={24}>
              <Button
                className="secondary-btn"
                type="default"
                htmlType="button"
                block
                icon={<img src={SSOIcon} alt="Icon" />}
                disabled>
                Continue with SSO<span className="coming-soon">(Coming Soon)</span>
              </Button>
            </Col>
            <Col span={24}>
              <Button
                className="secondary-btn"
                type="default"
                htmlType="button"
                block
                icon={<img src={IMAGES.googleLogo} alt="Icon" />}
                onClick={() => login()}>
                Login with Google
              </Button>

              <Button
                className="secondary-btn"
                type="default"
                htmlType="button"
                block
                icon={<img src={microsoftIcon} alt="Icon" />}
                onClick={() => handleLogin("popup")}>
                Login with Microsoft
              </Button>
            </Col>
          </Row>

          <Row className="forgotPassword" justify="end" align="middle">
            <Col className="mb-10">
              <Link className="text-fp" to={PATHS.forgotPassword}>
                Forgot password?
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </Flex>
  );
};

export default Login;
