import { ChangeEvent, FC, useRef, useState } from "react";
import { Button, Form, Modal, Progress } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../store";
import { setFileUploadProgress } from "../../store/userManagement/usersSlice";
import { uploadProfilePic } from "../../services/userManagement";
import { FormTitle, isEmpty, MaxFileSize, UploadResumeState } from "../../shared";

interface IUserProfileUploadForm {
  setIsVisibleResumeModal: Function;
  title: string;
  isVisibleResumeModal: boolean;
}
const UserProfileUploadForm: FC<IUserProfileUploadForm> = (props) => {
  const { setIsVisibleResumeModal, title, isVisibleResumeModal } = props;
  const [uploadForm] = Form.useForm();
  const dispatch = useDispatch();
  const { fileUploadProgress, isFileUpdating } = useSelector((state: RootState) => state.user);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [uploadingState, setUploadingState] = useState<UploadResumeState>(
    UploadResumeState.INITIAL
  );

  const isBackground = title === FormTitle.UploadCover;

  const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    fileUploadProgress && dispatch(setFileUploadProgress(0));

    if (e.target.files) {
      const file = e.target.files[0];
      const maxFileSize = isBackground ? MaxFileSize.BgCover : MaxFileSize.ProfilePic;

      if (file?.size > maxFileSize) {
        toast.error(`File size should be less than ${isBackground ? "2MB" : "1MB"}`);
      } else {
        setUploadingState(UploadResumeState.FILE);
        setFile(file);
        setFileName(file.name);
      }
    }
    e.target.value = "";
  };

  const handleFileUpload = async () => {
    if (file) {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("isBackground ", String(isBackground));

      if (isBackground) {
        toast.warn("Updating your background cover may take a moment. Please wait");
      } else {
        toast.warn("Updating your profile picture may take a moment. Please wait");
      }

      const res = await uploadProfilePic(formData, setUploadingState, isBackground);
      if (!isEmpty(res)) {
        handleCloseResumeModal();
      }
    } else {
      setUploadingState(UploadResumeState.FAILED);
      toast.error("Failed to upload");
    }
  };

  const handleRemoveFile = () => {
    uploadForm.resetFields();
    setUploadingState(UploadResumeState.INITIAL);
    setFile(null);
    setFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleCloseResumeModal = () => {
    setIsVisibleResumeModal(false);
    handleRemoveFile();
  };

  const handleClickUploadFile = () => {
    if (fileInputRef.current != null) {
      fileInputRef.current.click();
    }
  };

  const renderAsserUploadingState = (fileName: string, fileUploadProgress: number) => {
    return (
      <div className="uploading-field">
        <p className="upload-status">
          {uploadingState === UploadResumeState.FILE
            ? "Selected file"
            : uploadingState === UploadResumeState.UPLOADING
            ? "Uploading..."
            : uploadingState === UploadResumeState.FAILED
            ? "Upload Failed"
            : "Uploaded"}
        </p>
        <div className="file-container">
          <span>
            <i className="ri-attachment-line browseIcon"></i>
            <span>{fileName}</span>
          </span>
          {uploadingState === UploadResumeState.FILE ? (
            <div className="btn-wrapper">
              <Button type="text" className="text-btn remove-file" onClick={handleRemoveFile}>
                <i className="ri-delete-bin-fill"></i>
              </Button>
            </div>
          ) : (
            <></>
          )}

          {uploadingState !== UploadResumeState.UPLOADING ? (
            <Progress percent={fileUploadProgress} />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={isVisibleResumeModal}
      title={title}
      className="add-exp-modal"
      footer={false}
      onCancel={handleCloseResumeModal}
      centered>
      <Form form={uploadForm} name="uploadResume" className="block-style" layout="vertical">
        <div className="form-item-col">
          <p className="input-label">Upload File</p>
          <div>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple={false}
              placeholder="Upload Asset"
              onChange={handleSelectFile}
              accept=".jpg, .jpeg, .png, .svg"
            />
            <div className="upload-file-container">
              <div className="file-name">
                <span>
                  <i className="ri-attachment-line browseIcon"></i>
                  <span>Browse</span>
                </span>
              </div>

              <Button
                onClick={handleClickUploadFile}
                className="btn-primary fill-button btn-type2"
                type="primary"
                size="middle"
                disabled={isFileUpdating}>
                Browse
              </Button>
            </div>
          </div>
        </div>

        {uploadingState === UploadResumeState.FILE ||
        uploadingState === UploadResumeState.UPLOADING ||
        uploadingState === UploadResumeState.UPLOADED ||
        uploadingState === UploadResumeState.FAILED ? (
          <>{renderAsserUploadingState(fileName, fileUploadProgress)}</>
        ) : (
          ""
        )}

        <div className="submit-wrap ant-modal-footer">
          <Button className="btn" type="default" onClick={handleCloseResumeModal}>
            Cancel
          </Button>

          <Button
            className="btn"
            type="primary"
            htmlType="submit"
            loading={isFileUpdating}
            disabled={uploadingState === UploadResumeState.INITIAL || isFileUpdating}
            onClick={handleFileUpload}>
            Upload
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default UserProfileUploadForm;
