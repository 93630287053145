import { useRef } from "react";
import { Button, Popover, Space, Tag } from "antd";

import {
  IContent,
  IFormattedIndustry,
  IMappedIndustry,
  IMappedIndustryChildren,
} from "../../../store/contentManagement/content.interface";
import {
  findNodeNameByValue,
  findParentNodesByValue,
  mapDataToTreeSelect,
} from "../../../shared/taxonomyHelper";
import { useClickOutsideModal } from "../../../shared";

interface ISelectedTagModal {
  isOpen: boolean;
  setModalClose(value: number): void;
  selectedContent: IContent | null;
}

const SelectedTagModal = (props: ISelectedTagModal) => {
  const { isOpen, setModalClose, selectedContent } = props;
  const moreDescriptionRef = useRef(null);
  useClickOutsideModal(moreDescriptionRef, () => setModalClose(-1), isOpen);

  const mappedTaxonomyTree = mapDataToTreeSelect(selectedContent?.linkedTags?.taxonomyTree ?? []);
  const selectedIndustries = (selectedContent?.linkedTags?.linkedTaxonomyNodes?.Industry ?? []).map(
    (item) => String(item)
  );
  const selectedFunction = (selectedContent?.linkedTags?.linkedTaxonomyNodes?.Function ?? []).map(
    (item) => String(item)
  );

  const getFormattedSelectedTags = (selectedTags: string[], mappedTags: IMappedIndustry[]) => {
    const formattedTags: IFormattedIndustry[] = [];

    if (selectedTags && selectedTags.length) {
      selectedTags.forEach((value: string) => {
        formattedTags.push({
          parents: findParentNodesByValue(value, mappedTags),
          value,
          id: value,
          title: findNodeNameByValue(mappedTags, value),
        });
      });
    }
    return formattedTags;
  };

  const formattedSelectedIndustryTag = getFormattedSelectedTags(
    selectedIndustries,
    mappedTaxonomyTree
  );
  const formattedSelectedFunctionTag = getFormattedSelectedTags(
    selectedFunction,
    mappedTaxonomyTree
  );

  const handleCloseDescription = () => {
    setModalClose(-1);
  };

  return (
    <Popover
      overlayClassName="custom-description-body custom-tag-popover"
      trigger="click"
      open={isOpen}
      content={
        <div className="popover-content-container" ref={moreDescriptionRef}>
          <div className="popover-tags-scroll">
            <div className="list-tags-section">
              <h4>Industry</h4>
              <div className="list-tags-select">
                {formattedSelectedIndustryTag && formattedSelectedIndustryTag.length ? (
                  formattedSelectedIndustryTag.map(
                    (industry: IFormattedIndustry, industryIdx: number) => {
                      if (industry.parents && industry.parents.length) {
                        return (
                          <div className="item-tags" key={industryIdx}>
                            <Space size={[0, 4]} className="tags">
                              {industry.parents.map(
                                (indParent: IMappedIndustryChildren, indParentIdx: number) => {
                                  return <Tag key={indParentIdx}>{indParent.title}</Tag>;
                                }
                              )}
                              <Tag>{industry.title}</Tag>
                            </Space>
                          </div>
                        );
                      } else {
                        return (
                          <div className="item-tags" key={industryIdx}>
                            <Space size={[0, 1]} className="tags mb-24">
                              <Tag>{industry.title}</Tag>
                            </Space>
                          </div>
                        );
                      }
                    }
                  )
                ) : (
                  <div className="no-record">No record found</div>
                )}
              </div>
            </div>

            <div className="list-tags-section">
              <h4>Function</h4>
              <div className="list-tags-select">
                {formattedSelectedFunctionTag && formattedSelectedFunctionTag.length ? (
                  formattedSelectedFunctionTag.map((fun: IFormattedIndustry, funIdx: number) => {
                    if (fun.parents && fun.parents.length) {
                      return (
                        <div className="item-tags" key={funIdx}>
                          <Space size={[0, 4]} className="tags">
                            {fun.parents.map(
                              (indParent: IMappedIndustryChildren, funParentIdx: number) => {
                                return <Tag key={funParentIdx}>{indParent.title}</Tag>;
                              }
                            )}
                            <Tag>{fun.title}</Tag>
                          </Space>
                        </div>
                      );
                    } else {
                      return (
                        <div className="item-tags" key={funIdx}>
                          <Space size={[0, 8]} className="tags">
                            <Tag>{fun.title}</Tag>
                          </Space>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div className="no-record">No record found</div>
                )}
              </div>
            </div>
          </div>

          <div className="des-btn">
            <Button style={{ marginRight: "10px" }} type="text" onClick={handleCloseDescription}>
              Close
            </Button>
          </div>
        </div>
      }
      overlayStyle={{ width: "600px" }}
      arrow={false}
      placement="bottomRight"
    />
  );
};

export default SelectedTagModal;
