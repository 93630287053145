import { FC, useRef } from "react";
import { Button, Popover, Space, Tag } from "antd";
import { useClickOutsideModal } from "../../../../shared";
import { IFormattedIndustry } from "../../../../store/contentManagement/content.interface";
import "./AssetTagsGroupModal.scss";

interface IAssetTagsGroupModalProps {
  isOpen: boolean;
  setModalClose(value: number): void;
  formattedTags: IFormattedIndustry[];
  contentTitle: string;
}

const AssetTagsGroupModal: FC<IAssetTagsGroupModalProps> = ({
  isOpen,
  setModalClose,
  formattedTags,
  contentTitle,
}) => {
  const moreDescriptionRef = useRef(null);

  const handleCloseDescription = () => {
    setModalClose(-1);
  };

  useClickOutsideModal(moreDescriptionRef, handleCloseDescription, isOpen);

  return (
    <Popover
      overlayClassName="tag-popover-user-management custom-description-body custom-tag-popover"
      trigger="click"
      open={isOpen}
      content={
        <div className="popover-content-container asset-tags-modal" ref={moreDescriptionRef}>
          <div className="popover-tags-scroll">
            <div className="list-tags-section">
              <h4>{contentTitle}</h4>
              <div className="list-tags-select">
                {formattedTags && formattedTags.length ? (
                  formattedTags.map((industry: IFormattedIndustry, industryIdx: number) => {
                    if (industry.parents && industry.parents.length) {
                      return (
                        <div className="item-tags" key={industryIdx}>
                          <Space size={[0, 1]} className="tags">
                            {industry.parents.map((indParent: any, indParentIdx: number) => {
                              return <Tag key={indParentIdx}>{indParent?.taxonomyNodeName}</Tag>;
                            })}
                            <Tag>{industry.title}</Tag>
                          </Space>
                        </div>
                      );
                    } else {
                      return (
                        <div className="item-tags" key={industryIdx}>
                          <Space size={[0, 1]} className="tags mb-24">
                            <Tag>{industry.title}</Tag>
                          </Space>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div className="no-record">No record found</div>
                )}
              </div>
            </div>
          </div>

          <div className="des-btn">
            <Button style={{ marginRight: "10px" }} type="text" onClick={handleCloseDescription}>
              Close
            </Button>
          </div>
        </div>
      }
      overlayStyle={{ width: "600px" }}
      arrow={false}
      placement="bottomRight"
    />
  );
};

export default AssetTagsGroupModal;
