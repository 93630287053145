import { deleteRequest, get, post, put } from "./apiClients";
import { toast } from "react-toastify";
import { store } from "../store";
import {
  setAccessGroups,
  setAddAccessGroupLoading,
  setIsAccessGroupDeleting,
  setIsAccessGroupsLoading,
  setTotalAccessGroupsCount,
  setUpdateAccessGroupLoading,
  updatePageOnAccessGrpDelete,
} from "../store/accessGroup/accessGroupSlice";
import { IaccessGroups } from "../store/accessGroup/accessGroup.interface";
import { PageLimit, stringToBoolean } from "../shared";

interface IAccessGroupResponse {
  accessGroupId: number;
  accessGroupName: string;
  download: string;
  edit: string;
  role: string;
  searchAndView: string;
  status: string;
  taxonomyMapping: { taxonomyId: number; taxonomyNodeIds: number[] }[];
  upload: string;
}

export const CreateAccessGroup = async (
  createTaxonomy: {
    access_group_name: string;
    role: string;
    search_and_view: string;
    download: string;
    upload: string;
    edit: string;
    access_group_status: string;
  },
  addTaxonomy: {
    taxonomyMapping: {
      taxonomyId: number;
      taxonomyNodeIds: number[];
    }[];
  },
  accessGroupSearch: string,
  accessGroupPage: number
) => {
  try {
    store.dispatch(setAddAccessGroupLoading(true));
    store.dispatch(setIsAccessGroupsLoading(true));

    const createRes = await post(`/core/accessGroup/createAccessGroup`, createTaxonomy);
    await post(`/core/accessGroup-taxonomy`, {
      ...addTaxonomy,
      accessGroupId: createRes?.data?.access_group_id ?? -1,
    });

    getAccessGroups(false, accessGroupSearch, accessGroupPage);

    toast.success("Access Group added successfully");
  } catch (error: any) {
    console.log(error, "CreateAccessGroup error");
    toast.error(error?.message ?? "Failed to create access group");
  } finally {
    store.dispatch(setAddAccessGroupLoading(false));
    store.dispatch(setIsAccessGroupsLoading(false));
  }
};

export const getAccessGroups = async (
  isLoading: boolean,
  searchText: string | null,
  page: number | null
) => {
  try {
    isLoading && store.dispatch(setIsAccessGroupsLoading(true));
    let res;
    if (page === null || searchText === null) {
      res = await get(`/core/accessGroup/accessGroup-taxonomy`);
    } else {
      res = await get(
        `/core/accessGroup/accessGroup-taxonomy?name=${searchText}&page=${page - 1}&limit=${
          PageLimit.AccessGroup
        }`
      );
    }

    const accessGroupContent = res.content ?? [];
    const totalElementsRes = res.totalElements ?? 0;

    const apiResponse: IaccessGroups[] = accessGroupContent?.map((v: IAccessGroupResponse) => ({
      id: v?.accessGroupId,
      title: v?.accessGroupName,
      role: v?.role,
      taxonomyMapping: v?.taxonomyMapping,
      searchView: stringToBoolean(v?.searchAndView),
      download: stringToBoolean(v?.download),
      upload: stringToBoolean(v?.upload),
      edit: stringToBoolean(v?.edit),
      status: stringToBoolean(v?.status),
    }));

    store.dispatch(setAccessGroups(apiResponse ?? []));
    store.dispatch(setTotalAccessGroupsCount(totalElementsRes));
  } catch (error) {
    console.log(error, "getAccessGroups error");
    store.dispatch(setAccessGroups([]));
  } finally {
    store.dispatch(setIsAccessGroupsLoading(false));
  }
};

export const deleteAccessGroups = async (
  accessGroupId: number,
  accessGroupSearch: string,
  accessGroupPage: number,
  totalAccessGroupsCount: number
) => {
  try {
    store.dispatch(setIsAccessGroupDeleting(true));
    await deleteRequest(`/core/accessGroup/${accessGroupId}`);

    let newPage = accessGroupPage || 0;
    if (totalAccessGroupsCount % PageLimit.AccessGroup === 1) {
      newPage = newPage - 1;
    }
    store.dispatch(updatePageOnAccessGrpDelete(newPage));
    getAccessGroups(false, accessGroupSearch, newPage);
    toast.success("Access Group deleted successfully");
  } catch (error: any) {
    console.log(error, "deleteAccessGroups error");
    toast.error(error?.message ?? "Failed to delete access group");
  } finally {
    store.dispatch(setIsAccessGroupDeleting(false));
  }
};

export const UpdateAccessGroup = async (
  updateAccessGroup: {
    access_group_id: number;
    access_group_name: string;
    role: string;
    search_and_view: string;
    download: string;
    upload: string;
    edit: string;
    access_group_status: string;
  },
  updateTaxonomy: {
    taxonomyMapping: {
      taxonomyId: number;
      taxonomyNodeIds: number[];
    }[];
  },
  accessGroupSearch: string,
  accessGroupPage: number
) => {
  try {
    store.dispatch(setUpdateAccessGroupLoading(true));
    store.dispatch(setIsAccessGroupsLoading(true));

    await put(`/core/accessGroup/editAccessGroup`, updateAccessGroup);
    await post(`/core/accessGroup-taxonomy`, {
      ...updateTaxonomy,
      accessGroupId: updateAccessGroup?.access_group_id ?? -1,
    });
    getAccessGroups(false, accessGroupSearch, accessGroupPage);
    toast.success("Access Group updated successfully");
  } catch (error: any) {
    toast.error(error?.message ?? "Failed to update access group");
  } finally {
    store.dispatch(setUpdateAccessGroupLoading(false));
    store.dispatch(setIsAccessGroupsLoading(false));
  }
};
