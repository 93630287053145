import { useDispatch, useSelector } from "react-redux";
import {
  deleteLastChatMessage,
  setChatHistory,
  setIsCloseWebSocket,
  setIsStreamingStart,
  setLoadingMessageId,
  setSelectedChatHistory,
} from "../../store/conversationalSearch/conversationalSearchSlice";
import { Model } from "../constants";
import { RootState } from "../../store";
import {
  IChatHistory,
  ITaxonomyFilter,
} from "../../store/conversationalSearch/conversationalSearch.interface";
import { convertToNumber } from "../helpers";
import { TFiltersType } from "../../store/filtersFromTaxonomyData/filters.interface";

// Chat hooks start
export const useChats = () => {
  const dispatch = useDispatch();
  const { selectedChatHistory, chatHistory } = useSelector(
    (state: RootState) => state.conversationalSearch
  );
  const { industryTaxonomy, functionTaxonomy, assetTypeTaxonomy } = useSelector(
    (state: RootState) => state.taxonomy
  );
  const {
    selectedAssetType,
    selectedFileType,
    selectedFunctions,
    selectedIndustry,
    selectedDateRange,
  } = useSelector((state: RootState) => state.filtersData);

  const clearAllChatStates = () => {
    dispatch(setIsStreamingStart(false));
    dispatch(setLoadingMessageId(-1));
    dispatch(setIsCloseWebSocket(true));
  };

  const removeUnSavedChatHistory = (history: IChatHistory) => {
    const prevChatMessages = history?.messages || [];
    if (history.id !== -1 && prevChatMessages?.length < 3 && prevChatMessages[1]?.answerId === "") {
      const newChatHistory = chatHistory.filter((chatHistory) => chatHistory.id !== history.id);
      dispatch(setChatHistory(newChatHistory));
    }
  };

  const defaultConfigureState = () => {
    dispatch(
      setSelectedChatHistory({
        id: -1,
        title: "",
        messages: [],
        userId: -1,
        modelName: Model.GPT3,
        createdOn: "",
        chatTaxonomies: [],
        resumeFinder: false,
      })
    );
  };

  const startNewChat = () => {
    removeUnSavedChatHistory(selectedChatHistory);
    clearAllChatStates();
    deleteLastMessages(selectedChatHistory);
    defaultConfigureState();
  };

  const deleteLastMessages = (history: IChatHistory) => {
    // For user switch tab answers not received completely so we delete the last answer
    if (history?.messages && history?.messages?.length > 0) {
      const messageLength = history?.messages?.length - 1;
      if (
        !history?.messages[messageLength]?.answerId &&
        history?.messages[messageLength]?.error === false
      ) {
        dispatch(deleteLastChatMessage(history.id));
      }
    }
    // end
  };

  const getSelectedFilterObject = (): ITaxonomyFilter => {
    return {
      tags: [
        {
          taxonomyName: TFiltersType.Functions,
          taxonomyId: functionTaxonomy.taxonomyId,
          taxonomyNodeIds: convertToNumber(selectedFunctions),
        },
        {
          taxonomyName: TFiltersType.Industry,
          taxonomyId: industryTaxonomy?.taxonomyId,
          taxonomyNodeIds: convertToNumber(selectedIndustry),
        },
        {
          taxonomyName: TFiltersType.AssetType,
          taxonomyId: assetTypeTaxonomy?.taxonomyId,
          taxonomyNodeIds: convertToNumber(selectedAssetType),
        },
      ],
      fileType: selectedFileType,
      startDate: selectedDateRange.length > 0 ? selectedDateRange[0] : "",
      endDate: selectedDateRange.length > 0 ? selectedDateRange[1] : "",
      is_owner: null,
      is_bookmarked: null,
    };
  };

  return {
    startNewChat,
    clearAllChatStates,
    removeUnSavedChatHistory,
    defaultConfigureState,
    getSelectedFilterObject,
  };
};
// Chat hooks end
