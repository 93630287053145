import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ColumnsType } from "antd/es/table";
import { Button, Card, Col, Dropdown, Flex, MenuProps, Row, Table } from "antd";
import { CreateTaxonomy, DeleteModal, PageHeader } from "../../../components";
import SkeletonTable from "../user-management/SkeletonTable";
import SummaryManagement from "../contentManagement/SummaryManagement";
import { RootState } from "../../../store";
import { TaxonomyData } from "../../../store/taxonomy/taxonomy.interface";
import { setTaxonomyCurrPage } from "../../../store/taxonomy/taxonomySlice";
import { deleteTaxonomyManagement, fetchAllTaxonomy } from "../../../services/taxonomy";
import {
  PATHS,
  TaxonomyType,
  PageLimit,
  CustomPagination,
  truncateString,
  isEmpty,
  countWithZero,
} from "../../../shared";
import "./TaxonomyManagement.scss";

const TaxonomyManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    allTaxonomyData,
    taxonomyTableLoading,
    taxonomyDeleteLoading,
    totalTaxonomyCount,
    taxonomyCurrPage,
  } = useSelector((state: RootState) => state.taxonomy);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [seletedRowId, setSelectedRowId] = useState<number>(-1);
  const [isSummaryModal, setIsSummaryModal] = useState<number>(-1);
  const [summaryDescription, setSummaryDescirption] = useState<string>("");

  useEffect(() => {
    fetchAllTaxonomy(taxonomyCurrPage);
  }, [taxonomyCurrPage]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            dispatch(setTaxonomyCurrPage(taxonomyCurrPage));
            setIsModalOpen(true);
            navigate(`${PATHS.adminTaxonomyOverview}/${seletedRowId}`, {
              state: { isEditMode: true },
            });
          }}>
          Edit
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            dispatch(setTaxonomyCurrPage(taxonomyCurrPage));
            setIsDeleteModal(true);
          }}
          className="taxonomyDeleteText">
          Delete
        </div>
      ),
    },
  ];

  const renderTaxonomyType = (type: string) => {
    if (type === TaxonomyType.TREE)
      return (
        <div className="taxonomy-type d-flex d-flex-middle">
          <span className="taxonomy-icon d-flex d-flex-middle">
            <i className="ci ci-tree-hierarchy"></i>
          </span>
          Tree hierarchy
        </div>
      );
    return (
      <div className="taxonomy-type d-flex d-flex-middle">
        <span className="taxonomy-icon d-flex d-flex-middle">
          <i className="ci ci-flat-hierarchy"></i>
        </span>
        Flat keywords
      </div>
    );
  };

  const columns: ColumnsType<TaxonomyData> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "230px",
      render: (text, v: TaxonomyData) => (
        <>
          <span className="td-label">Title : </span>
          <strong
            onClick={() => {
              dispatch(setTaxonomyCurrPage(taxonomyCurrPage));
              navigate(`${PATHS.adminTaxonomyOverview}/${v.taxonomyId}`, {
                state: { isEditMode: false },
              });
            }}>
            <u style={{ cursor: "pointer" }}> {text}</u>
          </strong>
        </>
      ),
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
      width: "400px",
      render: (text, row) => {
        const maxLength = 60;
        return (
          <>
            <span className="td-label">Purpose : </span>
            <div className="tree-discription d-flex">
              <span>{truncateString(text, maxLength)}</span>
              {text?.length > maxLength && (
                <u
                  onClick={() => {
                    setSummaryDescirption(row?.purpose);
                    setIsSummaryModal(row?.taxonomyId);
                  }}
                  className="more-tag">
                  +more
                </u>
              )}

              <SummaryManagement
                isOpen={isSummaryModal === row?.taxonomyId}
                description={summaryDescription}
                setModalClose={handleSummaryModal}
              />
            </div>
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "200px",
      render: (_, row) => (
        <>
          <span className="td-label">Type : </span>
          {renderTaxonomyType(row?.taxonomyType)}
        </>
      ),
    },
    // {
    //   title: "Version",
    //   dataIndex: "version",
    //   key: "version",
    //   width: "120px",
    //   render: () => (
    //     <>
    //       <span className="td-label">Version : </span>
    //       Version 2.0
    //     </>
    //   ),
    // },
    {
      title: "Levels",
      dataIndex: "treeDepth",
      key: "treeDepth",
      width: "120px",
      render: (number: number) => (
        <>
          <span className="td-label">Levels : </span>
          {!isEmpty(number) && number > 0 ? countWithZero(number - 1) : "Nil"}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (v: TaxonomyData) => {
        return (
          <>
            <span className="td-label">Action : </span>
            <div className="actions-icons-wrap actions-icons-2 d-flex">
              <Dropdown
                overlayClassName="table-action-dropdown"
                menu={{
                  onClick: () => {
                    setSelectedRowId(v?.taxonomyId);
                  },
                  items,
                }}
                trigger={["click"]}
                placement="bottomRight">
                <Button className="more-action" onClick={() => setSelectedRowId(v.taxonomyId)}>
                  <i className="ri-more-2-fill"></i>
                </Button>
              </Dropdown>
              <i
                onClick={() => {
                  dispatch(setTaxonomyCurrPage(taxonomyCurrPage));
                  navigate(`${PATHS.adminTaxonomyOverview}/${v.taxonomyId}`, {
                    state: { isEditMode: false },
                  });
                }}
                className="ri-arrow-right-s-line"></i>
            </div>
          </>
        );
      },
    },
  ];

  const handleSummaryModal = () => {
    setIsSummaryModal(-1);
    setSummaryDescirption("");
  };

  const onRemove = async () => {
    await deleteTaxonomyManagement(seletedRowId);

    setIsDeleteModal(false);
    dispatch(setTaxonomyCurrPage(taxonomyCurrPage));
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
    setIsDeleteModal(false);
  };

  const handlePagination = (page: number) => {
    dispatch(setTaxonomyCurrPage(page));
  };

  return (
    <Flex className="admin-inner-wrap taxonomy-management-page" vertical>
      <PageHeader
        title="Taxonomy Management"
        content={
          <>
            <Button
              className="btn"
              type="default"
              icon={<i className="ri-add-circle-fill"></i>}
              onClick={() => setIsModalOpen(true)}>
              Create taxonomy
            </Button>
          </>
        }
      />
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}>
        <Row>
          <Col span={24}>
            <Card
              className={`global-table-card ${
                isEmpty(allTaxonomyData) ? "global-table-empty" : ""
              }`}>
              {taxonomyTableLoading ? (
                <SkeletonTable />
              ) : (
                <Table
                  className="global-table responsive-table"
                  columns={columns}
                  dataSource={allTaxonomyData}
                  tableLayout="fixed"
                  scroll={{ y: "calc(100vh - 188px)" }}
                  pagination={false}
                />
              )}

              {allTaxonomyData && allTaxonomyData.length ? (
                <CustomPagination
                  currentPage={taxonomyCurrPage}
                  total={totalTaxonomyCount}
                  handlePagination={handlePagination}
                  pageSize={PageLimit.Taxonomy}
                />
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>
      </motion.div>
      {!!isModalOpen && (
        <CreateTaxonomy
          title={"Create taxonomy"}
          isModalOpen={isModalOpen}
          onCancel={handleOnCancel}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      {/* delete taxonomy modal */}
      {!!deleteModal && (
        <DeleteModal
          isLoading={taxonomyDeleteLoading}
          isModalOpen={deleteModal}
          handleCancel={() => setIsDeleteModal(false)}
          handleSubmit={onRemove}
          message="Are you sure you want to delete this taxonomy?"
          title="Delete Taxonomy"
        />
      )}
    </Flex>
  );
};

export default TaxonomyManagement;
