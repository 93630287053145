import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { IAppSliceProps, IAppearanceProps, IAssetCount } from "./app.interface";
import { Layout, themeInitValue, ViewType } from "../../shared";

const initialAssetCount: IAssetCount = {
  assets_contributed: 0,
  download_count: 0,
  draft_assets: 0,
  view_count: 0,
};

const initialState: IAppSliceProps = {
  isGetThemeLoading: false,
  isUpdateThemeLoading: false,
  appLayoutView: "minimize-chat-page",
  routeHistory: [],
  appearance: themeInitValue,
  assetCount: initialAssetCount,
  isAssetCountLoading: false,
  layout: Layout.User,
  viewType: ViewType.GRID,
  isShowDeletedUserModal: false,
  findProfile: false,
  speechText: "",
  chatWidth: 350,
};

export const appSlice = createSlice({
  name: "appSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setIsGetThemeLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetThemeLoading = action.payload;
    },

    setIsUpdateThemeLoading: (state, action: PayloadAction<boolean>) => {
      state.isUpdateThemeLoading = action.payload;
    },

    setAppearance: (state, action: PayloadAction<IAppearanceProps>) => {
      state.appearance = action.payload;
    },

    setAppLayoutview: (state, action: PayloadAction<string>) => {
      state.appLayoutView = action.payload;
    },

    setRoutHitsory: (state, action: PayloadAction<string[]>) => {
      state.routeHistory = action.payload || [];
    },

    setAssetCount: (state, action: PayloadAction<IAssetCount>) => {
      state.assetCount = action.payload;
    },

    setIsAssetCountLoading: (state, action: PayloadAction<boolean>) => {
      state.isAssetCountLoading = action.payload;
    },

    setIncViewCount: (state) => {
      state.assetCount = { ...state.assetCount, view_count: state?.assetCount?.view_count + 1 };
    },

    setIncDownloadCount: (state) => {
      state.assetCount = {
        ...state.assetCount,
        download_count: state?.assetCount?.download_count + 1,
      };
    },

    setUpdateContributedCount: (state, action: PayloadAction<number>) => {
      state.assetCount = { ...state.assetCount, assets_contributed: action.payload };
    },

    setUpdateLayout: (state, action: PayloadAction<string>) => {
      state.layout = action.payload;
    },

    setViewType: (state, action: PayloadAction<ViewType>) => {
      state.viewType = action.payload;
    },

    setIsShowDeletedUserModal: (state, action: PayloadAction<boolean>) => {
      state.isShowDeletedUserModal = action.payload;
    },

    setFindProfileModel: (state, action: PayloadAction<boolean>) => {
      state.findProfile = action.payload;
    },

    setSpeechText: (state, action: PayloadAction<string>) => {
      state.speechText = action.payload;
    },
    setChatWidth: (state, action: PayloadAction<number | string>) => {
      state.chatWidth = action.payload;
    },
  },
});

export const {
  setIsUpdateThemeLoading,
  setAppearance,
  setAppLayoutview,
  setRoutHitsory,
  setAssetCount,
  setIsAssetCountLoading,
  setIncViewCount,
  setIncDownloadCount,
  setUpdateContributedCount,
  setUpdateLayout,
  setViewType,
  setIsShowDeletedUserModal,
  setFindProfileModel,
  setSpeechText,
  setChatWidth,
  setIsGetThemeLoading,
} = appSlice.actions;

export default appSlice.reducer;
