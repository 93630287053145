import {
  abortMediaUpload,
  initiateMediaUpload,
  mergeFileChunk,
  uploadFileInChunks,
} from "../../services/uploadMedia";
import { UploadAssetState } from "../../shared";
import { store } from "../../store";
import { resetMediaUploadState } from "../../store/mediaUpload/mediaUploadSlice";

const CHUNK_SIZE = 10 * 1024 * 1024; // 10MB

let uploadId: string;

const UploadMedia = async (
  file: File,
  FileName: string,
  setUploadingState: (state: UploadAssetState) => void,
  setUploadProgress: (progress: number) => void
) => {
  try {
    store.dispatch(resetMediaUploadState());
    setUploadingState(UploadAssetState.UPLOADING),
      (uploadId = await initiateMediaUpload("mp4", FileName));

    if (!uploadId) {
      throw new Error("Failed to initiate media upload.");
    }

    await uploadFileInChunks(uploadId, file, FileName, CHUNK_SIZE, setUploadProgress);

    await mergeFileChunk({
      fileName: FileName,
      uploadId,
      partETags: store.getState().mediaUploadSlice.partETags,
    });
  } catch {
    uploadId && (await abortMediaUpload({ fileName: FileName, uploadId: uploadId }));
    setUploadingState(UploadAssetState.FAILED);
  } finally {
    store.dispatch(resetMediaUploadState());
  }
};

export default UploadMedia;
