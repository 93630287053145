import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IUserProfile } from "../../../store/userManagement/user.interface";

export const useIsProfileDataChanged = (localUserProfile: IUserProfile) => {
  const { userProfile } = useSelector((state: RootState) => state.user);

  const getIsProfileDataChanged = () => {
    const prevSummary = userProfile?.summary || "";
    const prevName = userProfile?.name || "";
    const prevEmail = userProfile?.email || "";
    const prevPhone = userProfile?.phoneNumber || "";
    const prevRole = userProfile?.currentRole || "";
    const prevLocation = userProfile?.location || "";
    const prevStartDate = userProfile?.startDate || "";
    const prevExp = userProfile?.experience ? [...userProfile?.experience] : [];

    const currSummary = localUserProfile?.summary || "";
    const currName = localUserProfile?.name || "";
    const currEmail = localUserProfile?.email || "";
    const currPhone = localUserProfile?.phoneNumber || "";
    const currRole = localUserProfile?.currentRole || "";
    const currLocation = localUserProfile?.location || "";
    const currStartDate = localUserProfile?.startDate || "";
    const currExp = localUserProfile?.experience ? [...localUserProfile?.experience] : [];

    return (
      prevSummary !== currSummary ||
      prevName !== currName ||
      prevEmail !== currEmail ||
      prevPhone !== currPhone ||
      prevRole !== currRole ||
      prevLocation !== currLocation ||
      prevStartDate !== currStartDate ||
      JSON.stringify(prevExp) !== JSON.stringify(currExp)
    );
  };

  return {
    getIsProfileDataChanged,
  };
};
