import { Outlet } from "react-router-dom";
import "./AppLayout.scss";
import { useSelector } from "react-redux";
import { History, VideoAudioPLayerModal } from "../../pages";
import { RootState } from "../../store";
import { SideBar } from "../../components";
import { ChatFloatingBtn, fileType, getAllIds, PATHS, useChats } from "../../shared";
import { MenuProps } from "antd";
import { useDispatch } from "react-redux";
import {
  clearContentFilter,
  setContentFilesInContext,
  setContentSelectedIds,
} from "../../store/contentManagement/contentSlice";
import { setFindProfileModel } from "../../store/appState/appSlice";
import {
  setContentFilterForNewChat,
  setSelectedFilesForAssetFilter,
  setSelectedReleventAsset,
  setStartNewChatFromInsight,
  setStartNewConversation,
  setUpdateUploadFileList,
} from "../../store/conversationalSearch/conversationalSearchSlice";
import {
  removeFilterData,
  setAllAssetTypesSelected,
  setAllFileTypesSelected,
} from "../../store/filtersFromTaxonomyData/filters.slice";
import { resetProfileResumes } from "../../store/profile/profileSlice";

const AppLayout = () => {
  const { assetTypeTaxonomy } = useSelector((state: RootState) => state.taxonomy);
  const { isShowVideoPLayerModal, videoModalContent } = useSelector(
    (state: RootState) => state.mediaUploadSlice
  );
  const { appLayoutView, findProfile } = useSelector((state: RootState) => state.app);

  type MenuItem = Required<MenuProps>["items"][number];

  const dispatch = useDispatch();
  const { clearAllChatStates, defaultConfigureState } = useChats();

  const renderSideBarDetails = (
    insightsLabel: string,
    insightsKey: PATHS,
    profileLabel: string,
    profileKey: PATHS
  ): { label: string; key: PATHS } => {
    if (findProfile) return { label: profileLabel, key: profileKey };
    else return { label: insightsLabel, key: insightsKey };
  };

  const handleNewTopic = () => {
    dispatch(setStartNewChatFromInsight(false));
    clearAllChatStates();
    defaultConfigureState();
    dispatch(removeFilterData([]));
    dispatch(setAllFileTypesSelected(fileType));
    dispatch(setSelectedReleventAsset([]));
    dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
    dispatch(setStartNewConversation(false));
    dispatch(setUpdateUploadFileList([]));
    dispatch(setContentFilesInContext([]));
    dispatch(setContentSelectedIds([]));
    dispatch(setSelectedFilesForAssetFilter([]));
    dispatch(resetProfileResumes());
    dispatch(setContentFilterForNewChat({ pageLocation: "" }));
  };

  const sideBarItems: MenuItem[] = [
    {
      label: "Home",
      icon: <i className="ci ci-home-icon" />,
      key: PATHS.home,
      onClick: () => {
        handleNewTopic();
        dispatch(clearContentFilter());
        dispatch(setFindProfileModel(false));
      },
      // children: [
      // {
      //   label: "Get Insights",
      //   icon: <img src={IMAGES.insightIcon} />,
      //   key: PATHS.home,
      //   onClick: () => {
      //     handleNewTopic();
      //     dispatch(clearContentFilter());
      //     dispatch(setFindProfileModel(false));
      //   },
      // },
      // {
      //   label: "Find Profiles",
      //   icon: <img src={IMAGES.profileIcon} />,
      //   key: PATHS.profile,
      //   onClick: () => {
      //     handleNewTopic();
      //     dispatch(clearContentFilter());
      //     dispatch(setFindProfileModel(true));
      //   },
      // },
      // ],
    },
    {
      label: renderSideBarDetails("Sources", PATHS.sources, "Resumes", PATHS.profileSources).label,
      icon: <i className="ci ci-sources-icon" />,
      key: renderSideBarDetails("Sources", PATHS.sources, "Resumes", PATHS.profileSources).key,
    },
    {
      label: renderSideBarDetails(
        "My Assets",
        PATHS.myContent,
        "My Uploads",
        PATHS.profileMyContent
      ).label,
      icon: <i className="ci ci-my-content-icon" />,
      key: renderSideBarDetails("My Assets", PATHS.myContent, "My Uploads", PATHS.profileMyContent)
        .key,
    },
    ...(true
      ? []
      : [
          {
            label: "Pages",
            icon: <i className="ci ci-pages-icon" />,
            key: "pages-submenu",
            children: [
              {
                label: "Industry",
                key: "idustry-submenu",
                children: [
                  { label: "Energy", key: PATHS.topicPage },
                  { label: "Financial", key: 345 },
                  { label: "Retail Industry", key: 234 },
                  { label: "Aerospace", key: 344 },
                  { label: "Automotive", key: 324 },
                ],
              },
              {
                label: "Function",
                key: "function-submenu",
                children: [{ label: "Coming Soon", key: PATHS.comingSoon }],
              },
            ],
          },
        ]),
  ];

  return (
    <>
      <div className="app-layout-container">
        <div className={"side-bar-outlet-wrap sidebar-with-content sidebar-with-user-content"}>
          <SideBar {...{ sideBarItems }} />
        </div>
        <div className="history-outlet-container">
          {appLayoutView !== "close-chat-page" ? <History /> : ""}
          {appLayoutView !== "full-chat-page" ? (
            <>
              <ChatFloatingBtn />
              <Outlet />
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* Video Player Modal */}
      <VideoAudioPLayerModal
        content={videoModalContent}
        isModalOpen={isShowVideoPLayerModal}
      />
    </>
  );
};

export default AppLayout;
