import { useEffect, useState } from "react";
import { Modal, Button, Form, Input, Checkbox, Select, Skeleton } from "antd";
import "./DataConnectionModal.scss";
import { useSelector } from "react-redux";
import { IMAGES } from "../../shared";
import { RootState } from "../../store";
import {
  deleteDataConnection,
  getAllSources,
  getSharepointAllFolders,
  selectSharepointFolders,
  selectSharepointSite,
  setFrequency,
  sharepointConnect,
  updateSharepointConnection,
} from "../../services/dataConnection";
import {
  IAllSources,
  ISharepointSiteDetails,
} from "../../store/dataConnection/dataConnection.interface";

interface IModal {
  isModalOpen: boolean;
  handleCancel: () => void;
  setIsDataModalOpen: (v: boolean) => void;
  selectedConnectionDetails: IAllSources | null;
  isEditMode?: boolean;
}

interface ISourceDetails {
  account_password: string;
  account_username: string;
  client_id: string;
  client_secret: string;
  domain_url: string;
  tenant_id: string;
}

const SourceDetails = [
  // { title: "SalesForce", icon: IMAGES.salesforceIcon },
  { title: "SharePoint", icon: IMAGES.sharepointIcon },
  // { title: "OneDrive", icon: IMAGES.oneDriveIcon },
  // { title: "Confluence", icon: IMAGES.ConfluenceIcon },
  // { title: "Web Content", icon: IMAGES.sharepointIcon },
];

const DataConnectionModal = (props: IModal) => {
  const {
    isModalOpen,
    handleCancel,
    setIsDataModalOpen,
    selectedConnectionDetails,
    isEditMode = false,
  } = props;

  const [current, setCurrent] = useState(0);
  const [selectedFolders, setSelectedFolders] = useState<string[]>([]);
  const [selectFrequency, setSelectFrequency] = useState<number | null>(null);
  const [selectedSites, setSelectedSites] = useState<ISharepointSiteDetails>({
    site_id: null,
    site_name: null,
  });

  const isConnectionEditable = selectedConnectionDetails !== null;

  const {
    dataConnectionId,
    allSharepointSites,
    allSharepointFoldersName,
    isSharepointFoldersLoading,
    isConnectDataSourceLoading,
    dataConnectionCurrPage,
  } = useSelector((state: RootState) => state.dataConnection);

  const [sourceDetailsForm] = Form.useForm();

  useEffect(() => {
    if (isConnectionEditable) {
      setCurrent(3);
      getSharepointAllFolders(selectedConnectionDetails?.dataConnectionId);
      setSelectedFolders(selectedConnectionDetails?.folders ?? []);
      setSelectFrequency(selectedConnectionDetails?.frequency ?? null);
    } else {
      setCurrent(0);
    }
  }, [isConnectionEditable, selectedConnectionDetails]);

  // useEffect(() => {
  //   return () => {
  //     onCancel();
  //   };
  // }, []);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSubmitSourceDetails = async (v: ISourceDetails) => {
    await sharepointConnect(
      {
        username: v?.account_username,
        password: v?.account_password,
        client_id: v?.client_id,
        client_secret: v?.client_secret,
        domain: v?.domain_url,
        tenant_id: v?.tenant_id,
      },
      () => next()
    );
  };

  const handleNext = async () => {
    if (isConnectionEditable) {
      next();
    } else {
      switch (current) {
        case 1:
          sourceDetailsForm.submit();
          break;
        case 2:
          await selectSharepointSite(
            dataConnectionId,
            selectedSites?.site_name ?? "",
            selectedSites?.site_id ?? "",
            () => next()
          );
          break;
        case 3:
          await selectSharepointFolders(dataConnectionId, selectedFolders, () => next());
          break;
        default:
          next();
      }
    }
  };

  const handleSubmit = async () => {
    if (isConnectionEditable) {
      await updateSharepointConnection(
        selectedConnectionDetails?.dataConnectionId,
        selectedFolders,
        selectFrequency ?? 24
      );
      getAllSources(dataConnectionCurrPage);
    } else {
      await setFrequency(dataConnectionId, selectFrequency ?? 24, dataConnectionCurrPage);
    }
    setIsDataModalOpen(false);
    setCurrent(0);
  };

  const handleChangeFolders = (checkedValues: string[]) => {
    setSelectedFolders(checkedValues);
  };

  const handleFrequency = (value: number) => {
    setSelectFrequency(value);
  };

  const handleChangeSites = (value: string) => {
    const selectedSite = allSharepointSites.find((site) => site?.site_name === value);

    if (selectedSite) {
      setSelectedSites(selectedSite);
    }
  };

  const steps = [
    {
      title: "Step 1",
      content: (
        <div className="data-source-wrap">
          {SourceDetails.map((v) => (
            <div className="data-source-container position-relative d-flex d-flex-middle active-radio">
              <span className="radio-box-custom position-absolute" />
              <img src={v?.icon} alt="source" />
              <div className="data-source-title">{v?.title}</div>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Step 2",
      content: (
        <>
          <Form form={sourceDetailsForm} onFinish={handleSubmitSourceDetails} layout="vertical">
            <Form.Item
              label="Domain Name"
              name="domain_url"
              rules={[{ required: true, message: "Please input domain URL!" }]}>
              <Input placeholder="Enter Domain Name here" type="url" />
            </Form.Item>

            <Form.Item
              label="Service Account User Name"
              name="account_username"
              rules={[{ required: true, message: "Please input account user name!" }]}>
              <Input placeholder="Enter User Name here" />
            </Form.Item>
            <Form.Item
              label="Service Account Password"
              name="account_password"
              rules={[{ required: true, message: "Please input account password!" }]}>
              <Input
                placeholder="Enter Password here"
                type="password"
                suffix={<i className="ci ci-lock-outline-icon" />}
              />
            </Form.Item>
            <Form.Item
              label="Client ID"
              name="client_id"
              rules={[{ required: true, message: "Please input client ID!" }]}>
              <Input placeholder="Enter Client ID here" />
            </Form.Item>
            <Form.Item
              label="Client Secret"
              name="client_secret"
              rules={[{ required: true, message: "Please input client secret!" }]}>
              <Input placeholder="Enter Client Secret here" />
            </Form.Item>
            <Form.Item
              label="Tenant ID"
              name="tenant_id"
              rules={[{ required: true, message: "Please input tenant ID!" }]}>
              <Input placeholder="Enter Tenant ID here" />
            </Form.Item>
          </Form>
        </>
      ),
    },
    {
      title: "Step 3",
      content: (
        <Select
          placeholder="Select Site"
          value={selectedSites?.site_name}
          style={{ width: "100%" }}
          onChange={handleChangeSites}>
          {!!allSharepointSites &&
            allSharepointSites?.map((option: ISharepointSiteDetails) => (
              <Select.Option key={option?.site_id} value={option?.site_name}>
                {option?.site_name}
              </Select.Option>
            ))}
        </Select>
      ),
    },
    {
      title: "Step 4",
      content: (
        <div className="files-wrapper">
          <Checkbox.Group className="all" value={selectedFolders} onChange={handleChangeFolders}>
            <ul className="folder-list">
              {allSharepointFoldersName?.map((item) => (
                <li key={item}>
                  <Checkbox value={item} />
                  <span className="info-wrap">
                    <span className="folder-icon">
                      <img src={IMAGES.folderIcon} alt="Icon" />
                    </span>
                    <span className="content-wrap">
                      <h6>{item}</h6>
                      <p>Assessment tool</p>
                    </span>
                  </span>
                  {/* remove this below hide */}
                  <Button className="open-btn" type="text" hidden={true}>
                    <i className="ri-arrow-right-s-line"></i>
                  </Button>
                </li>
              ))}
            </ul>
          </Checkbox.Group>
        </div>
      ),
    },
    {
      title: "Step 5",
      content: (
        // <Form.Item
        //   label="Select Update Frequency"
        //   name="update_frequency"
        //   rules={[{ required: true, message: "Please select update frequency!" }]}>
        <Select
          placeholder="Select Frequency"
          style={{ width: "100%" }}
          value={selectFrequency}
          onChange={handleFrequency}>
          <Select.Option value={12}>12 Hz</Select.Option>
          <Select.Option value={24}>24 Hz</Select.Option>
        </Select>
        // </Form.Item>
      ),
    },
  ];

  const onCancel = async () => {
    if (current !== 0 && current !== 1) {
      if (dataConnectionId !== -1) {
        await deleteDataConnection(dataConnectionId, false);
        sourceDetailsForm.resetFields();
        handleCancel();
      } else {
        sourceDetailsForm.resetFields();
        handleCancel();
      }
    } else {
      sourceDetailsForm.resetFields();
      handleCancel();
    }
  };

  const disableBackBtn = () => isEditMode && current !== steps?.length - 1;

  return (
    <Modal
      open={isModalOpen}
      rootClassName="data-modal-root"
      title={
        <>
          {current > 0 && (
            <Button
              className={`btn back-btn`}
              disabled={disableBackBtn()}
              type="primary"
              onClick={prev}>
              <i className="ri-arrow-left-s-line"></i>
            </Button>
          )}
          {isConnectionEditable ? "Edit the Source" : "Connect the Source"}
        </>
      }
      centered={true}
      onCancel={onCancel}
      footer={null}>
      {current === 0 && <div className="data-modal-sub-header">Select the source type</div>}

      {current === steps.length - 1 && (
        <div className="data-modal-sub-header d-flex d-flex-middle">
          <i className="ri-check-line check"></i>
          <div className="selected-icon">
            <img src={IMAGES.sharepointIcon} alt="Icon" />
          </div>
          <span>You have selected Sharepoint as source type.</span>
        </div>
      )}

      {isSharepointFoldersLoading ? (
        <Skeleton active />
      ) : (
        <div className="form-fields-wrapper">{steps[current].content}</div>
      )}

      <div className="submit-wrap ant-modal-footer">
        {current < steps.length - 1 && (
          <Button
            type="primary"
            className="btn"
            onClick={handleNext}
            loading={isConnectDataSourceLoading}>
            Next
          </Button>
        )}

        {current === steps.length - 1 && (
          <Button
            type="primary"
            className="btn"
            htmlType="submit"
            loading={isConnectDataSourceLoading}
            onClick={handleSubmit}>
            Confirm
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default DataConnectionModal;
