import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Button, Card, Col, Flex, Form, Row } from "antd";
import { HomeSlider } from "../../components/home";
import { DeleteModal } from "../../components";
import {
  UserProfileUploadForm,
  BasicDetailsForm,
  InfoSection,
  SummaryForm,
  ViewExp,
  UploadResume,
  AssetPreviewModal,
} from "../../components/userProfile";
import { RootState } from "../../store";
import { IUserProfile } from "../../store/userManagement/user.interface";
import {
  setIsExtractingResumeDetails,
  setUserProfile,
  userProfileInitValue,
} from "../../store/userManagement/usersSlice";
import { getRecentlyViewedAssets } from "../../services/home";
import {
  extractResumeDetails,
  fetchUserProfile,
  updateUserProfile,
  userProfilePulling,
} from "../../services/userManagement";
import { useIsProfileDataChanged } from "../../shared/hooks/userProfile/useIsProfileDataChanged";
import { FormTitle, getUserEmail, getUserId, IMAGES, isEmpty, SummaryStaus } from "../../shared";
import "./UserProfile.scss";

const UserProfile = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const profileUserId = location?.state?.profileUserId || null;

  const { isRecentlyViewedAssetsLoading, recentlyViewedAssets } = useSelector(
    (state: RootState) => state.home
  );
  const { userProfile, isProfileUpdating } = useSelector((state: RootState) => state.user);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [uploadFormTitle, setUploadFormTitle] = useState<string>("");
  const [isVisibleResumeModal, setIsVisibleResumeModal] = useState<boolean>(false);
  const [hasResume, setHasResume] = useState<boolean>(true);
  const [isShowDeleteModal, setIShowDeleteModal] = useState<boolean>(false);
  const [isShowCancelWarnModal, setIsShowCancelWarnModal] = useState<boolean>(false);
  const [isOpenUploadResume, setIsOpenUploadResume] = useState<boolean>(false);
  const [localUserProfile, setLocalUserProfile] = useState<IUserProfile>(userProfileInitValue);

  const { getIsProfileDataChanged } = useIsProfileDataChanged(localUserProfile);
  const isResumeProcessing = localUserProfile?.fileStatus === SummaryStaus.PROCESSING;
  const isItsMe = profileUserId === getUserId();
  let extractResumeDetailsInterval: any;

  // console.log("userProfile  ===", userProfile);
  // console.log("localUserProfile  ===", localUserProfile);
  // console.log("getIsProfileDataChanged  ===", getIsProfileDataChanged());
  // console.log("#################################");

  const itsMe = () => {
    return profileUserId === getUserId();
  };

  useEffect(() => {
    dispatch(setIsExtractingResumeDetails(false));
    if (isItsMe) {
      fetchUserProfile();
    } else {
      getRecentlyViewedAssets(); // Call API for fetching other user profiles and contributed assets by userId.
    }
  }, []);

  const resetToInitState = () => {
    setLocalUserProfile(userProfile);
    form.setFieldsValue(userProfile);
    setIsEdit(false);
    setIsShowCancelWarnModal(false);
    setHasResume(!isEmpty(userProfile?.originalFileName) ? true : false);
  };

  useEffect(() => {
    resetToInitState();
  }, [userProfile]);

  const handleRemoveResume = () => {
    setIsVisibleResumeModal(false);
    setHasResume(false);
    setIShowDeleteModal(false);
  };

  const handleCloseDeleteModal = () => {
    setIShowDeleteModal(false);
  };

  const renderResumeBtn = (close: boolean = false) => {
    return (
      <Button
        className={`btn resume-btn ${!isEdit && "cursor-default"}`}
        type="default"
        disabled={isResumeProcessing || isProfileUpdating}
        icon={<img src={IMAGES.pdfIconBig} />}>
        {userProfile?.originalFileName}
        {close && (
          <span className="del-icon" onClick={() => setIShowDeleteModal(true)}>
            <i className="ci ci-bin-icon" />
          </span>
        )}
      </Button>
    );
  };

  const handleSaveChanges = async () => {
    let profileDetails: IUserProfile = {
      ...localUserProfile,
      email: getUserEmail(),
    };
    if (!isEmpty(userProfile?.profileId)) {
      profileDetails.profileId = userProfile?.profileId;
    }

    const res = await updateUserProfile(profileDetails);
    if (!isEmpty(res?.data)) {
      dispatch(setUserProfile(res?.data));
      setIsEdit(false);
    }
  };

  const handleCancel = () => {
    resetToInitState();
  };

  const handleCancelBtnClick = () => {
    const isProfileDataChanged = getIsProfileDataChanged();
    if (isProfileDataChanged) {
      setIsShowCancelWarnModal(true);
    } else {
      handleCancel();
    }
  };

  const handleExtractDetails = async () => {
    dispatch(setIsExtractingResumeDetails(true));
    const res = await extractResumeDetails();
    console.log("handleExtractDetails res ===", res);
    if (!isEmpty(res)) {
      setLocalUserProfile((prev) => ({ ...prev, fileStatus: SummaryStaus.PROCESSING }));
    } else {
      dispatch(setIsExtractingResumeDetails(false));
    }
  };

  const handleResumeDetailsPulling = async () => {
    if (isResumeProcessing) {
      extractResumeDetailsInterval = setInterval(async () => {
        const res = await userProfilePulling();
        console.log("pulling-res ===", res);
        if ([String(SummaryStaus.COMPLETED), SummaryStaus.FAILED].includes(res?.fileStatus || "")) {
          setLocalUserProfile((prev) => ({ ...prev, fileStatus: res?.fileStatus || "" }));
          dispatch(setIsExtractingResumeDetails(false));
        }
      }, 10000);
    } else {
      clearInterval(extractResumeDetailsInterval);
    }
  };

  useEffect(() => {
    handleResumeDetailsPulling();

    return () => {
      clearInterval(extractResumeDetailsInterval);
    };
  }, [localUserProfile]);

  return (
    <>
      <Flex className="inner-app-wrap user-profile-page" vertical>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}>
          <div className="profile-head">
            <Card
              className="user-banner"
              style={{
                backgroundImage: `url(${
                  userProfile?.profileBackgroundPreSignedUrl
                    ? userProfile?.profileBackgroundPreSignedUrl
                    : IMAGES.userDefaultWall
                })`,
              }}>
              <div className="user-extra d-flex d-flex-middle d-flex-right">
                {isEdit && (
                  <Button
                    className="text-btn"
                    type="text"
                    onClick={() => [
                      setUploadFormTitle(FormTitle.UploadCover),
                      setIsVisibleResumeModal(true),
                    ]}>
                    <i className="ri-pencil-line"></i>
                  </Button>
                )}
              </div>
              <div className="user-extra extra-bottom d-flex d-flex-middle d-flex-right">
                {!isEdit && (
                  <Button
                    className="btn default-btn-hover"
                    type="default"
                    icon={<i className="ri-share-fill"></i>}>
                    Share
                  </Button>
                )}
              </div>
            </Card>

            <Card className="user-detail">
              <div className="user-pic">
                <img
                  alt="profile pic"
                  src={
                    userProfile?.profilePicturePreSignedUrl
                      ? userProfile?.profilePicturePreSignedUrl
                      : IMAGES.userDefaultAvatar
                  }
                  onError={(e) => (e.currentTarget.src = IMAGES.userDefaultAvatar)}
                />
                <Button
                  type="text"
                  className="upload-user-pic"
                  onClick={() => [
                    setUploadFormTitle(FormTitle.UploadPic),
                    setIsVisibleResumeModal(true),
                  ]}>
                  <i className="ri-camera-fill"></i>
                </Button>
              </div>

              <InfoSection />

              <div className="user-edit-options">
                <div className="opt-inner d-flex d-flex-middle d-flex-right">
                  {hasResume && !isEdit && renderResumeBtn()}
                  {isEdit && (
                    <>
                      {hasResume ? (
                        renderResumeBtn(true)
                      ) : (
                        <Button
                          className="btn"
                          type="default"
                          onClick={() => setIsOpenUploadResume(true)}>
                          Upload Resume
                        </Button>
                      )}
                      <Button
                        className="btn generate-btn"
                        type="primary"
                        disabled={!hasResume || isResumeProcessing || isProfileUpdating}
                        onClick={handleExtractDetails}
                        icon={<i className="ri-sparkling-2-fill"></i>}>
                        Extract details
                      </Button>
                    </>
                  )}

                  {isResumeProcessing && (
                    <span className="loading-wrap">
                      <img src={IMAGES.loadingSpinner} alt="loading" />
                      Extracting Details
                    </span>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </motion.div>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}>
          <Row>
            <Col span={16}>
              <Card>
                <SummaryForm
                  form={form}
                  isEdit={isEdit}
                  localUserProfile={localUserProfile}
                  setLocalUserProfile={setLocalUserProfile}
                  isProfileUpdating={isProfileUpdating}
                />
              </Card>
            </Col>

            <Col span={8}>
              <Card>
                <ViewExp
                  isEdit={isEdit}
                  localExp={localUserProfile?.experience || []}
                  setLocalUserProfile={setLocalUserProfile}
                  isProfileUpdating={isProfileUpdating}
                />
              </Card>
            </Col>
          </Row>
        </motion.div>

        {isItsMe ? (
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6 }}>
            <Card>
              <BasicDetailsForm
                form={form}
                isEdit={isEdit}
                localUserProfile={localUserProfile}
                setLocalUserProfile={setLocalUserProfile}
                isProfileUpdating={isProfileUpdating}
              />
            </Card>
          </motion.div>
        ) : (
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6 }}>
            <HomeSlider
              title="Contributed Assets"
              isLoading={isRecentlyViewedAssetsLoading}
              data={recentlyViewedAssets}
            />
          </motion.div>
        )}

        {itsMe() && (
          <div className="bottom-edit-bar">
            {isEdit ? (
              <Form name="profileForm" form={form} onFinish={handleSaveChanges}>
                <Button className="btn" danger={true} type="default" onClick={handleCancelBtnClick}>
                  Cancel
                </Button>
                <Button
                  className="btn ml-3"
                  type="primary"
                  htmlType="submit"
                  loading={isProfileUpdating}
                  disabled={!getIsProfileDataChanged() || isProfileUpdating}>
                  Save Changes
                </Button>
              </Form>
            ) : (
              <Button className="btn" type="default" onClick={() => setIsEdit(true)}>
                Edit
              </Button>
            )}
          </div>
        )}
      </Flex>

      {/* Upload Profile Pic Modal */}
      <UserProfileUploadForm
        {...{
          setIsVisibleResumeModal,
          isVisibleResumeModal,
          title: uploadFormTitle,
        }}
      />

      {isOpenUploadResume && (
        <UploadResume isOpen={isOpenUploadResume} setIsOpen={setIsOpenUploadResume} />
      )}

      {/* Delete Resume Modal */}
      {isShowDeleteModal && (
        <DeleteModal
          isLoading={false}
          isModalOpen={isShowDeleteModal}
          handleCancel={handleCloseDeleteModal}
          handleSubmit={handleRemoveResume}
          message="Are you sure you want to delete this resume?"
          title="Delete Resume"
        />
      )}

      {/* Cancel warning Modal */}
      {isShowCancelWarnModal && (
        <DeleteModal
          isLoading={false}
          isModalOpen={isShowCancelWarnModal}
          handleCancel={() => setIsShowCancelWarnModal(false)}
          handleSubmit={handleCancel}
          message="This will remove your unsaved changes."
          title="Are you sure you want to cancel?"
          submitBtnName="Yes"
          cancelBtnName="No"
        />
      )}

      <AssetPreviewModal profileUserId={profileUserId} />
    </>
  );
};

export default UserProfile;
