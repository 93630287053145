import { Dispatch, SetStateAction } from "react";
import { Col, Form, FormInstance, Input, Row } from "antd";
import { IUserProfile } from "../../../store/userManagement/user.interface";
import { getUserEmail } from "../../../shared";

interface IBasicDetailsForm {
  form: FormInstance<any>;
  isEdit: boolean;
  localUserProfile: IUserProfile;
  setLocalUserProfile: Dispatch<SetStateAction<IUserProfile>>;
  isProfileUpdating: boolean;
}

const BasicDetailsForm = (props: IBasicDetailsForm) => {
  const { form, isEdit, localUserProfile, setLocalUserProfile, isProfileUpdating } = props;
  const isDisabled = isProfileUpdating || !isEdit;

  return (
    <>
      <div className="card-head-wrap d-flex d-flex-between">
        <div className="heading-wrap">
          <h4>Basic Details</h4>
          <p>Change your personal information settings here</p>
        </div>
      </div>

      <div className="user-info-change-wrap">
        <Form name="profileForm" form={form}>
          <Row className="form-row">
            <Col span={12}>
              <div className="input-wrap">
                <span className="input-label">Full Name</span>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter full name",
                    },
                  ]}>
                  <Input
                    type="text"
                    value={localUserProfile?.name || ""}
                    disabled={isDisabled}
                    onChange={(e) => {
                      form.setFieldsValue({ name: e.target.value });
                      setLocalUserProfile((prev) => ({ ...prev, name: e.target.value }));
                    }}
                    prefix={isEdit ? <i className="ci ci-user-line-icon" /> : null}></Input>
                </Form.Item>
              </div>
            </Col>

            <Col span={12}>
              <div className="input-wrap">
                <span className="input-label">Email Address</span>
                <Input
                  type="email"
                  disabled={true}
                  value={getUserEmail()}
                  // value={localUserProfile?.email}
                  prefix={isEdit ? <i className="ci ci-email-line-icon" /> : null}></Input>
              </div>
            </Col>

            <Col span={12}>
              <div className="input-wrap">
                <span className="input-label">Phone Number</span>
                <Form.Item
                  name="phoneNumber"
                  rules={[
                    {
                      type: "string",
                      required: false,
                      message: "Please enter phone number",
                    },
                    {
                      pattern: new RegExp(/^[6-9]\d{9}$/),
                      message: "Please enter valid phone number",
                    },
                  ]}>
                  <Input
                    disabled={isDisabled}
                    value={localUserProfile?.phoneNumber || ""}
                    onChange={(e) => {
                      form.setFieldsValue({ phoneNumber: e.target.value });
                      setLocalUserProfile((prev) => ({ ...prev, phoneNumber: e.target.value }));
                    }}
                    prefix={isEdit ? <i className="ri-phone-line" /> : null}
                  />
                </Form.Item>
              </div>
            </Col>

            <Col span={12}>
              <div className="input-wrap">
                <span className="input-label">Role</span>
                <Form.Item
                  name="currentRole"
                  rules={[
                    {
                      type: "string",
                      required: false,
                      message: "Please enter role",
                    },
                  ]}>
                  <Input
                    type="text"
                    value={localUserProfile?.currentRole || ""}
                    disabled={isDisabled}
                    onChange={(e) => {
                      form.setFieldsValue({ currentRole: e.target.value });
                      setLocalUserProfile((prev) => ({ ...prev, currentRole: e.target.value }));
                    }}
                    prefix={isEdit ? <i className="ci ci-user-line-icon" /> : null}></Input>
                </Form.Item>
              </div>
            </Col>

            <Col span={12}>
              <div className="input-wrap">
                <span className="input-label">Location</span>
                <Form.Item
                  name="location"
                  rules={[
                    {
                      type: "string",
                      required: false,
                      message: "Please enter location",
                    },
                  ]}>
                  <Input
                    type="text"
                    disabled={isDisabled}
                    value={localUserProfile?.location || ""}
                    onChange={(e) => {
                      form.setFieldsValue({ location: e.target.value });
                      setLocalUserProfile((prev) => ({ ...prev, location: e.target.value }));
                    }}
                    prefix={isEdit ? <i className="ci ci-location-icon" /> : null}
                  />
                </Form.Item>
              </div>
            </Col>

            <Col span={12} hidden={true}>
              <div className="input-wrap">
                <span className="input-label">Start Date</span>
                <Form.Item
                  name="startDate"
                  rules={[
                    {
                      type: "date",
                      required: false,
                      message: "Please select start date",
                    },
                  ]}>
                  <Input
                    type="date"
                    disabled={isDisabled}
                    value={localUserProfile?.startDate || ""}
                    onChange={(e) => {
                      form.setFieldsValue({ startDate: e.target.value });
                      setLocalUserProfile((prev) => ({ ...prev, startDate: e.target.value }));
                    }}
                    prefix={isEdit ? <i className="ci ci-calendar-check-icon" /> : null}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default BasicDetailsForm;
