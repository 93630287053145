import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { RootState } from "../../../store";
import {
  contentByIdInitialValue,
  setContentById,
  setSelectedAllTagsByAssestId,
  tagsByAssestIdInitValue,
} from "../../../store/contentManagement/contentSlice";
import { setIsPDFModalOpen } from "../../../store/pdfViewer/pdfViewer.slice";
import { IContent } from "../../../store/contentManagement/content.interface";
import { updateViewCount } from "../../../services/contentManagement";
import { FromWhere, ImageViewer, isEmpty, PATHS, PDFViewer } from "../../../shared";

interface IAssetPreviewModal {
  profileUserId: number | null;
}

const AssetPreviewModal = ({ profileUserId }: IAssetPreviewModal) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isPDFModalOpen, pdfContent } = useSelector((state: RootState) => state.pdfViewer);

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    updateViewCount(assetId);

    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: {
        isEditMode,
        fromWhere: FromWhere.UserProfile,
        profileUserId,
      },
    });
  };

  const handleContentDetail = (content: IContent) => {
    return handleViewEditContentNavigate(content?.asset_id);
  };

  const handlePDFClose = () => {
    dispatch(setIsPDFModalOpen(false));
  };

  return (
    <>
      <ImageViewer handleDetail={() => handleContentDetail(pdfContent)} />
      <Modal
        className="pdf-modal detail-open"
        centered={true}
        onCancel={handlePDFClose}
        footer={false}
        open={!isEmpty(pdfContent) && isPDFModalOpen}
        style={{ top: "20px", right: "20px", position: "absolute", marginRight: "20px" }}>
        <PDFViewer
          isRelevantAsset={true}
          isChatButton={true}
          content={pdfContent}
          btnClick={() => handleContentDetail(pdfContent)}
        />
      </Modal>
    </>
  );
};

export default AssetPreviewModal;
