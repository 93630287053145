import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { persistStore } from "redux-persist";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Flex,
  Input,
  MenuProps,
  Row,
  Select,
  Space,
  Switch,
  Table,
} from "antd";
import {
  AddEditUserManagement,
  PageHeader,
  TableSkeleton,
  DeleteModal,
  ShowAllTaxonomyTags,
} from "../../../components";
import { Industry } from "../../../pages/home/Home";
import { RootState, store } from "../../../store";
import { IUser } from "../../../store/userManagement/user.interface";
import { setSearchText, setUserCurrPage } from "../../../store/userManagement/usersSlice";
import {
  deleteUser,
  fetchAllUsers,
  updateUser,
  updateUserStatus,
} from "../../../services/userManagement";
import { setOldAppearance } from "../../../services/appAppearanceApi";
import { getAccessGroups } from "../../../services/accessGroup";
import {
  useDebounce,
  PageLimit,
  CustomPagination,
  getLocalStorageItem,
  removeLocalstorage,
  removeCookie,
  PATHS,
  formatRelativeTime,
  isEmpty,
  UserRoles,
} from "../../../shared";
import "./Usermanagement.scss";

const Usermanagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    allUsers,
    userTableLoading,
    userDeleteLoading,
    totalUsersCount,
    userCurrPage,
    searchText,
  } = useSelector((state: RootState) => state.user);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [isShowDeleteUserModal, setIsShowDeleteUserModal] = useState<boolean>(false);
  const [accessGroupModalKey, setAccessGroupModalKey] = useState<number>(-1);
  const [functionsList] = useState<Industry[]>([]);

  const localStorageItem = getLocalStorageItem("user_foundationKM");
  const details: string | undefined = localStorageItem !== null ? localStorageItem : undefined;
  const userDetails = details ? JSON.parse(details) : {};

  useEffect(() => {
    fetchAllUsers(searchText, userCurrPage);
  }, [userCurrPage]);

  useEffect(() => {
    getAccessGroups(true, "", 1);
  }, []);

  const handleUpdateStatus = (value: boolean, userDetials: IUser) => {
    const isUserEnable = value ? "enable" : "disable";
    updateUserStatus(isUserEnable, userDetials?.id, searchText, userCurrPage);
  };

  const handleUpdateRole = async (value: string, selectedUser: IUser) => {
    const request = {
      ...selectedUser,
      userPermission: [
        {
          roleName: value,
          configSettingId: 1,
        },
      ],
    };
    const res: any = await updateUser(
      { ...request, userId: selectedUser?.id },
      searchText,
      userCurrPage,
      {},
      true,
      false
    );
    if (!isEmpty(res?.data) && value !== UserRoles.Admin && selectedUser.id === userDetails?.id) {
      handleLogout();
    }
  };

  const renderAccessGroups = (accessGroups: any[]) => {
    const dataLength: number = !isEmpty(accessGroups) ? accessGroups?.length : 0;

    if (dataLength > 0) {
      const accessGroupNames: string[] = accessGroups?.map((v) => v?.accessGroupName);
      if (dataLength === 1) {
        return (
          <div className="item-tags item-tags-ui">
            <Space size={[0, 8]} className="tags-span" align="center">
              <p className="tags-p">{accessGroupNames[0]}</p>
            </Space>
          </div>
        );
      } else {
        return (
          <>
            <div className="item-tags item-tags-ui">
              <Space size={[0, 8]} className="tags-span" align="center">
                {accessGroupNames?.slice(0, 2).map((v, i: number) => {
                  const key = accessGroups[i]?.accessGroupId;
                  return (
                    <Fragment key={key}>
                      <p className="tags-p">{v}</p>
                      {i === 0 && <span className="spacer">|</span>}
                      {i !== 0 && (
                        <span
                          className="more-tag cursor-pointer"
                          onClick={() => setAccessGroupModalKey(key)}>
                          +more
                        </span>
                      )}
                      <ShowAllTaxonomyTags
                        isOpen={key === accessGroupModalKey}
                        contentTitle="Access Groups"
                        selectedContent={accessGroupNames}
                        setModalClose={setAccessGroupModalKey}
                      />
                    </Fragment>
                  );
                })}
              </Space>
            </div>
          </>
        );
      }
    } else {
      return (
        <div className="item-tags item-tags-ui">
          <Space size={[0, 8]} wrap className="tags-span">
            N/A
          </Space>
        </div>
      );
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            setIsModalOpen(true);
          }}>
          Edit
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setIsShowDeleteUserModal(true);
          }}
          className="UserDeleteText">
          Delete
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (text: string) => (
        <>
          <span className="td-label">Name : </span>
          {text}
        </>
      ),
    },
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
      width: "340px",
      render: (text: string) => (
        <>
          <span className="td-label">Email address : </span>
          {text}
        </>
      ),
    },
    {
      title: "Admin",
      key: "userPermissions",
      width: "120px",
      render: (user: IUser) => (
        <>
          <span className="td-label">Admin : </span>
          <Select
            className="flat-select"
            style={{ width: "60px" }}
            popupClassName="ui-dropdown-default"
            value={user?.userPermissions[0]?.role || UserRoles.User}
            onChange={(e) => handleUpdateRole(e, user)}>
            <Select.Option value={UserRoles.Admin}>Yes</Select.Option>
            <Select.Option value={UserRoles.User}>No</Select.Option>
          </Select>
        </>
      ),
    },
    {
      title: "Access Groups",
      key: "accessGroups",
      width: "240px",
      render: (user: IUser) => {
        return (
          <>
            <span className="td-label">Access Groups : </span>
            {renderAccessGroups(user?.accessGroups)}
          </>
        );
      },
    },
    {
      title: "Last Activity",
      key: "lastLogin",
      width: "180px",
      render: (user: IUser) => {
        return (
          <>
            <span className="td-label">Last Activity : </span>
            {user?.lastLogin ? formatRelativeTime(user?.lastLogin) : "N/A"}
          </>
        );
      },
    },
    {
      title: "Status",
      width: "100px",
      render: (_: string, user: IUser) => {
        return (
          <>
            <span className="td-label">Status : </span>
            <Switch
              disabled={user?.id === userDetails?.id}
              defaultChecked={user?.status === "A" ? true : false}
              onChange={(e) => handleUpdateStatus(e, user)}
            />
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "90px",
      render: (_: string, user: IUser) => (
        <>
          <span className="td-label">Action : </span>
          <Dropdown
            trigger={["click"]}
            menu={{
              onClick: () => {
                setSelectedUser(user);
              },
              items,
            }}
            overlayStyle={{ width: 80 }}
            overlayClassName="table-action-dropdown table-action-dropdown-user"
            placement="bottomRight">
            <Button className="more-action">
              <i className="ri-more-2-fill"></i>
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];

  const handleLogout = () => {
    toast.success("Logout Successfully");
    persistStore(store).purge();
    removeLocalstorage();
    removeCookie();
    indexedDB.deleteDatabase("localforage");
    indexedDB.deleteDatabase("knowledge-management");
    setOldAppearance();
    navigate(PATHS.login);
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const getUsersBySearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedSearchText = e.target.value.trim();
    if (trimmedSearchText.length >= 3) await fetchAllUsers(trimmedSearchText, 1);
    if (e.target.value.length === 0) {
      await fetchAllUsers("", 1);
    }
    dispatch(setUserCurrPage(1));
  };

  const handleSearchDebounce = useDebounce(getUsersBySearch, 500);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchDebounce(e);
    dispatch(setSearchText(e.target.value));
  };

  const handleDeleteUser = async () => {
    if (selectedUser?.id) await deleteUser(selectedUser?.id, searchText, userCurrPage);
    setIsShowDeleteUserModal(false);
    setSelectedUser(null);
    dispatch(setUserCurrPage(userCurrPage));
    setSelectedUser(null);
  };

  const handlePagination = (page: number) => {
    dispatch(setUserCurrPage(page));
  };

  return (
    <Flex className="admin-inner-wrap user-managements-page" vertical>
      <PageHeader
        title="User Management"
        isRelevantAsset={false}
        content={
          <>
            <Input
              type="search"
              className="header-search"
              placeholder="Search by name or email..."
              prefix={<i className="ri-search-line" />}
              onChange={(e) => {
                handleChangeSearch(e);
              }}
              defaultValue={searchText}
              value={searchText}
              allowClear
            />
            <Button
              className="btn"
              type="default"
              icon={<i className="ri-add-circle-fill"></i>}
              onClick={() => setIsModalOpen(true)}>
              Add new user
            </Button>
          </>
        }
      />

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}>
        <Row>
          <Col span={24}>
            <Card className={`global-table-card ${isEmpty(allUsers) ? "global-table-empty" : ""}`}>
              {userTableLoading ? (
                <TableSkeleton />
              ) : (
                <Table
                  className="global-table responsive-table responsive-table-user-management"
                  columns={columns}
                  dataSource={allUsers?.length ? allUsers : []}
                  tableLayout="fixed"
                  scroll={{ y: "calc(100vh - 188px)" }}
                  pagination={false}
                />
              )}

              {allUsers && allUsers.length ? (
                <CustomPagination
                  currentPage={userCurrPage}
                  total={totalUsersCount}
                  handlePagination={handlePagination}
                  pageSize={PageLimit.UserManagement}
                />
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>
      </motion.div>

      {!!isModalOpen && (
        <AddEditUserManagement
          title={selectedUser ? "Edit User" : "Add User"}
          isModalOpen={isModalOpen}
          onCancel={handleOnCancel}
          selectedUser={selectedUser}
          industries={functionsList}
          functions={functionsList}
          industryTaxonomyId={0}
          functionTaxonomyId={0}
          handleLogout={handleLogout}
          userDetails={userDetails}
        />
      )}

      {/* delete user modal */}
      <DeleteModal
        isLoading={userDeleteLoading}
        isModalOpen={isShowDeleteUserModal}
        handleCancel={() => {
          setIsShowDeleteUserModal(false);
          setSelectedUser(null);
        }}
        handleSubmit={handleDeleteUser}
        message="Are you sure you want to delete this user?"
        title="Delete User"
      />
    </Flex>
  );
};

export default Usermanagement;
