import { IContent } from "../../../store/contentManagement/content.interface";
import { AssetUploadStatus, FromWhere } from "../../constants";
import "./AssetStatus.scss";

interface IAssetStatus {
  content: IContent;
  isShowAllStatus?: boolean;
  fromWhere?: string;
  isGridView?: boolean;
}

const AssetStatus = ({
  content,
  isShowAllStatus = false,
  fromWhere,
  isGridView = false,
}: IAssetStatus) => {
  const renderAllAssetStatus = () => {
    if (
      content?.assetStatus === AssetUploadStatus.COMPLETED &&
      fromWhere !== FromWhere.Source &&
      isGridView
    ) {
      if (content?.isPublished === true || content?.isPublished === "TRUE") {
        return <div className="published inner">Published</div>;
      } else {
        return <div className="inner grey">Draft</div>;
      }
    } else {
      switch (content?.assetStatus) {
        case AssetUploadStatus.PROCESSING:
        case AssetUploadStatus.UPLOADED_TO_DO:
        case AssetUploadStatus.CONVERTED_TO_PDF:
        case AssetUploadStatus.SENT_TO_LLM:
          return <div className="inProgressStatus inner">Processing</div>;

        case AssetUploadStatus.FAILED:
        case AssetUploadStatus.FAILED_IN_LLM:
          return <div className="failedStatusText inner">Failed</div>;

        case AssetUploadStatus.STOPPING:
          return <div className="inProgressStatus inner">Stopping</div>;

        case AssetUploadStatus.STOPPED:
          return <div className="failedStatusText inner">Stopped</div>;

        case AssetUploadStatus.COMPLETED:
          return <div className="published inner">Processed</div>;

        default:
          return "";
      }
    }
  };

  const renderAssetStatus = () => {
    switch (content?.assetStatus) {
      case AssetUploadStatus.PROCESSING:
      case AssetUploadStatus.UPLOADED_TO_DO:
      case AssetUploadStatus.CONVERTED_TO_PDF:
      case AssetUploadStatus.SENT_TO_LLM:
        return <div className="inProgressStatus inner">Processing</div>;

      case AssetUploadStatus.STOPPING:
        return <div className="inProgressStatus inner">Stopping</div>;

      default:
        return "";
    }
  };

  return (
    <div className="asset-stat">
      {isShowAllStatus ? renderAllAssetStatus() : renderAssetStatus()}
    </div>
  );
};

export default AssetStatus;
