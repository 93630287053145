import { toast } from "react-toastify";
import { store } from "../store";
import {
  setAllProfiles,
  setIsAllProfileLoading,
  setIsShortlistAssetLoading,
  updateShortlistedResume,
} from "../store/profile/profileSlice";
import { get, put } from "./apiClients";

export const getAllProfiles = async (fileName: string[]) => {
  try {
    store.dispatch(setIsAllProfileLoading(true));

    const res = await get(`core/asset/all-resume?file_names=${fileName}`);

    store.dispatch(setAllProfiles(res));
  } catch (err: any) {
    console.log("get AssetBy Id api error", err);
    return err;
  } finally {
    store.dispatch(setIsAllProfileLoading(false));
  }
};

export const shortlistAssert = async (fileName: string, isShortlisted: boolean) => {
  try {
    store.dispatch(setIsShortlistAssetLoading(fileName));

    await put(`core/asset/resume/shortlist`, {
      file_name: fileName,
      is_shortlisted: isShortlisted,
    });

    store.dispatch(updateShortlistedResume({ fileName, isShortlisted }));
    toast.success("Successfully updated shortlisted resume");
  } catch (err: any) {
    console.log("shortlistAssert api error", err);
    toast.error(err?.error ?? "Failed to shortlist resume");
    return err;
  } finally {
    store.dispatch(setIsShortlistAssetLoading(""));
  }
};
