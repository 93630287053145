import { Button, Flex, Form, Input, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { PATHS } from "../../../shared";
import "./ForgotPassword.scss";
import { resetOtpPassword, setUserEmailAtForgetPassword } from "../../../store/auth/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { sendOtp } from "../../../services/authApi";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSendOtpLoading } = useSelector((state: RootState) => state.auth);

  const onFinish = (value: { email: string }) => {
    const { email } = value;

    dispatch(setUserEmailAtForgetPassword(email));
    sendOtp({ email }, navigate);
  };

  return (
    <Flex className="loginForm" align="center" justify="center">
      <div className="loginFormInner">
        <div id="fp-step-2" className="card-inner">
          <h3 className="box-title">Forgot Password?</h3>
          <p className="short-text">
            Enter your email below and we'll send you a link to reset your password.
          </p>
          <Form
            name="forgotPassword"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: false }}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
            size="large">
            <Form.Item
              label="Your email"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input your email.",
                },
              ]}>
              <Input placeholder="" />
            </Form.Item>
            <Row className="forgotPassword" justify="end" align="middle"></Row>
            <Form.Item className="loginBtn">
              <Button
                loading={isSendOtpLoading}
                className="primary-btn"
                type="primary"
                htmlType="submit"
                block>
                Send Link
              </Button>
            </Form.Item>
          </Form>
          <div className="back-btn d-flex d-flex-middle d-flex-center">
            <Button
              loading={false}
              className="back-to-log d-flex d-flex-middle mt-20"
              type="default"
              htmlType="submit"
              onClick={() => {
                navigate(PATHS.login);
                dispatch(resetOtpPassword([]));
              }}
              icon={<i className="ri-arrow-left-s-line"></i>}>
              <Link to={PATHS.login}> Back to log in</Link>
            </Button>
          </div>
        </div>
      </div>
    </Flex>
  );
};

export default ForgotPassword;
