import { Skeleton } from "antd";

import "./PdfViewer.scss";

const LoadingPdf = () => {
  return (
    <>
      <Skeleton active paragraph={{ rows: 18 }} />
    </>
  );
};

export default LoadingPdf;
