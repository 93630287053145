import { useEffect } from "react";

export const useClickOutsideModal = (ref: any, func: () => void, isOpen: boolean) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current?.contains(event.target)) {
        setTimeout(() => {
          func();
        }, 0);
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleClickOutside, true);
    } else {
      document.removeEventListener("click", handleClickOutside, true);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [func, ref, isOpen]);
};
