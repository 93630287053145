import { Pagination, PaginationProps } from "antd";
import "./CustomPagination.scss";

interface ICustomPagination {
  currentPage: number;
  total: number;
  handlePagination: (page: number) => void;
  pageSize: number;
}

const CustomPagination = ({
  currentPage,
  total,
  handlePagination,
  pageSize,
}: ICustomPagination) => {

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  };
  
  return (
    <div className="pagination-setion">

      {/* <div className="table-length">
        <span className="text">Showing</span>
        <Select
          value={pageSize}
          options={[
            { value: 10, label: 10 },
            { value: 20, label: 20 },
            { value: 30, label: 30 },
          ]}
        />
        <span className="text">of {total} entries</span>
      </div> */}

      <Pagination
        current={currentPage}
        total={total}
        onChange={handlePagination}
        pageSize={pageSize}
        showSizeChanger={false}
        itemRender={itemRender}
      />
    </div>
  );
};

export default CustomPagination;
