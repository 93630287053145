import { Button, Input, Modal } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./UserComments.scss";
import { RootState } from "../../../store";
import { messageComment } from "../../../services/conversationalSearch";
import { IMessages } from "../../../store/conversationalSearch/conversationalSearch.interface";
import { setChatAction } from "../../../store/conversationalSearch/conversationalSearchSlice";

interface IUserCommentModalProps {
  visible: boolean;
  handleCancel: () => void;
  message: IMessages | undefined;
  setUserFeedback: (v: boolean) => void;
}
const UserComments = ({
  visible,
  message,
  handleCancel,
  setUserFeedback,
}: IUserCommentModalProps) => {
  const dispatch = useDispatch();

  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { selectedChatHistory } = useSelector((state: RootState) => state.conversationalSearch);

  const submitFeedback = async () => {
    try {
      setIsLoading(true);
      if (message) {
        dispatch(
          setChatAction({
            ...message,
            userComments: comment ? comment : "",
            thumbsUp: false,
            thumbsDown: true,
          })
        );
        const request = {
          chatId: selectedChatHistory.id,
          answerId: message.answerId,
          userComments: comment ? comment : "",
          thumbsUp: false,
          thumbsDown: true,
        };
        const response = await messageComment(request, setUserFeedback);

        if (response && response.status === 200) {
          setComment("");
          handleCancel();
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error while message action", error);
    }
  };

  return (
    <Modal
      className="feedback-modal"
      title="What are your thoughts or suggestions on how we can improve your experience?"
      open={visible}
      centered={true}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          disabled={!comment.trim().length}
          loading={isLoading}
          key="submit"
          type="primary"
          onClick={() => {
            submitFeedback();
          }}>
          Submit
        </Button>,
      ]}>
      <div className="message-comment-container">
        <Input.TextArea
          rows={4}
          placeholder="Enter feedback here"
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          autoSize={false}
          style={{ resize: "none" }} // Disable resize handle
        />
      </div>
    </Modal>
  );
};

export default UserComments;
