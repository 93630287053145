import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import {
  IConfigurationList,
  IExperience,
  IUser,
  IUserProfile,
  IUserSliceProps,
} from "./user.interface";
import { SummaryStaus } from "../../shared";

// TODO: Remove the below code after test
export const userExperienceData: IExperience[] = [
  {
    company: "GC",
    currentlyWorking: true,
    endDate: "2024-08-15",
    location: "Delhi",
    startDate: "2020-08-16",
    title: "Manager",
  },
  {
    company: "DC",
    currentlyWorking: false,
    endDate: "2020-08-15",
    location: "Mumbai",
    startDate: "2017-08-15",
    title: "Assistant Manager",
  },
  {
    company: "HTC",
    currentlyWorking: false,
    endDate: "2017-08-15",
    location: "Bangalore",
    startDate: "2015-08-14",
    title: "Accountant",
  },
];
const localStorageItem = localStorage.getItem("user_foundationKM");
const details: string | undefined = localStorageItem !== null ? localStorageItem : undefined;
const userDetails = details ? JSON.parse(details) : {};

export const userProfileInitValue: IUserProfile = {
  profileId: null,
  name: "",
  email: userDetails?.email,
  phoneNumber: "",
  currentRole: "",
  location: "",
  startDate: "",
  summary: "",
  experience: [],
  userName: "",
  profilePictureFileName: "",
  profilePicturePreSignedUrl: "",
  profileBackgroundFileName: "",
  profileBackgroundPreSignedUrl: "",
  isBackground: false,

  fileName: "",
  originalFileName: "",
  file: "",
  fileContentType: "",
  originalResumePreSignedUrl: "",
  fileStatus: SummaryStaus.NOT_STARTED,
};

const initialState: IUserSliceProps = {
  allUsers: [],
  userTableLoading: false,
  userDeleteLoading: false,
  totalUsersCount: 0,
  userCurrPage: 1,
  searchText: "",
  configurationList: [],
  userProfile: userProfileInitValue,
  isGetProfileLoading: false,
  isProfileUpdating: false,

  fileUploadProgress: 0,
  isFileUpdating: false,
  isResumeUploading: false,
  isExtractingResumeDetails: false,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setAllUsersDetails: (state, action: PayloadAction<IUser[]>) => {
      state.allUsers = action.payload;
    },
    setUserTableLoading: (state, action: PayloadAction<boolean>) => {
      state.userTableLoading = action.payload;
    },
    setUserDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.userDeleteLoading = action.payload;
    },
    setTotalUsersCount: (state, action: PayloadAction<number>) => {
      state.totalUsersCount = action.payload;
    },
    setUserCurrPage: (state, action: PayloadAction<number>) => {
      state.userCurrPage = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setConfigurationList: (state, action: PayloadAction<IConfigurationList[]>) => {
      state.configurationList = action.payload;
    },

    clearUserManagementFilter: (state) => {
      state.userCurrPage = 1;
      state.searchText = "";
    },

    setUserProfile: (state, action: PayloadAction<IUserProfile>) => {
      state.userProfile = action.payload;
    },
    setIsGetProfileLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetProfileLoading = action.payload;
    },
    setIsProfileUpdating: (state, action: PayloadAction<boolean>) => {
      state.isProfileUpdating = action.payload;
    },

    setFileUploadProgress: (state, action: PayloadAction<number>) => {
      state.fileUploadProgress = action.payload;
    },
    setIsFileUpdating: (state, action: PayloadAction<boolean>) => {
      state.isFileUpdating = action.payload;
    },
    setIsResumeUploading: (state, action: PayloadAction<boolean>) => {
      state.isResumeUploading = action.payload;
    },
    setIsExtractingResumeDetails: (state, action: PayloadAction<boolean>) => {
      state.isExtractingResumeDetails = action.payload;
    },
  },
});

export const {
  setAllUsersDetails,
  setUserTableLoading,
  setUserDeleteLoading,
  setTotalUsersCount,
  setUserCurrPage,
  setSearchText,
  setConfigurationList,
  clearUserManagementFilter,
  setUserProfile,
  setIsGetProfileLoading,
  setIsProfileUpdating,
  setFileUploadProgress,
  setIsFileUpdating,
  setIsResumeUploading,
  setIsExtractingResumeDetails,
} = userSlice.actions;

export default userSlice.reducer;
