import { useRef } from "react";
import { Button, Popover, Space, Tag } from "antd";
import { useClickOutsideModal } from "../../../shared";
import "./showAllTaxonomyTags.scss";
interface IShowAllTaxonomyTags {
  isOpen: boolean;
  setModalClose(value: number): void;
  selectedContent: any;
  contentTitle: string;
}

const ShowAllTaxonomyTags = (props: IShowAllTaxonomyTags) => {
  const { isOpen, setModalClose, selectedContent, contentTitle } = props;

  const moreDescriptionRef = useRef(null);

  useClickOutsideModal(moreDescriptionRef, () => setModalClose(-1), isOpen);

  const handleCloseDescription = () => {
    setModalClose(-1);
  };

  return (
    <Popover
      overlayClassName="tag-popover-user-management custom-description-body custom-tag-popover"
      trigger="click"
      open={isOpen}
      content={
        <div className="popover-content-container" ref={moreDescriptionRef}>
          <div className="popover-tags-scroll">
            <div className="list-tags-section">
              <h4>{contentTitle}</h4>
              <div className="list-tags-select">
                {selectedContent?.length &&
                  selectedContent.map((item: string, index: number) => (
                    <div className="item-tags" key={index}>
                      <Space size={[0, 4]} className="tags">
                        <Tag>{item}</Tag>
                      </Space>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="des-btn">
            <Button style={{ marginRight: "10px" }} type="text" onClick={handleCloseDescription}>
              Close
            </Button>
          </div>
        </div>
      }
      overlayStyle={{ width: "600px" }}
      arrow={false}
      placement="bottomRight"
    />
  );
};

export default ShowAllTaxonomyTags;
