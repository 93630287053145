import { Dispatch, SetStateAction, useState } from "react";
import dayjs from "dayjs";
import { Button, Modal } from "antd";
import ExpForm from "./ExpForm";
import { IExperience, IUserProfile } from "../../../store/userManagement/user.interface";
import { isEmpty } from "../../../shared";

interface IViewExp {
  isEdit: boolean;
  localExp: IExperience[];
  setLocalUserProfile: Dispatch<SetStateAction<IUserProfile>>;
  isProfileUpdating: boolean;
}

const ViewExp = (props: IViewExp) => {
  const { isEdit, localExp, setLocalUserProfile, isProfileUpdating } = props;
  const [isVisibleExperienceModal, setIsVisibleExperienceModal] = useState<boolean>(false);
  const [selectedExperience, setSelectedExperience] = useState<IExperience | null>(null);
  const [selectedExpIndex, setSelectedExpIndex] = useState<number>(-1);

  const handleCloseExperienceModal = () => {
    setIsVisibleExperienceModal(false);
    setSelectedExperience(null);
    setSelectedExpIndex(-1);
  };

  const handleSelectExp = (experience: IExperience, index: number) => {
    setSelectedExperience(experience);
    setIsVisibleExperienceModal(true);
    setSelectedExpIndex(index);
  };

  return (
    <>
      <div className="card-head-wrap d-flex d-flex-between">
        <div className="heading-wrap">
          <h4>Experience</h4>
        </div>
        <div className="btns-wrap">
          <div className="btn-wrap-inner">
            {isEdit && (
              <Button
                className="add-exp"
                disabled={isProfileUpdating}
                type="text"
                onClick={() => [setSelectedExperience(null), setIsVisibleExperienceModal(true)]}>
                <i className="ri-add-large-fill"></i>
              </Button>
            )}
          </div>
        </div>
      </div>

      <ul className="exp-row">
        {!isEmpty(localExp) ? (
          localExp.map((exp: IExperience, index: number) => (
            <li key={index}>
              <span className="det">
                <h6>{exp.title}</h6>
                <p>
                  {exp.company}, {exp.location}
                </p>
              </span>

              <span className="year">
                <span>
                  {dayjs(exp.startDate).get("year")} -{" "}
                  {exp.currentlyWorking ? "Present" : dayjs(exp?.endDate || "").get("year")}
                </span>
                {isEdit && (
                  <Button
                    className="edit-exp"
                    type="text"
                    disabled={isProfileUpdating}
                    onClick={() => handleSelectExp(exp, index)}>
                    <i className="ri-pencil-line"></i>
                  </Button>
                )}
              </span>
            </li>
          ))
        ) : (
          <p className="no-record no-data-margin">No Data</p>
        )}
      </ul>

      {/* Add Experience Modal */}
      <Modal
        open={isVisibleExperienceModal}
        title="Experience"
        className="add-exp-modal"
        footer={false}
        onCancel={handleCloseExperienceModal}
        centered>
        <ExpForm
          localExp={localExp}
          setLocalUserProfile={setLocalUserProfile}
          selectedExperience={selectedExperience}
          selectedExpIndex={selectedExpIndex}
          handleCloseExperienceModal={handleCloseExperienceModal}
          isVisibleExperienceModal={isVisibleExperienceModal}
        />
      </Modal>
    </>
  );
};

export default ViewExp;
