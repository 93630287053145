import { TreeSelect } from "antd";
import { IMappedIndustry } from "../../../store/contentManagement/content.interface";
const { SHOW_ALL } = TreeSelect;

interface IEditContentTags {
  selectedTags: string[];
  mappedContentTags: IMappedIndustry[];
  placeholder: string;
  className?: string;
  popupClassName: string;
  handleChange: (selectedNodes: string[]) => void;
  isNonLeafNodeDisable?: boolean;
  allowClear?: boolean;
  disabled?: boolean;
  treeCheckStrictly?: boolean;
  popupMatchSelectWidth?: boolean;
  showSearch?: boolean;
  maxTagCountnumber?: number;
  onBlur?: () => void;
  onClear?: () => void;
  onSearch?: () => void;
}

const EditContentTags = ({
  maxTagCountnumber = 3,
  selectedTags,
  mappedContentTags,
  placeholder,
  className = "",
  popupClassName,
  handleChange,
  isNonLeafNodeDisable = true,
  allowClear = false,
  disabled = false,
  treeCheckStrictly = false,
  popupMatchSelectWidth = true,
  showSearch = true,
  onBlur,
  onClear,
  onSearch,
}: IEditContentTags) => {
  const isLeaf = (node: any) => {
    return !node.children || node.children.length === 0;
  };

  const enableDisableNonLeafNodes = (nodes: any[]): any => {
    return nodes.map((node: any) => {
      if (!isLeaf(node)) {
        return {
          ...node,
          disabled: isNonLeafNodeDisable,
          children: enableDisableNonLeafNodes(node.children),
        };
      }
      return node;
    });
  };

  const customFilter = (inputValue: string, treeNode: any) => {
    const searchText = inputValue?.toLowerCase();
    const nodeTitle = treeNode?.title?.toLowerCase();
    return nodeTitle?.indexOf(searchText) !== -1;
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  const handleAllClear = () => {
    if (onClear) {
      onClear();
    }
  };

  const handleSearch = () => {
    if (onSearch) {
      onSearch();
    }
  };

  return (
    <>
      <i className="ri-search-line tree-search"></i>
      <TreeSelect
        showSearch={showSearch}
        value={selectedTags}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder={placeholder}
        allowClear={allowClear}
        treeDefaultExpandAll
        onChange={handleChange}
        treeData={enableDisableNonLeafNodes(mappedContentTags)}
        multiple
        treeLine
        treeCheckable={true}
        treeCheckStrictly={treeCheckStrictly}
        maxTagCount={maxTagCountnumber}
        popupClassName={popupClassName}
        className={className}
        disabled={disabled}
        showCheckedStrategy={SHOW_ALL}
        popupMatchSelectWidth={popupMatchSelectWidth}
        filterTreeNode={customFilter}
        onBlur={handleBlur}
        onClear={handleAllClear}
        onSearch={handleSearch}
      />
    </>
  );
};

export default EditContentTags;
