import axios, { AxiosRequestConfig, AxiosError } from "axios";
import persistStore from "redux-persist/es/persistStore";
import { store } from "../store";
import configs from "../shared/config";
import { setIsShowDeletedUserModal } from "../store/appState/appSlice";

export const getSubdomain = (): string | null => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  if (subdomain === "localhost") return "dev-foundationkm";
  else return subdomain;
};

// Axios Instance
export const axiosClient = axios.create({
  baseURL: configs.BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Subdomain: getSubdomain(),
  },
});

// Request configuration (Interceptor)
axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token_foundationKM");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export const refreshToken = async (): Promise<string> => {
  try {
    const { data } = await axios.get(`${configs.BASE_URL}core/token/refreshtoken`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token_foundationKM")}`,
        isRefreshToken: true,
      },
    });

    return data.jwttoken;
  } catch (err) {
    console.log("get refreshToken error  ===", err);
    throw err;
  } finally {
  }
};

// Response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err: AxiosError) => {
    const originalRequest: any = err.config;
    const status = err.response?.status;
    console.log("err.response  ===", err.response);

    if (status === 401) {
      store.dispatch(setIsShowDeletedUserModal(true));
    } else if (status === 401 && !originalRequest?._retry) {
      if (originalRequest) {
        originalRequest._retry = true;

        try {
          // Call the refresh token function to get a new access token
          const newAccessToken = await refreshToken();
          // Update the Authorization header with the new token
          localStorage.setItem("token_foundationKM", newAccessToken);
          if (newAccessToken) {
            return axiosClient(originalRequest);
          }
        } catch (refreshError: any) {
          console.error("Error refreshing token ===", refreshError);
          persistStore(store).purge();
          // window.location.reload();
        }
      }
    } else if (err?.code === "ERR_NETWORK" || err?.code === "ERR_CANCELED") {
      return Promise.reject(err);
    }
    return Promise.reject(err?.response?.data);
  }
);

// Methods GET, POST,PUT, DELETE
export const get = async (path: string, config?: AxiosRequestConfig) => {
  return await axiosClient.get(`${path}`, config).then((response) => response.data);
};

export const post = async (path: string, payload?: any, config?: AxiosRequestConfig) => {
  return await axiosClient.post(`${path}`, payload, config).then((response) => response);
};

export const put = async (path: string, payload?: any, config?: AxiosRequestConfig) => {
  return await axiosClient.put(`${path}`, payload, config).then((response) => response);
};

export const deleteRequest = async (path: string, config?: AxiosRequestConfig) => {
  return await axiosClient.delete(`${path}`, config).then((response) => response);
};
